<template>
  <div class="container">
    <div class="row row-cols-1 row-cols-lg-2 g-3 mb-5" v-if="currentCalls.length > 0">
      <div class="col" v-for="call of currentCalls">
        <div class="card shadow h-100" style="  border: 3px solid var(--color-base-light-2);">
          <div class="card-body">
            <div style="min-height: 8rem;">
              <div class="d-flex align-items-center mb-3" v-if="'icon' in call">
                <img :src="call.icon" alt="..." class="img-fluid" />
                <h1 class="card-title text-start">{{ call.title }}</h1>
              </div>
              <h1 class="card-title mb-3 text-start" v-else>{{ call.title }}</h1>

              <h6 class="card-subtitle mb-2 fw-bold pill-text c_2 bg_rosa">{{ call.type }}</h6>
            </div>
            <div class="card-text">
              <div class="container-fluid">
                <div class="row justify-content-end g-1">
                  <div class="col-12 col-lg-6 d-flex justify-content-end" v-for="link of currentLinks(call.links)">
                    <a :href="link.link" target="_blank" class="btn btn-principal m-1" v-if="link.link.includes('https://') || link.link.includes('http://')">{{ link.description }}</a>
                    <router-link :to="link.link" class="btn btn-principal m-1" v-else>{{ link.description }}</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="'validity' in call" class="card-footer border-0 bg_blanco">
            <p class="float-end fw-bold pill-text c_4 bg_verde">
              <span class="iconify" data-icon="mdi:calendar-month-outline" width="25" height="25" ></span>
              {{ call.validity }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <p>
        No se encontraron convocatorias abiertas en las que puedas participar.
        Si la convocatoria que buscabas pertenece a algún proceso o área
        específicos, intenta consultarla en la sección o página
        correspondientes de este sitio web.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConvocatoriasComponent",
  props: {
    calls: []
  },
  computed: {
    currentCalls() {
      return this.calls.filter((call) => {
        if ('validity' in call) {
          if (this.isValidity(call.validity)) {
            return true;
          }
          return false;
        }
        return true;
      });
    }
  },
  methods: {
    currentLinks(links) {
      return links.filter((link) => {
        if ('validity' in link) {
          if (this.isValidity(link.validity)) {
            return true;
          }
          return false;
        }
        return true;
      });
    },
    isValidity(validity) {
      let today = new Date();

      let startDate = validity.split("-")[0].trim();

      let startDateParts = startDate.split("/");
      let startDay = parseInt(startDateParts[0], 10);
      let startMonth = parseInt(startDateParts[1], 10) - 1;
      let startYear = parseInt(startDateParts[2], 10);

      let endDate = validity.split("-")[1].trim();

      let endDateParts = endDate.split("/");
      let endDay = parseInt(endDateParts[0], 10);
      let endMonth = parseInt(endDateParts[1], 10) - 1;
      let endYear = parseInt(endDateParts[2], 10);

      startDate = new Date(startYear, startMonth, startDay);
      endDate = new Date(endYear, endMonth, endDay, 23, 59, 59, 0);

      if (today >= startDate && today <= endDate) {
        return true;
      }

      return false;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>