<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">Universidades</h1>
      </div>
    </div>

    <div class="row">
      <div class="col d-flex justify-content-center">
        <nav>
          <a :class="publicUniversitiesStaus ? 'active' : ''" @click="getPublicUniversities()">Públicas</a>
          <a :class="privateUniversitiesStaus ? 'active' : ''" @click="getPrivateUniversities()">Privadas</a>
          <a :class="allUniversitiesStaus ? 'active' : ''" @click="getAllUniversities()">Todas</a>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Mostrando {{ universitiesFilter.length }} universidades</h2>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-3 g-3">
      <div class="col" v-for="universidad of universitiesFilter">
        <router-link class="i-card p-3 mb-3 h-100" :to="'/expouniversidades/' + universidad.siglas">
          <div class="i-card-header">
            <div class="i-card-icon">
              <img :src="universidad.logo" alt="..." />
            </div>
            {{ universidad.siglas }}
          </div>
          <div class="i-card-body">
            {{ universidad.nombre }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import universities from "@/assets/json/universidades.json";

export default {
  data: () => {
    return {
      universities: universities,
      universitiesFilter: universities,
      publicUniversitiesStaus: false,
      privateUniversitiesStaus: false,
      allUniversitiesStaus: true
    }
  },
  computed: {
    publicUniversities() {
      return this.universities.filter((university) => {
        if (university.tipo == "publica") {
          return university;
        }
      });
    },
    privateUniversities() {
      return this.universities.filter((university) => {
        if (university.tipo == "privada") {
          return university;
        }
      });
    },
    allUniversities() {
      return this.universities;
    }
  },
   methods: {
    getPublicUniversities() {
      this.universitiesFilter = this.publicUniversities;

      this.publicUniversitiesStaus = true;
      this.privateUniversitiesStaus = false;
      this.allUniversitiesStaus = false;
    },
    getPrivateUniversities() {
      this.universitiesFilter = this.privateUniversities;

      this.publicUniversitiesStaus = false;
      this.privateUniversitiesStaus = true;
      this.allUniversitiesStaus = false;

    },
    getAllUniversities() {
      this.universitiesFilter = this.allUniversities;

      this.publicUniversitiesStaus = false;
      this.privateUniversitiesStaus = false;
      this.allUniversitiesStaus = true;

    }
   }
}
</script>

<style scoped>
nav {
  display: inline-flex;
  align-content: space-around
}

nav a {
  margin: 10px 10px 10px 10px;
  color: #2f328a;
  padding: 10px 20px;
  border-radius: 10px;
  border: 3px solid transparent;
}

nav a:hover {
  color: #2f328a;
  border: 3px solid #2f328a;
}

nav a.active {
  color: #fff;
  background: #2f328a;
  border: 3px solid #2f328a
}
</style>