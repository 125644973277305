<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo">IEA - Matriz de Indicadores para Resultados (MIR) y Ficha del Indicador de Desempeño (FID)</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Matriz de Indicadores para Resultados (MIR)</h2>
        <table class="table">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Matriz de Indicadores para Resultados (MIR) 2023</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/MIR-y-FID/MIR 2023.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Ficha del Indicador de Desempeño (FID)</h2>
        <table class="table">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Ficha del Indicador de Desempeño (FID) 2023</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/MIR-y-FID/FID 2023.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Zahara Lizzette Ramirez Flores',
          department: 'Departamento de Planeación Estrategica',
          phone: '449 910 5600 ext. 4099',
          updatedAt: '20/09/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "MIRyFIDView",
  components: {
    RIComponent,
  }
}
</script>

<style lang="scss" scoped>

</style>
