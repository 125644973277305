<template>
  <div class="container border rounded shadow p-3">
    <div class="row">
      <div class="col">
        <router-link to="/educacion-basica/programas-educativos" class="btn btn-ln-bg1">
          <span class="iconify" data-icon="ph:arrow-bend-down-left-bold"></span>
          Regresar
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="titulo">
          Programa de Fortalecimiento de los Servicios de Educación
          Especial (PFSEE)
        </h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>
          El Programa de Fortalecimiento de los Servicios de Educación Especial
          (PFSEE) tiene como objetivo contribuir a que las y los estudiantes de
          educación básica con discapacidad y/o con aptitudes sobresalientes de
          los Servicios de Educación Especial del PFSEE permanezcan en el ciclo
          escolar correspondiente y desarrollen habilidades para potenciar su
          aprendizaje.
        </p>
        <div class="banner_sm bg_dg-1 mb-3">
          <div class="item1">
            <p class="titulo">
              Programa de Fortalecimiento de los Servicios de Educación
              Especial (PFSEE)
            </p>
          </div>
          <div class="item2">
            <p class="contenido">Tríptico informativo</p>
          </div>
          <div class="item3">
            <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PFSEE/PFSEE-Tríptico del PFSEE.pdf" target="_blank" class="btn btn-principal-light">
              Ver Tríptico
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Reglas de Operación PFSEE</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>ACUERDO número 27/12/22 por el que se emiten las Reglas de Operación del Programa Fortalecimiento de los Servicios de Educación Especial (PFSEE) para el ejercicio fiscal 2023.</td>
              <td class="text-center" width="50">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5676022&fecha=29/12/2022#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Contraloría Social al Servicio del PFSEE</h2>
        <div class="banner_sm bg_dg-6 mb-3">
          <div class="item1">
            <p class="titulo">
              Contraloría Social al Servicio del PFSEE
            </p>
          </div>
          <div class="item2">
            <p class="contenido">Tríptico informativo</p>
          </div>
          <div class="item3">
            <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PFSEE/PFSEE-Tríptico de Contraloría Social.pdf" target="_blank" class="btn btn-principal-light">
              Ver Tríptico
            </a>
          </div>
        </div>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>PFSEE-Formato de Quejas y Denuncias.</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PFSEE/PFSEE-Formato de Quejas y Denuncias.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>PFSEE-Guía Operativa de Contraloría Social.</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PFSEE/PFSEE-Guía Operativa de Contraloría Social.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>PFSEE-Programa Estatal de Trabajo de Contraloría Social.</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PFSEE/PFSEE-Programa Estatal de Trabajo de Contraloría Social.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>PFSEE-Programa Anual de Trabajo de Contraloría Social.</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PFSEE/PFSEE-Programa Anual de Trabajo de Contraloría Social.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>PFSEE-Esquema de Contraloría Social.</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PFSEE/PFSEE-Esquema de Contraloría Social.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>Acuerdo por el que se establecen los Lineamientos para la promoción, operación y seguimiento de la contraloría social en los programas federales de desarrollo social.</td>
              <td class="text-center" width="50">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5704680&fecha=11/10/2023#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Irma Teresa Díaz Esparza y Lucia de León Ursúa',
          department: 'Departamento de Educación Especial e Inclusión y Departamento de Educación Especial y Enlace Estatal de la Contraloría Social del PFSEE',
          phone: '449 910 5600 ext. 4075',
          updatedAt: '16/11/2023'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "PFSEEView",
  components: {
    RIComponent
  }
};
</script>

<style lang="scss" scoped>

</style>
