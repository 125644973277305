<template>
  <div class="navDependencia">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="routerWrapper">
            
            <router-link to="/educacion-basica">Principal</router-link>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                ¿Quiénes somos?
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <router-link to="/educacion-basica/somos">Misión, Visión y Valores</router-link>
                </li>
                <li>
                  <router-link to="/educacion-basica/cifras-de-la-educacion">Cifras de la Educación</router-link>
                </li>
                <li>
                  <a href="http://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/somos/Plan-de-Desarrollo-Educativo-2022-2027.pdf" target="_blank" rel="noopener noreferrer">Plan de Desarrollo Educativo 2022-2027</a>
                </li>
                <li>
                  <a href="http://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/somos/La-Nueva-Escuela-Mexicana.pdf" target="_blank" rel="noopener noreferrer">La Nueva Escuela Mexicana</a>
                </li>
              </ul>
            </div>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                Niveles Educativos
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <router-link to="/educacion-basica/educacion-inicial">Educación Inicial</router-link>
                </li>
                <li>
                  <router-link to="/educacion-basica/educacion-especial">Educación Especial</router-link>
                </li>
                <li>
                  <router-link to="/educacion-basica">Educación Preescolar</router-link>
                </li>
                <li>
                  <router-link to="/educacion-basica">Educación Primaria</router-link>
                </li>
                <li>
                  <router-link to="/educacion-basica">Educación Secundaria</router-link>
                </li>
                <li>
                  <router-link to="/educacion-basica/educacion-fisica">Educación Física</router-link>
                </li>
              </ul>
            </div>

            <router-link to="/educacion-basica/SisAAE">SisAAE</router-link>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                Relación Interinstitucional y Social
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <router-link to="/educacion-basica/programas-educativos">Programas Educativos</router-link>
                </li>
              </ul>
            </div>

            <router-link to="/educacion-basica/consejo-tecnico-escolar">Consejo Técnico Escolar</router-link>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                Comunidad Multigrado
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <router-link to="/educacion-basica">Preescolar</router-link>
                </li>
                <li>
                  <router-link to="/educacion-basica">Primaria</router-link>
                </li>
                <li>
                  <router-link to="/educacion-basica">Secundaria</router-link>
                </li>
              </ul>
            </div>

          </div>
          <hr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarDEBComponent"
}
</script>

<style lang="scss" scoped>

</style>