<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Coordinación de Becas y Financiamiento Educativo</h1>
      </div>
    </div>

    <div class="row" v-if="isValidity('02/02/2024 - 13/09/2024')">
      <div class="col">
        <h2 class="subtitulo">¿Ya tienes tu expediente digital?</h2>
        <div class="banner-body-left bg_6">
          <img src="https://www.iea.gob.mx/img/convocatorias-y-becas/becas/img-banner.webp" alt="..." class="img-fluid"/>
          <div class="baninfo-wrapper">
            <h1>Expediente Digital</h1>
            <p>Papás y mamás, para participar en el proceso de Becas es necesario tener el Expediente Digital de su hijo o hija</p>
            <div class="d-flex flex-column">
              <a href="https://expedientedigital.aguascalientes.gob.mx/expediente/ui/ciudadano/Inicio.aspx" target="_blank" rel="noopener noreferrer" class="btn btn-principal-light mb-3" style="position: static;">
                Crear Expediente Digital
              </a>
              <a href="https://youtu.be/ECj_VoBAzUo?si=kM26tlXDcYfX0tos" target="_blank" rel="noopener noreferrer" class="btn btn-principal-light mb-3" style="position: static;">
                Tutorial para crear Expediente Digital
              </a>
              <a href="https://youtu.be/MPGRcrDsa08?si=PsqlMxtApO78PQgz" target="_blank" rel="noopener noreferrer" class="btn btn-principal-light" style="position: static;">
                Tutorial para solicitar una beca a través del Expediente Digital
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col">
        <div class="banner_sm bg_dg-5 mb-3">
          <div class="item1">
            <p class="titulo">Calendario de Pago de Becas</p>
          </div>
          <div class="item2">
            <p class="contenido">Calendario de Pago de Becas</p>
          </div>
          <div class="item3">
            <a href="https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/Calendario-de-Pago-de-Becas.pdf" target="_blank" class="btn btn-principal-light">
              Ver calendario
            </a>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row" v-if="scholarshipCalls.length > 0">
      <div class="col">
        <h2 class="subtitulo">Convocatorias y Resultados de Becas</h2>
        <div class="alert alert-primary d-flex align-items-center" role="alert" v-if="isValidity('02/02/2024 - 13/09/2024')">
          <span class="iconify" data-icon="ep:info-filled" width="75" height="75"></span>
          <div>
            Estimado solicitante, las becas que tienen habilitada la opción <b>Registrar Solicitud</b> requieren que tengas creado y actualizado tu <b>Expediente Digital</b>. Para crear tu Expediente Digital, visita:
            <a href="https://expedientedigital.aguascalientes.gob.mx/expediente/ui/ciudadano/Inicio.aspx" target="_blank" rel="noopener noreferrer">Expediente Digital</a>.
          </div>
        </div>
        <ConvocatoriasComponent :calls="scholarshipCalls" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="banner_sm" style="background-image: linear-gradient(0deg, rgba(41, 60, 152, 0.5) 23.02%, rgba(41, 60, 152, 0.357) 80.31%), url('https://www.iea.gob.mx/img/bg-banners.png');">
          <div class="item1">
            <p class="titulo">Coordinación de Becas y Financiamiento Educativo</p>
          </div>
          <div class="item2">
            <p class="contenido">Av. Manuel Gomez Morin S/N, Ferronales, 20180 <br> Tel. 449 910 5694</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Alberto Robles Núñez',
          department: 'Coordinación de Becas y Financiamiento Educativo',
          phone: '449 910 5694',
          updatedAt: '01/11/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ConvocatoriasComponent from '../../components/ConvocatoriasComponent.vue';
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "BecaslView",
  components: {
    ConvocatoriasComponent,
    RIComponent
  },
  data() {
    return {
      scholarshipCalls: [
        // {
        //   title: "Beca Académica - Educación Inicial y Preescolar (Ciclo Escolar 2024-2025)",
        //   type: "Convocatoria de Beca",
        //   icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_listaviaje.svg",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Académica - Educación Inicial y Preescolar (Ciclo Escolar 2024-2025).pdf",
        //       validity: "02/09/2024 - 20/09/2024"
        //     }
        //   ],
        //   validity: "02/09/2024 - 20/09/2024"
        // },
        // {
        //   title: "Beca Académica - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025)",
        //   type: "Convocatoria de Beca",
        //   icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_listaviaje.svg",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Académica - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
        //       validity: "02/09/2024 - 20/09/2024"
        //     }
        //   ],
        //   validity: "02/09/2024 - 20/09/2024"
        // },
        {
          title: "Beca Académica - Educación Básica (Ciclo Escolar 2024-2025)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_listaviaje.svg",
          links: [
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca Académica - Educación Básica (Ciclo Escolar 2024-2025).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        },
        // {
        //   title: "Beca Académica - Formación para el Trabajo, Media Superior y Superior (Segundo Semestre 2024)",
        //   type: "Convocatoria de Beca",
        //   icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_listaviaje.svg",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Académica - Formación para el Trabajo, Media Superior y Superior (Segundo Semestre 2024).pdf",
        //       validity: "02/09/2024 - 20/09/2024"
        //     }
        //   ],
        //   validity: "02/09/2024 - 20/09/2024"
        // },
        // {
        //   title: "Beca Académica - Formación para el Trabajo, Media Superior y Superior (Tercer Cuatrimestre 2024 y Primer Cuatrimestre 2025)",
        //   type: "Convocatoria de Beca",
        //   icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_listaviaje.svg",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Académica - Formación para el Trabajo, Media Superior y Superior (Tercer Cuatrimestre 2024 y Primer Cuatrimestre 2025).pdf",
        //       validity: "02/09/2024 - 20/09/2024"
        //     }
        //   ],
        //   validity: "02/09/2024 - 20/09/2024"
        // },
        {
          title: "Beca Académica - Formación para el Trabajo, Media Superior y Superior (Segundo Semestre 2024, Tercer Cuatrimestre 2024 y Primer Cuatrimestre 2025)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_listaviaje.svg",
          links: [
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca Académica - Formación para el Trabajo, Media Superior y Superior (Segundo Semestre 2024, Tercer Cuatrimestre 2024 y Primer Cuatrimestre 2025).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        },
        {
          title: "Beca Educación Especial y Barreras para el Aprendizaje - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_familiaDos.svg",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Educación Especial y Barreras para el Aprendizaje - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
              validity: "02/09/2024 - 13/09/2024"
            },
            {
              description: "Registrar Solicitud",
              link: "https://expedientedigital.aguascalientes.gob.mx/expediente/ui/ciudadano/Inicio.aspx",
              validity: "09/09/2024 - 13/09/2024"
            },
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca Educación Especial y Barreras para el Aprendizaje - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        },
        {
          title: "Beca Fortalecimiento a la Permanencia Escolar - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_cuidado.svg",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Fortalecimiento a la Permanencia Escolar - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
              validity: "02/09/2024 - 13/09/2024"
            },
            {
              description: "Registrar Solicitud",
              link: "https://expedientedigital.aguascalientes.gob.mx/expediente/ui/ciudadano/Inicio.aspx",
              validity: "09/09/2024 - 13/09/2024"
            },
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca Fortalecimiento a la Permanencia Escolar - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        },
        {
          title: "Beca IEA‑SNTE - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_dinero.svg",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca IEA‑SNTE - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
              validity: "09/09/2024 - 13/09/2024"
            },
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca IEA‑SNTE - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        },
        {
          title: "Beca IEA‑SNTE - Educación Media Superior y Superior (Segundo Semestre 2024)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_dinero.svg",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca IEA‑SNTE - Educación Media Superior y Superior (Segundo Semestre 2024).pdf",
              validity: "09/09/2024 - 13/09/2024"
            },
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca IEA‑SNTE - Educación Media Superior y Superior (Segundo Semestre 2024).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        },
        {
          title: "Beca Tu Futuro es Gigante - Educación Superior(Segundo Semestre 2024)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_derechos.svg",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Tu Futuro es Gigante - Educación Superior (Segundo Semestre 2024).pdf",
              validity: "02/09/2024 - 13/09/2024"
            },
            {
              description: "Registrar Solicitud",
              link: "https://expedientedigital.aguascalientes.gob.mx/expediente/ui/ciudadano/Inicio.aspx",
              validity: "09/09/2024 - 13/09/2024"
            },
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca Tu Futuro es Gigante - Educación Superior (Segundo Semestre 2024).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        },
        {
          title: "Estímulo a la Excelencia Educativa - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_diploma.svg",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Estímulo a la Excelencia Educativa - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
              validity: "02/09/2024 - 13/09/2024"
            },
            {
              description: "Registrar Solicitud",
              link: "https://expedientedigital.aguascalientes.gob.mx/expediente/ui/ciudadano/Inicio.aspx",
              validity: "09/09/2024 - 13/09/2024"
            },
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Estímulo a la Excelencia Educativa - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        },
        {
          title: "Estímulo a la Excelencia Educativa - Educación Media (Segundo Semestre 2024)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_diploma.svg",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Estímulo a la Excelencia Educativa - Educación Media (Segundo Semestre 2024).pdf",
              validity: "02/09/2024 - 13/09/2024"
            },
            {
              description: "Registrar Solicitud",
              link: "https://expedientedigital.aguascalientes.gob.mx/expediente/ui/ciudadano/Inicio.aspx",
              validity: "09/09/2024 - 13/09/2024"
            },
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Estímulo a la Excelencia Educativa - Educación Media (Segundo Semestre 2024).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        }
      ]
    }
  },
  methods: {
    isValidity(validity) {
      let today = new Date();

      let startDate = validity.split("-")[0].trim();

      let startDateParts = startDate.split("/");
      let startDay = parseInt(startDateParts[0], 10);
      let startMonth = parseInt(startDateParts[1], 10) - 1;
      let startYear = parseInt(startDateParts[2], 10);

      let endDate = validity.split("-")[1].trim();

      let endDateParts = endDate.split("/");
      let endDay = parseInt(endDateParts[0], 10);
      let endMonth = parseInt(endDateParts[1], 10) - 1;
      let endYear = parseInt(endDateParts[2], 10);

      startDate = new Date(startYear, startMonth, startDay);
      endDate = new Date(endYear, endMonth, endDay, 23, 59, 59, 0);

      if (today >= startDate && today <= endDate) {
        return true;
      }

      return false;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
