<template>
  <div class="banner-body-left bg_dg-7">
    <img src="img/STEAMHidrotalent/bg-banner.webp" alt="..." class="img-fluid"/>
    <div class="baninfo-wrapper">
      <h1>STEAM-IE HIDROTALENT 2023</h1>
      <p>
        Fecha límite de registro: 6 de octubre de 2023
      </p>
      <div class="d-flex align-items-end flex-column">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSeXNKSH0v0QQFBn3n-5qO5Zr52F5ThdE6QPrGDNQMi3VWsfyQ/viewform" target="_blank" class="btn btn-principal-light float-end">
          Regístrate aquí
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerComponent"
}
</script>

<style lang="scss" scoped></style>