<template>
  <header>
    <HeaderComponent />
  </header>

  <main id="main" style="margin-top: 100px; margin-bottom: 120px">
    <div class="container">
      <div class="row mb-5">
        <div class="col-md-4">
          <p class="fs-4 c_base fw-bold">
            ¿Tienes un proyecto emprendedor? Esta es tu oportunidad para exponer tu
            negocio o idea de negocio al mundo ¡Participa en el STEAM-IE HIDROTALENT
            2023!
          </p>
          <a href="https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-STEAM-IE-HIDROTALENT-2023-ANEXO-I.zip" target="_blank"  rel="noopener noreferrer" class="tarjeta bg_dg-7">
            <p class="inferior">Consulta las bases y descarga los recursos aquí</p>
          </a>
          <a href="https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/RES-Programa-de-Apoyo-para-Movilidad-HMC.pdf" target="_blank"  rel="noopener noreferrer" class="tarjeta bg_dg-3">
            <p class="inferior">Resultados de Programa de Apoyo para Movilidad "Harvarda México Conference"</p>
          </a>
        </div>
        <div class="col-md-8">
          <BannerComponent />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <img src="img/STEAMHidrotalent/img.webp" alt="..." class="img-fluid shadow border rounded" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import HeaderComponent from "@/pages/STEAMHidrotalent/components/HeaderComponent.vue";
import BannerComponent from "@/pages/STEAMHidrotalent/components/BannerComponent.vue";

export default {
  name: "STEAMHidrotalentModule",
  components: {
    HeaderComponent,
    BannerComponent,
  },
};
</script>

<style lang="scss" scoped></style>
