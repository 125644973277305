<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Educatel</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="banner-body-left bg_2">
          <img src="https://www.iea.gob.mx/img/educatel/img-banner.webp" alt="..." class="img-fluid"/>
          <div class="baninfo-wrapper">
            <h1>Línea Educatel<br> 449 974 0606</h1>
            <div class="d-flex flex-column">
              <p>¡Comunícate con nosotros!</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="banner_sm bg_dg-3 mb-3">
          <div class="item1">
            <p class="titulo">Correo electrónico Educatel</p>
          </div>
          <div class="item2">
            <p class="contenido">educatel@iea.edu.mx</p>
          </div>
          <div class="item3">
            <a href="mailto: educatel@iea.edu.mx" target="_blank" class="btn btn-principal-light">
              Enviar correo
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :infoManager="{
            name: 'Instituto de Educación de Aguascalientes',
            department: 'Instituto de Educación de Aguascalientes',
            phone: '449 910 5600',
            updatedAt: '30/1/2024'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "EducatelView",
  components: {
    RIComponent
  }
}
</script>

<style>

</style>
