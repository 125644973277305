<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="sliderGea swiper-container rounded shadow">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="slide of slides">
              <a :href="slide.link" target="_blank" v-if="'link' in slide && externalLink(slide.link)">
                <img :src="slide.img" alt="..." class="img-fluid" style="aspect-ratio: 2/1;" />
              </a>
              <router-link :to="slide.link" v-if="'link' in slide && !externalLink(slide.link)">
                <img :src="slide.img" alt="..." class="img-fluid" style="aspect-ratio: 2/1;" />
              </router-link>
              <img :src="slide.img" alt="..." class="img-fluid" style="aspect-ratio: 2/1;" v-if="!('link' in slide)" />
            </div>
          </div>
          <div class="swiper-pagination"></div>

          <!-- <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div> -->

          <!-- <div class="swiper-scrollbar"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarruselComponent",
  props: {
    slides: []
  },
  created() {
    gea.iwin(() => {
      setTimeout(() => {
        const swiper = new Swiper(".swiper-container", {
          observer: true,
          loop: true,
          speed: 1000,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          // navigation: {
          //   nextEl: ".swiper-button-next",
          //   prevEl: ".swiper-button-prev"
          // },
          // scrollbar: {
          //   el: '.swiper-scrollbar'
          // }
        });
      }, 1000);
    });
  },
  methods: {
    externalLink(link) {
      return link.includes('https://') || link.includes('http://');
    }
  }
}
</script>

<style lang="scss" scoped>

</style>