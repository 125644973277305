<template>
  <div class="header-1 bg_dg-7">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="headerContent">
            <p class="supLong mb-0" style="line-height: 1;">Servicios Digitales</p>
            <div class="d-flex">
              <p class="inf">IEA</p>
              <img src="https://www.iea.gob.mx/img/servicios-digitales/i_support.svg" class="digitalImg d-none d-lg-block"/>
            </div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="https://www.aguascalientes.gob.mx">Inicio</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="https://www.aguascalientes.gob.mx/dependencias">Dependencias</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="https://www.iea.gob.mx">IEA</a>
                </li>
                <li class="breadcrumb-item active">
                  Subdirección de Tecnologías de la Información
                </li>
              </ol>
            </nav>
            <nav class="navbar">
              <router-link to="/servicios-digitales">Servicios Digitales</router-link>
              <a href="https://www.aguascalientes.gob.mx/tramites/categoria/58/Educación">Trámites Educativos</a>
              <router-link to="/servicios-digitales/instituciones">Instituciones Educativas</router-link>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <img src="https://www.iea.gob.mx/img/servicios-digitales/divider.svg" alt="..." class="w-100">
  </div>
</template>

<script>
export default {
  name: "HeaderComponent"
}
</script>

<style lang="scss" scoped>

</style>