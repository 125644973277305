<template>
  <div class="navDependencia">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="routerWrapper">
            
            <router-link to="/">Principal</router-link>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                ¿Quiénes somos?
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <router-link to="/somos">Misión, visión y valores institucionales</router-link>
                </li>
                <li>
                  <router-link to="/objetivos">Objetivos</router-link>
                </li>
                <li>
                  <router-link to="/historia">Historia</router-link>
                </li>
                <li>
                  <router-link to="/organigrama">Organigrama</router-link>
                </li>
                <li>
                  <a href="https://www.iea.gob.mx/IEA/somos/Programa-Institucional-IEA.pdf" target="_blank">Programa Institucional</a>
                </li>
              </ul>
            </div>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                Convocatorias y Becas
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <router-link to="/becas">Becas</router-link>
                </li>
                <li>
                  <router-link to="/convocatorias">Convocatorias</router-link>
                </li>
                <li>
                  <router-link to="/adquisiciones">Procesos de adquisición</router-link>
                </li>
              </ul>
            </div>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                Sistema educativo
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <router-link to="/educacion-basica/programas-educativos">Programas educativos</router-link>
                </li>
                <li>
                  <router-link to="/participacion-social">Participación social</router-link>
                </li>
                <li>
                  <router-link to="/escuela-y-familia">Escuela y Familia</router-link>
                </li>
                <li>
                  <router-link to="/acoso-escolar">Acoso Escolar</router-link>
                </li>
                <li>
                  <router-link to="/estrategias-aula">Estrategias en el Aula</router-link>
                </li>
                <li>
                  <router-link to="/carrera-magisterio">Carrera del Magisterio</router-link>
                </li>
              </ul>
            </div>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                Niveles educativos
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <router-link to="/educacion-basica">Educación básica</router-link>
                </li>
                <li>
                  <router-link to="/educacion-media-superior">Educación media superior</router-link>
                </li>
                <li>
                  <router-link to="/educacion-superior">Educación superior</router-link>
                </li>
              </ul>
            </div>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                Trámites y Servicios
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <a href="https://www.aguascalientes.gob.mx/tramites/categoria/58/Educación">Trámites educativos</a>
                </li>
                <li>
                  <router-link to="/servicios-digitales">Servicios digitales</router-link>
                </li>
                <li>
                  <a href="https://www.iea.gob.mx/webiea/planeacion/indi_ea/index.php" target="_blank">Catálogo de escuelas</a>
                </li>
                <li>
                  <router-link to="/directorio-telefonico">Directorio telefónico IEA</router-link>
                </li>
                <li>
                  <a href="http://sippe.iea.edu.mx/" target="_blank">Control Escolar</a>
                </li>
                <li>
                  <a href="https://ieasis.iea.edu.mx/#/tablad/Cedulas?sys=ieasis%7Cpruebas%7C08000050000102" target="_blank">Consulta de Cédulas Estatales en Línea</a>
                </li>
                <li>
                  <router-link to="/servicio-social">Servicio Social y Prácticas Profesionales</router-link>
                </li>
                <li>
                  <router-link to="/incorporacion">Incorporación</router-link>
                </li>
              </ul>
            </div>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                Control Interno
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <router-link to="/scii">Control Interno</router-link>
                </li>
                <li>
                  <router-link to="/manuales">Manuales</router-link>
                </li>
                <li>
                  <router-link to="/normatividad">Normatividad</router-link>
                </li>
                <li>
                  <router-link to="/codigo-de-etica-y-conducta">Código de Ética y Conducta</router-link>
                </li>
                <li>
                  <router-link to="/comision-de-seguridad-e-higiene">Comision de Seguridad e Higiene</router-link>
                </li>
                <li>
                  <router-link to="/lineamientos">Lineamientos y políticas de escuelas</router-link>
                </li>
              </ul>
            </div>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                Transparencia IEA
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <a href="http://eservicios2.aguascalientes.gob.mx/vsiscat/Vista.aspx?d=41&t=IEA" target="_blank">Formatos de Obligaciones Públicas de Transparencia</a>
                </li>
                <li>
                  <router-link to="/informes-SRFT">Informes SRFT</router-link>
                </li>
                <li>
                  <router-link to="/reportes-SHCP">Reportes SHCP</router-link>
                </li>
                <li>
                  <router-link to="/informes-PAE">Informes PAE</router-link>
                </li>
                <li>
                  <router-link to="/SED">Sistema De Evaluación Del Desempeño (Anexo 13)</router-link>
                </li>
                <li>
                  <router-link to="/titulo-V-LGCG">Título V LGCG</router-link>
                </li>
                <li>
                  <router-link to="/IECR">Índice de Expedientes Clasificados</router-link>
                </li>
                <li>
                  <router-link to="/evaluaciones-externas">Evaluaciones Externas</router-link>
                </li>
                <li>
                  <router-link to="/MIR-y-FID">MIR y FID</router-link>
                </li>
                <li>
                  <router-link to="/FONE-y-Convenios">Indicadores FONE y Convenios Federales</router-link>
                </li>
                <li>
                  <a href="https://www.iea.gob.mx/IEA/transparencia/PDE-2022-2027.pdf" target="_blank" rel="noopener noreferrer">Plan de Desarrollo del Estado 2022-2027</a>
                </li>
                <li>
                  <router-link to="/avisos-de-privacidad">Avisos de Privacidad</router-link>
                </li>
              </ul>
            </div>
          </div>
          <hr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarIEAComponent"
}
</script>