<template>
  <header class="bg-img" style="background-image: url('https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/bg-header.webp');  background-size: cover; background-repeat: no-repeat; position: relative;">
    <div class="container">
      <div class="row ">
        <div class="col-lg-12 align-middle headerContent" style="padding-bottom: 18%;">
          <p class="supLong mb-0" style="line-height: 1;">2° Congreso de Educacion Superior</p>
          <div class="d-flex">
            <p class="inf">2024</p>
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/i_support.svg" class="digitalImg d-none d-lg-block" />
          </div>
          <p class="frase">13 y 14 de noviembre de 2024</p>
        </div>
      </div>
    </div>

    <img class="divider" src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/divider.svg" alt="">
  </header>

  <main id="main">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="mini-1">
            <h1>2° Congreso de Educacion Superior del Estado de Aguascalientes</h1>
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/i_support.svg" />
          </div>
        </div>
      </div>

      <div class="row g-5 mb-5">
        <div class="col-lg-4">
          <section>
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/banner.webp" alt="..." class="img-fluid rounded shadow" />
          </section>
        </div>
        <div class="col-lg-8">
          <section>
            <div class="banner_sm bg_dg-6">
              <div class="item1">
                <p class="titulo">
                  2° Congreso de Educacion Superior
                </p>
              </div>
              <div class="item2">
                <p class="contenido">Convocatoria y Programa</p>
              </div>
              <div class="item3">
                <a href="/IEA/niveles-educativos/educacion-superior/congreso/CVT-Segundo-Congreso-de-Educación-Superior.pdf" target="_blank" rel="noopener noreferrer" class="btn btn-principal-light me-3">
                  Ver Convocatoria
                </a>
              </div>
            </div>
          </section>
          <section>
            <h2 class="titulo">Conferencias y Talleres</h2>
            <div class="sliderGea swiper-container" style="display: block;">
              <div class="swiper-wrapper">
                <div class="swiper-slide p-5" style="background-color: white;" v-for="(taller, index) of talleres">
                  <div class="container">
                    <div class="row">
                      <div class="col">
                        <div class="d-flex flex-column flex-lg-row">
                          <div class="mini-1">
                            <h1 class="fs-4">{{ taller.tema }}</h1>
                            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/i_support.svg" height="24" class="d-block" />
                          </div>
                          <p class="text-end">
                            <b class="c_1">{{ taller.fecha }} de {{ taller.horario }}</b><br />
                            <b>{{ taller.lugar }}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p class="pill-text bg_cyan mb-3"><b>Responsable(s):</b></p>
                      </div>
                    </div>
                    <div class="row g-3 mb-3">
                      <div class="col" v-for="tallerista of taller.talleristas">
                        <div class="d-flex flex-column align-items-center justify-content-between">
                          <img :src="tallerista.imagen" alt="..." style="height: 100px;" class="img-fluid rounded-circle shadow" />
                          <p class="text-center">
                            <b>{{ tallerista.nombre }}</b>
                          </p>
                          <button class="btn btn-principal" @click="showTalleristaModal(tallerista)">Ver Semblanza</button>
                        </div>
                      </div>
                    </div>
                    <div class="row" style="text-align: justify;">
                      <div class="col">
                        <div>
                          <p class="pill-text bg_cyan mb-3"><b>Objetivo:</b></p>
                          <p>{{ taller.objetivo }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>

              <!-- <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div> -->

              <!-- <div class="swiper-scrollbar"></div> -->
            </div>
          </section>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <RIComponent :infoManager="{
            name: 'César Zapata Acosta',
            department: 'Subdirección de Fortalecimiento de la Educación Superior',
            phone: '449 149 2400 ext. 7804',
            updatedAt: '05/11/2024'
          }" />
        </div>
      </div>
    </div>
  </main>

  <!-- Modal -->
  <div class="modal fade" id="talleristaModal" tabindex="-1" aria-hidden="true" v-if="talleristaModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="subtitulo">Acerca del Tallerista</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="text-align: justify;">
          <div class="clearfix">
            <div class="float-none float-md-start m-auto m-md-3" style="max-width: 200px;">
              <div class="d-flex flex-column align-items-center">
                <img :src="talleristaModal.imagen" alt="..." style="height: 150px;" class="img-fluid rounded-circle shadow" />
                <p class="c_base text-center">
                  <b>{{ talleristaModal.nombre }}</b>
                </p>
              </div>
            </div>
            <p class="fw-bold" v-for="parrafo in talleristaModal.semblanza">
              {{ parrafo }}
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "CongresoESView",
  components: {
    RIComponent
  },
  data: () => {
    return {
      talleres: [
        {
          tema: "Conferencia magistral",
          talleristas: [
            {
              nombre: "Hugo Moreno Sánchez",
              imagen: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/talleristas/tallerista-00.webp",
              semblanza: [
                "Hugo Moreno Sánchez cursó estudios de ingeniería en electrónica, en la Universidad Autónoma Metropolitana, en la Ciudad de México. Su trayectoria académica la inició en 1981 en el Instituto de Investigaciones en Matemáticas Aplicadas y en Sistemas de la UNAM, donde realizó proyectos de desarrollo tecnológico en el campo del procesamiento digital de señales; además, fue profesor de nivel licenciatura en la Facultad de Ingeniería de dicha institución",
                "Ha sido evaluador del “Premio a la Vinculación Universidad Empresa” y del “Premio Santander a la Innovación Empresarial”. Recibió en 2010, por parte del Gobierno Francés, el nombramiento de Caballero en la Orden de las Palmas Académicas, por sus aportaciones al desarrollo de la cultura francesa en México, en las Universidades Tecnológicas."
              ]
            }
          ],
          objetivo: "La vinculación como función sustantiva en la educación superior.",
          lugar: "Sede ENSFA",
          fecha: "Miércoles 13 de noviembre de 2024",
          horario: "18:30 hrs."
        },
        {
          tema: "Taller 1. Vinculación institucional (Servicio social, prácticas profesionales y estadías)",
          talleristas: [
            {
              nombre: "Ricardo Enrique Bello Bolio",
              imagen: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/talleristas/tallerista-01.webp",
              semblanza: [
                "Ricardo Enrique Bello Bolio, es Ingeniero Civil por Facultad de Ingeniería de la Universidad Autónoma de Yucatán, tiene la Maestría en Educación Superior de la Facultad de Educación de la UDAY, la Especialización en Docencia de la misma facultad y estudios de la Especialización en Finanzas, Facultad de Contaduría y Administración de la UADY.",
                "Fue Secretario General de la Universidad Autónoma de Yucatán y Director General de Planeación de esta, fue Rector de la Universidad Tecnológica Metropolitana de Yucatán. Fue Director General de Educación Superior y Director General de Investigación e Innovación de la Secretaría de Investigación, Innovación y Educación Superior del Gobierno del Estado del Yucatán. Secretario Técnico del Sistema de investigación, Innovación y Desarrollo Tecnológico del Estado de Yucatán (SIIDETEY."
              ]
            }
          ],
          objetivo: "Fortalecer las acciones que realizan los responsables de las áreas de servicio social, prácticas profesionales y estadías de las IES en el sector productivo, a fin de que estas contribuyan a mejorar la calidad de las experiencias de los estudiantes en el entorno laboral, así como sus competencias profesionales y personales.",
          lugar: "Sede ENSFA",
          fecha: "Jueves 14 de noviembre de 2024",
          horario: "10:30 a 12:30 hrs."
        },
        {
          tema: "Taller 2. Investigación, emprendimiento e innovación",
          talleristas: [
            {
              nombre: "Eduardo Iñiguez",
              imagen: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/talleristas/tallerista-02.webp",
              semblanza: [
                "En el ámbito académico es egresado de la licenciatura en Ciencias de la Comunicación por el Tec de Monterrey (2014) y de la Stanford Business School por el programa Go To Market (2019). Más adelante cursó una certificación en Planificación Empresarial Estratégica y Habilidades para Gerentes de Alto Desempeño por el Tec de Monterrey (2018-2019). Es graduado por la Maestría en Alta Dirección de Empresas (MBA) por la Universidad Anáhuac, y de la Maestría en Responsabilidad Social y Liderazgo Sustentable por la Universidad de Barcelona. Actualmente forma parte del Programa de Dirección Regional D1-R 2023-2024 por el IPADE. Instituto Panamericano de Alta Dirección de Empresas. Cuenta con diversos diplomados por varias organizaciones e instituciones como MONEDU, Aspen University, INTÜIT, Pacto Mundial, etc.",
                "Ingresó a Enactus México en el año 2010 como estudiante, y en el año 2014, ya graduado, es invitado a unirse al equipo nacional como coordinador regional. Enactus es una organización mundial con presencia en más de 38 países que promueve la innovación y el emprendimiento social mediante la vinculación entre líderes de negocios, universitarios y académicos, reactivando la economía de México y el mundo a través del talento emprendedor universitario. En el año 2018 fue promovido a Director Nacional de Enactus México, y bajo su dirección, la operación de Enactus México se convirtió, desde el año 2019, en la más grande de la red mundial de Enactus, contando con la participación de +450 universidades."
              ]
            }
          ],
          objetivo: "Revitalizar las acciones de los responsables de promover el pensamiento crítico y la innovación en las instituciones de educación superior, con el fin de implementar estrategias que impulsen la creación de entornos emprendedores y de innovación sostenibles y dinámicos.",
          lugar: "Sede ENSFA",
          fecha: "Jueves 14 de noviembre de 2024",
          horario: "10:30 a 12:30 hrs."
        },
        {
          tema: "Taller 3. Modelo de formación DUAL y Alternancia",
          talleristas: [
            {
              nombre: "Germán Bonilla Bermúdez",
              imagen: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/talleristas/tallerista-03.webp",
              semblanza: [
                "Coordinador del Proyecto Asociación para el Desarrollo Industrial Sostenible Alemania-Alianza del Pacífico (PAGSID).",
                "Organización: Confederación de la Industria Alemana (BDI).",
                "Es Maestro en Ciencias en Ingeniería de Sistemas de Producción por la RWTH Aachen University (Alemania) e Ingeniero Mecánico Administrador por el Tecnológico de Monterrey",
                "Ha sido profesor en el Tecnológico de Monterrey y consultor para empresas extranjeras en proyectos de inversión en México y para PyMEs en proyectos de planeación estratégica, innovación, gestión de la tecnología e industria 4.0. Cuenta con más de 10 años de experiencia en proyectos de cooperación internacional con enfoque en desarrollo sostenible, innovación y educación, siendo su área de especialización la cooperación económica y desarrollo entre Alemania y la Alianza del Pacífico. Actualmente es coordinador del proyecto de cooperación PAGSID de la BDI."
              ]
            }
          ],
          objetivo: "Proporcionar a los responsables del modelo dual y de alternancia de las instituciones de educación superior, la información necesaria para facilitar la coordinación, comprensión y operación de dicho modelo, garantizando una formación profesional más sólida y acorde con las necesidades del mercado.",
          lugar: "Sede ENSFA",
          fecha: "Jueves 14 de noviembre de 2024",
          horario: "10:30 a 12:30 hrs."
        },
        {
          tema: "Taller 4. Internacionalización de los programas educativos y movilidad académica",
          talleristas: [
            {
              nombre: "Enrique Ku González",
              imagen: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/talleristas/tallerista-04.webp",
              semblanza: [
                "Enrique fue responsable de las acciones de internacionalización solidaria en la Coordinación Sectorial de Movilidad y Cooperación Académica en Educación Superior y coordinador del grupo de trabajo de la SEP para la instrumentación de la Estrategia Nacional de Educación Inclusiva.",
                "Ha sido responsable de asuntos internacionales en el Gobierno de Campeche y asesor de la Comisión de Relaciones Exteriores en el Senado de la República en las LX y LXI Legislaturas.",
                "En materia de derechos humanos, como Director General de Enlace y Desarrollo con Organismos No Gubernamentales fue responsable de los proyectos de formación, divulgación y profesionalización de la sociedad civil en políticas públicas, y colaboró en la conformación de protocolos de derechos humanos en procesos de búsqueda de personas desaparecidas desde la oficina del Comisionado Nacional de Seguridad.",
                "Es Maestro en Administración Pública por la Universidad de Columbia, Nueva York, diplomado en Ciencias Políticas por el Instituto de Estudios Políticos de Paris, y Licenciado en Relaciones Internacionales por Instituto Tecnológico y de Estudios Superiores de Monterrey."
              ]
            }
          ],
          objetivo: "Fortalecer las competencias de los responsables de movilidad e internacionalización de los programas educativos en las instituciones de educación superior, a fin de optimizar la implementación de programas de movilidad, asegurando su alineación con las necesidades académicas y profesionales de los estudiantes, promoviendo la internacionalización de la educación superior y mejorando la competitividad global de las instituciones.",
          lugar: "Sede ENSFA",
          fecha: "Jueves 14 de noviembre de 2024",
          horario: "10:30 a 12:30 hrs."
        },
        {
          tema: "Taller 5. Pertinencia de la oferta educativa (Seguimiento a egresados y empleadores, AST, y estudio de factibilidad)",
          talleristas: [
            {
              nombre: "José Cruz de León González",
              imagen: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/talleristas/tallerista-05.webp",
              semblanza: [
                "Tiene una amplia experiencia en el sector educativo. Posee estudios en Ingeniería Química y una Maestría en Administración por la Universidad Autónoma de Nuevo León. Además, cuenta con una licenciatura en Pedagogía, así como una sólida trayectoria en la asesoría y administración de proyectos educativos. Actualmente es presidente de la Fundación Genera Valor a tu Vida (2011 a la fecha) y colabora en el Consejo de Vinculación del TECNL.",
                "Ha desempeñado diversos cargos de relevancia, entre los que se incluyen: Coordinador General para la Reforma Educativa en la Secretaría de Educación de Veracruz (2006-2010), Asesor del Oficial Mayor de la SEP (2001-2005), Encargado de la Rectoría de la Universidad Tecnológica del Estado de Zacatecas (1998), Subsecretario de Planeación de la Secretaría de Educación, Cultura y Deporte del Estado de Tamaulipas, Subdirector General de Planeación Educativa de los Servicios Coordinados de Educación Pública en Nuevo León (1989-1992), y Asesor del Secretario de Educación Pública, Lic. Fernando Solana (1980-1982)."
              ]
            }
          ],
          objetivo: "Desarrollar en los responsables de seguimiento de egresados y empleadores de las instituciones de educación superior, una visión integral sobre cómo el seguimiento de estas actividades puede influir en la pertinencia y mejora continua de los programas educativos, alineándolos con las demandas del mercado laboral y las necesidades de los egresados.",
          lugar: "Sede ENSFA",
          fecha: "Jueves 14 de noviembre de 2024",
          horario: "10:30 a 12:30 hrs."
        },
        {
          tema: "Taller 6. Educación continua y servicios tecnológicos",
          talleristas: [
            {
              nombre: "Joaquín Amaro Cisneros",
              imagen: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/talleristas/tallerista-06.webp",
              semblanza: [
                "Joaquín cuenta con más de 17 años de experiencia en el área comercial relacionada con la industria de IT y OT, trabajando dentro de organizaciones globales de venta de Hardware, Software y Servicios de Nuevas Tecnologías, reclutamiento, desarrollo y habilitación de socios de Negocio, Liderazgo de Equipos de Ventas y desarrollo de estrategias de mercado en América Latina en empresas líderes en innovación como son IBM, DELL y ahora en SIEMENS.",
                "Actualmente es responsable del Desarrollo de la Estrategia Académica y otras Alianzas en SIEMENS Digital Industries Software en Mesoamérica, donde tiene la misión de generar relaciones y desarrollar nuevos negocios dentro de los ecosistemas tecnológicos; sector académico, industrial y gubernamental donde impulsa iniciativas en proyectos de transformación digital para el crecimiento de la región",
                "Parte de estas iniciativas han tenido como resultado la certificación más de 30,000 personas en soluciones SIEMENS para la Industria con validez internacional, la incorporación de soluciones para la transformación digital en distintas asignaturas nivel nacional en instituciones educativas públicas y privadas, además de liderar diagnósticos de madurez digital por industrias y territorios identificar las necesidades de las regiones y poder incorporar soluciones a estas necesidades en las instituciones educativas.",
                "Joaquín es Ingeniero en Sistemas Computarizados y Tecnologías de la Información con Subsistema en Administración y Negocios por la Universidad Iberoamericana y cuenta con la maestría en Ciencias de la Administración de Negocios por el Instituto Politécnico Naciona."
              ]
            }
          ],
          objetivo: "Identificar el papel clave de la educación continua y los servicios tecnológicos que ofertan las instituciones de educación superior, en la pertinencia de los programas educativos, la demanda del mercado laboral y las necesidades tecnológicas actuales.",
          lugar: "Sede ENSFA",
          fecha: "Jueves 14 de noviembre de 2024",
          horario: "10:30 a 12:30 hrs."
        },
      ],
      talleristaModal: null,
    }
  },
  created() {
    gea.iwin(function () {
      setTimeout(() => {
        const swiper = new Swiper(".swiper-container", {
          // obserkver: true,
          loop: true,
          speed: 1000,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          },
          // navigation: {
          //   nextEl: ".swiper-button-next",
          //   prevEl: ".swiper-button-prev"
          // },
          // scrollbar: {
          //   el: '.swiper-scrollbar'
          // },
          slidesPerView: 1,
          spaceBetween: 0,
          centeredSlides: true
        });

        // const swiperSliders = document.querySelectorAll('.swiper-slide');

        // swiperSliders.forEach((swiperSlider) => {
        //   swiperSlider.style.width = '100%';
        // });
      }, "1000");
    });
  },
  methods: {
    showTalleristaModal(tallerista) {
      this.talleristaModal = tallerista;

      gea.iwin(() => {
        const talleristaModal = bootstrap.Modal.getOrCreateInstance("#talleristaModal");
        talleristaModal.show();
      });
    }
  }
}
</script>

<style lang="scss" scoped></style>
