<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- Buscador de trámites -->
        <div id="tgea"></div>
      </div>
      <div class="col-12">
        <router-link to="/educacion-basica/los-suenos-se-construyen-en-el-aula" class="tarjeta bg_dg-7">
          <p class="inferior">
            Programa Estratégico "Los Sueños se Construyen en el Aula"
          </p>
        </router-link>
      </div>
      <div class="col-12">
        <!-- Sección de enlaces relacionados -->
        <div class="enlacesDependencias">
          <h1 class="titulo">Enlaces Relacionados</h1>
          <ul class="enlaces-list">
            <li class="enlaces-item">
              <a href="https://suenos.iea.edu.mx" target="_blank" rel="noopener noreferrer" class="enlaces-link">Plataforma "Los Sueños se Construyen en el Aula"</a>
            </li>
            <li class="enlaces-item">
              <a href="https://nomsis-desarrollo.iea.edu.mx/?sys=ieasis|pruebas" target="_blank" rel="noopener noreferrer" class="enlaces-link">Sistema de reportes para titulares de la plataforma "Los Sueños se Construyen en el Aula"</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12">
        <!-- Sección de enlaces de interés -->
        <div class="enlacesDependencias">
          <h1 class="titulo">Enlaces de Interés</h1>
          <!-- <ul class="enlaces-list">
            <li class="enlaces-item">
              <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/bachilleratos-estatales/Calendario-Escolar-2023-2024-BD.pdf" target="_blank" rel="noopener noreferrer" class="enlaces-link">Calendario Escolar 2023-2024 Bachillerato del Deporte</a>
            </li>
            <li class="enlaces-item">
              <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/bachilleratos-estatales/Calendario-Escolar-2023-2024-BAH.pdf" target="_blank" rel="noopener noreferrer" class="enlaces-link">Calendario Escolar 2023-2024 Bachillerato en Arte y Humanidades "José Guadalupe Posada"</a>
            </li>
            <li class="enlaces-item">
              <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/bachilleratos-estatales/Calendario-Escolar-2023-2024-BGMP.pdf" target="_blank" rel="noopener noreferrer" class="enlaces-link">Calendario Escolar 2023-2024 Bachillerato General Militarizado Poniente "1er Escuadrón de Lanceros de Aguascalientes"</a>
            </li>
            <li class="enlaces-item">
              <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/bachilleratos-estatales/Calendario-Escolar-2023-2024-BGMO.pdf" target="_blank" rel="noopener noreferrer" class="enlaces-link">Calendario Escolar 2023-2024 Bachillerato General Militarizado Oriente "José María Chávez"</a>
            </li>
          </ul> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarDEBComponent",
};
</script>

<style lang="scss" scoped>

</style>
