<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col">
        <p>
          La legalidad, veracidad y la calidad de la información es estricta
          responsabilidad de la dependencia, entidad o empresa productiva del
          Estado que la proporcionó en virtud de sus atribuciones y/o facultades
          normativas.
        </p>
        <hr />
        <p class="text-end c_2">
          <strong class="c_base">Responsable de la información: </strong>{{ infoManager.name }}<br />
          <strong class="c_base">Área: </strong>{{ infoManager.department }}<br />
          <strong class="c_base">Teléfono: </strong>{{ infoManager.phone
          }}<br />
          <strong class="c_base">Fecha de actualización: </strong>{{ date }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RIComponent",
  props: {
    infoManager: {
      name: String,
      department: String,
      phone: String,
      updatedAt: String,
    },
  },
  data() {
    return {
      months: [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre",
      ],
    }
  },
  computed: {
    currentDate() {
      const currentDate = new Date();
      return `${currentDate.getDate()}/${(currentDate.getMonth() + 1)}/${currentDate.getFullYear()}`
    },
    date() {
      let date = this.infoManager.updatedAt != null ? this.infoManager.updatedAt.split("/") : this.currentDate.split("/");
      return `${date[0]} de ${this.months[date[1] - 1]} de ${date[2]}`;
    },
  },
};
</script>
