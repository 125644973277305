<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div id="audio-player-container">
          <audio :src="audio.path" ref="audioPlayer" loop @timeupdate="onPlaying"></audio>
          <div :class="bg + ' rounded d-flex flex-column justify-content-center align-items-center'" style="min-height: 220px; min-width: 180px">
            <div class="text-center text-white fst-italic fw-bold">{{ title }}</div>
            <div class="text-center text-white fst-italic fw-bold fs-1">{{ subtitle }}</div>
          </div>

          <p class="fw-bold mt-5">{{ audio.name }}</p>

          <div v-if="!playing">
            <button id="play-icon" @click="play">
              <span class="iconify" data-icon="bi:play" width="50" height="50"></span>
            </button>
          </div>
          <div v-else>
            <button id="play-icon" @click="pause">
              <span class="iconify" data-icon="bi:pause" width="50" height="50"></span>
            </button>
          </div>

          <div class="timeContainer">
            <input class="audioPlayerInput" type="range" id="seek-slider" min="0" max="100" v-model="seekValue" @change="onSeek" />
            <span>{{ currentTime == 0 ? "0:00" : currentTime }}</span>
            <span>{{ duration == "NaN:NaN" ? "0:00" : duration }}</span>
          </div>

          <!-- <div class="soundContainer">
            <output id="volume-output">100</output>
            <input class="audioPlayerInput" type="range" id="volume-slider" max="100" value="100" />
            <button id="mute-icon"></button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReproductorAudioComponent",
  props: {
    title: String,
    subtitle: String,
    audio: {
      name: String,
      path: String
    },
    bg: String
  },
  data() {
    return {
      duration: 0,
      currentTime: 0,
      seekValue: 0,
      playing: false
    };
  },
  created() {
    gea.iwin(() => {
      this.duration = this.calculateTime(this.$refs.audioPlayer.duration);
    });
  },
  updated() {
    gea.iwin(() => {
      this.duration = this.calculateTime(this.$refs.audioPlayer.duration);
    });
  },
  methods: {
    play() {
      this.$refs.audioPlayer.play();
      this.playing = true;
    },
    pause() {
      this.$refs.audioPlayer.pause();
      this.playing = false;
    },
    stop() {
      const { audioPlayer } = this.$refs;
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
    },
    setSpeed(speed) {
      this.$refs.audioPlayer.playbackRate = speed;
    },
    onPlaying() {
      const { audioPlayer } = this.$refs;
      if (!audioPlayer) {
        return;
      }
      this.currentTime = this.calculateTime(audioPlayer.currentTime);
      this.seekValue = (audioPlayer.currentTime / audioPlayer.duration) * 100;
    },
    onSeek() {
      const { audioPlayer } = this.$refs;
      const seekto = audioPlayer.duration * (this.seekValue / 100);
      audioPlayer.currentTime = seekto;
    },
    calculateTime(secs) {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    }
  },
};
</script>

<style>
  
</style>