<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Escuela y Familia</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Programa Hazlo por tu hijo</h2>
      </div>
      <div class="col-12">
        <p>
          El programa de prevención <b>Hazlo por tu hijo</b>, promueve ambientes
          seguros en planteles educativos públicos de niveles básico y medio
          superior en el estado, con el apoyo de padres de familia, docentes y
          autoridades educativas.
        </p>
      </div>
      <div class="col-12">
        <div class="banner_sm bg_dg-2 mb-3">
          <div class="item1">
            <p class="titulo">Programa de prevención Hazlo por tu hijo</p>
          </div>
          <div class="item2">
            <p class="contenido">Protocolo de actuación</p>
          </div>
          <div class="item3">
            <a href="IEA/sistema-educativo/escuela-y-familia/Programa-Hazlo-Por-Tu-Hijo.pdf" target="_blank" class="btn btn-principal-light">
              Ver protocolo
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Protocolo de acoso escolar</h2>
      </div>
      <div class="col-12">
        <p>
          Protocolo para prevenir, atender, sancionar y erradicar el acoso y el
          hostigamiento sexual en las instituciones formadoras y actualizadoras
          de docentes del instituto de educación de Aguascalientes.
        </p>
      </div>
      <div class="col-12">
        <div class="banner_sm bg_dg-6 mb-3">
          <div class="item1">
            <p class="titulo">Protocolo de acoso escolar</p>
          </div>
          <div class="item2">
            <p class="contenido">Protocolo de prevención</p>
          </div>
          <div class="item3">
            <a href="IEA/sistema-educativo/escuela-y-familia/PROTOCOLO_ACOSO.pdf" target="_blank" class="btn btn-principal-light">
              Ver protocolo
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">
          Campaña de información para visualizar la violencia contra la mujer en
          los hogares
        </h2>
        <p>
          La
          <b>Dirección General de Educación Indígena, Intercultural y Bilingüe de la SEP</b>,
          a través del <b>Instituto de Educación de Aguascalientes</b>,
          impulsa una campaña de información para visualizar la violencia contra
          la mujer en los hogares, escuelas y otros espacios públicos de
          contextos indígenas en el Estado de Aguascalientes, a través de 6
          spots (2 de contexto familiar, 2 de contexto escolar y 2 de contexto
          comunitario) y materiales gráficos (3 carteles y una infografía). Todo
          esto en el marco del Programa Integral, para Prevenir, Atender,
          Sancionar y Erradicar la Violencia contra las Mujeres PIPASEVM
          (2019-2024) a cargo de la Secretaría de Gobernación.
        </p>
        <p>
          La campaña tiene como propósito que la población indígena identifique
          y/o reconozca la violencia de género y, a su vez, modifique patrones
          de comportamiento que afectan la integridad de las mujeres en los
          diferentes espacios de la comunidad.
        </p>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <ReproductorAudioComponent
          title="Escuela y Familia"
          subtitle="Spots de Radio"
          :audio="audio"
          bg="bg_dg-6"
        />
      </div>
      <div class="col">
        <div class="container">
          <div class="row row-cols-1 g-1">
            <div class="col d-flex" v-for="audio of spots">
              <button class="btn btn-ln-bg2 w-100 text-start p-2" @click="changeAudio(audio)">
                {{ audio.name }}
              </button>
              <a :href="audio.path" download class="c_2 p-2">
                <span class="iconify" data-icon="akar-icons:download" width="35" height="35"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2 g-3">
      <div class="col">
        <img src="https://www.iea.gob.mx/img/sistema-educativo/escuela-y-familia/cartel_01.jpg" alt="..." class="img-fluid rounded shadow" />
      </div>
      <div class="col">
        <img src="https://www.iea.gob.mx/img/sistema-educativo/escuela-y-familia/cartel_02.jpg" alt="..." class="img-fluid rounded shadow" />
      </div>
      <div class="col">
        <img src="https://www.iea.gob.mx/img/sistema-educativo/escuela-y-familia/cartel_03.jpg" alt="..." class="img-fluid rounded shadow" />
      </div>
      <div class="col">
        <img src="https://www.iea.gob.mx/img/sistema-educativo/escuela-y-familia/infografia_01.jpg" alt="..." class="img-fluid rounded shadow" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :infoManager="{
            name: 'David Alejandro López Rubalcava',
            department: 'Coordinación de Comunicación Social',
            phone: '449 910 5600 ext. 4372',
            updatedAt: '20/4/2023',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ReproductorAudioComponent from "@/pages/www/components/ReproductorAudioComponent.vue";
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "EscuelaYFamiliaView",
  components: {
    ReproductorAudioComponent,
    RIComponent,
  },
  data: () => {
    return {
      spots: [
        {
          name: "Acoso escolar",
          path: "https://www.iea.gob.mx/IEA/sistema-educativo/escuela-y-familia/spots/acoso-escolar.mp3"
        },
        {
          name: "Costumbres",
          path: "https://www.iea.gob.mx/IEA/sistema-educativo/escuela-y-familia/spots/costumbres.mp3"
        },
        {
          name: "Violencia de género",
          path: "https://www.iea.gob.mx/IEA/sistema-educativo/escuela-y-familia/spots/violencia-de-genero.mp3"
        },
        {
          name: "Violencia en el mercado",
          path: "https://www.iea.gob.mx/IEA/sistema-educativo/escuela-y-familia/spots/violencia-en-el-mercado.mp3"
        },
        {
          name: "Violencia familiar",
          path: "https://www.iea.gob.mx/IEA/sistema-educativo/escuela-y-familia/spots/violencia-familiar.mp3"
        },
        {
          name: "Violencia de pareja",
          path: "https://www.iea.gob.mx/IEA/sistema-educativo/escuela-y-familia/spots/violencia-pareja.mp3"
        }
      ],
      audio: {
        name: "Acoso escolar",
        path: "https://www.iea.gob.mx/IEA/sistema-educativo/escuela-y-familia/spots/acoso-escolar.mp3"
      }
    };
  },
  methods: {
    changeAudio(audio) {
      this.audio = audio;
    },
  },
};
</script>

<style></style>
