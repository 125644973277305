<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <NoticiaComponent v-if="!loading" :news="news" />
      </div>
    </div>
  </div>
</template>

<script>
import NoticiaComponent from '@/components/NoticiaComponent.vue';

export default {
  name: 'NoticiaView',
  components: {
    NoticiaComponent
  },
  data() {
    return {
      news: Object,
      loading: true
    }
  },
  created() {
    gea.iwin(() => {
      axios.get("https://servicios.iea.edu.mx/ieaws/ws-sgc/news/get/" + this.$route.params.id)
        .then((response) => {
          this.loading = false;
          this.news = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
}
</script>
