/**
 * Módulo de rutas del módulo ExpoUniversidadesModule. Contiene las definiciones
 * de rutas de la Página de la Expo Universidades (https://www.iea.gob.mx/expouniversidades).
 */

// Componente principal del módulo...
import ModuleComponent from "../ModuleComponent.vue";

// Expo Universidades...
import UniversidadesView from '../views/UniversidadesView.vue';
import UniversidadView from '../views/UniversidadView.vue';

const moduleRoute = {
  path: '/expouniversidades',
  component: ModuleComponent,
  meta: { title: 'IEA | Expo Universidades' },
  children: [
    { path: '', component: UniversidadesView },
    { path: ':siglas', component: UniversidadView },
  ]
};

export default moduleRoute;