<template>
  <div class="container border rounded shadow p-3">
    <div class="row">
      <div class="col">
        <router-link to="/educacion-basica/programas-educativos" class="btn btn-ln-bg1">
          <span class="iconify" data-icon="ph:arrow-bend-down-left-bold"></span>
          Regresar
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="titulo">Programa Nacional de Inglés (PRONI)</h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p>
          El Programa Nacional de Inglés en el estado de Aguascalientes
          beneficia a alrededor de 157,591 alumnos en los niveles de preescolar,
          primaria y secundaria de todos los municipios del estado y los cuales
          son atendidos por docentes y asesores externos especializados, mismos
          que están capacitados para llevar los aprendizajes de la lengua
          extranjera (Inglés) a dichos alumnos, contribuyendo así a promover el
          acceso igualitario entre hombres y mujeres a los beneficios del
          programa.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="banner_sm mb-5" style="background-image: linear-gradient(0deg, rgba(41, 60, 152, 0.5) 23.02%, rgba(41, 60, 152, 0.357) 80.31%), url('https://www.iea.gob.mx/img/bg-banners.png');">
          <div class="item1">
            <p class="titulo">Coordinación de Idiomas</p>
          </div>
          <div class="item2">
            <p class="contenido">Teléfonos <br /> 449 970 6156 <br /> 449 970 6025</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <router-link to="/educacion-basica/programas-educativos/PRONI/preescolar-y-primaria" class="nav-link fw-bold c_base" active-class="active">
              PREESCOLAR Y PRIMARIA
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/educacion-basica/programas-educativos/PRONI/secundaria" class="nav-link fw-bold c_base" active-class="active" aria-current="page" is>
              SECUNDARIA
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <router-view></router-view>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Contraloría Social en el PRONI</h2>
        <p>
          Como parte de las acciones y en cumplimiento a los requerimientos de
          Contraloría Social para el Ejercicio Fiscal 2023. Nos permitimos
          compartir los trípticos digitales del la Contraloría Social en el PRONI.
        </p>
        <div class="banner_sm bg_dg-7 mb-3">
          <div class="item1">
            <p class="titulo">
              Contraloría Social en el PRONI
            </p>
          </div>
          <div class="item2">
            <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/Folleto-de-Contraloría-Social.pdf" target="_blank" class="btn btn-principal-light">
              Ver Folleto
            </a>
          </div>
          <div class="item3">
            <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/Tríptico-de-Contraloría-Social.pdf" target="_blank" class="btn btn-principal-light">
              Ver Tríptico
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/Folleto-de-Comité-de-Contraloría-Social.pdf" target="_blank" class="pillLink m-0">
          <div class="pill-card">
            <div class="d-inline-flex align-items-center">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_familiaDos.svg" alt="..." class="img-fluid" />
              <div>Folleto de Comité de Contraloría Social</div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-6">
        <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/Tríptico-de-Comité-de-Contraloría-Social.pdf" target="_blank" class="pillLink m-0">
          <div class="pill-card">
            <div class="d-inline-flex align-items-center">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_familiaDos.svg" alt="..." class="img-fluid" />
              <div>Tríptico de Comité de Contraloría Social</div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-6">
        <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/Folleto-de-Quejas-y-Denuncias.pdf" target="_blank" class="pillLink m-0">
          <div class="pill-card">
            <div class="d-inline-flex align-items-center">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_derechos.svg" alt="..." class="img-fluid" />
              <div>Folleto de Quejas y Denuncias</div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-6">
        <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/Tríptico-de-Quejas-y-Denuncias.pdf" target="_blank" class="pillLink m-0">
          <div class="pill-card">
            <div class="d-inline-flex align-items-center">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_derechos.svg" alt="..." class="img-fluid" />
              <div>Tríptico de Quejas y Denuncias</div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-12">
        <p class="text-center fw-bold fst-italic">
          "Este programa es público, ajeno a cualquier partido político. Queda
          prohibido el uso para fines distintos a los establecidos en el programa".
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Redes sociales de la Coordinación de Idiomas</h2>
      </div>
      <div class="col-12">
        <div class="redes-button-wrapper justify-content-center">
          <a href="https://www.facebook.com/Coordinaci%C3%B3n-de-Idiomas-102936132573107/" target="_blank">
            <div class="fb-button">
              <div class="button-content">
                <img src="https://www.aguascalientes.gob.mx/img/Iconografia/fb.svg" alt="fb" class="text-center" />
                <p class="red-button-title text-center">Facebook</p>
              </div>
            </div>
          </a>
          <a href="https://twitter.com/idiomas_coord_?s=20&t=yM3dKac3_Ee-MmhG-c4PhA" target="_blank">
            <div class="twitter-button">
              <div class="button-content">
                <img src="https://www.aguascalientes.gob.mx/img/Iconografia/twitter.svg" alt="twitter" class="text-center" />
                <p class="red-button-title">Twitter</p>
              </div>
            </div>
          </a>
          <a href="https://instagram.com/coordinacion_idiomas?igshid=YmMyMTA2M2Y=" target="_blank">
            <div class="insta-button">
              <div class="button-content">
                <img src="https://www.aguascalientes.gob.mx/img/Iconografia/insta.svg" alt="youtube" class="text-center" />
                <p class="red-button-title">Instagram</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Coordinación de Idiomas',
          department: 'Coordinación de Idiomas',
          phone: '449 970 6156 / 449 970 6025',
          updatedAt: '15/10/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "PRONIView",
  components: {
    RIComponent
  }
}
</script>

<style lang="scss" scoped>

</style>
