<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">
          IEA - Reportes Secretaría de Hacienda y Crédito Público (SHCP)
        </h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Ejercicio Fiscal 2023</h2>
        <table class="table">
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Periódico Oficial del Estado</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td>Primer trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/1T-2023 POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Primer trimestre (Oficio de Publicación en POE)</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/1T-2023 Oficio de Publicación en POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Segundo trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/2T-2023 POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Segundo trimestre (Oficio de Publicación en POE)</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/2T-2023 Oficio de Publicación en POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Tercer trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/3T-2023 POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Tercer trimestre (Oficio de Publicación en POE)</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/3T-2023 Oficio de Publicación en POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Cuarto trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/4T-2023 POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Cuarto trimestre (Oficio de Publicación en POE)</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/4T-2023 Oficio de Publicación en POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>Destino del gasto</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td>Primer trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/1T-2023 AGS Destino del gasto.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/1T-2023 AGS Destino del gasto.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Segundo trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/2T-2023 AGS Destino del gasto.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/2T-2023 AGS Destino del gasto.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Tercer trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/3T-2023 AGS Destino del gasto.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/3T-2023 AGS Destino del gasto.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Cuarto trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/4T-2023 AGS Destino del gasto.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/4T-2023 AGS Destino del gasto.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>Ejercicio del gasto</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td>Primer trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/1T-2023 AGS Ejercicio del Gasto.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/1T-2023 AGS Ejercicio del Gasto.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Primer trimestre. Extracción del SRFT - Ejercicio del Gasto (Desglose)</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/1T-2023 Extracción del SRFT - Ejercicio del Gasto (Desglose).csv" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Segundo trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/2T-2023 AGS Ejercicio del Gasto.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/2T-2023 AGS Ejercicio del Gasto.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Tercer trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/3T-2023 AGS Ejercicio del Gasto.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/3T-2023 AGS Ejercicio del Gasto.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Cuarto trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/4T-2023 AGS Ejercicio del Gasto.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/4T-2023 AGS Ejercicio del Gasto.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>Indicadores</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td>Primer trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/1T-2023 AGS Indicadores.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/1T-2023 AGS Indicadores.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Segundo trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/2T-2023 AGS Indicadores.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/2T-2023 AGS Indicadores.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Tercer trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/3T-2023 AGS Indicadores.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/3T-2023 AGS Indicadores.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Cuarto trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/4T-2023 AGS Indicadores.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2023/4T-2023 AGS Indicadores.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Ejercicio Fiscal 2022</h2>
        <table class="table">
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Periódico Oficial del Estado</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td>Informe definitivo</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2022/ID-2022 POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Informe definitivo (Oficio de Publicación en POE)</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2022/ID-2022 Oficio de Publicación en POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>Destino del gasto</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td>Informe definitivo</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2022/ID-2022 AGS Destino del gasto.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2022/ID-2022 AGS Destino del gasto.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>Ejercicio del gasto</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td>Informe definitivo</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2022/ID-2022 AGS Ejercicio del Gasto.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2022/ID-2022 AGS Ejercicio del Gasto.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>Indicadores</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td>Informe definitivo</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2022/ID-2022 AGS Indicadores.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SHCP/2022/ID-2022 AGS Indicadores.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :infoManager="{
            name: 'Miguel Ángel Franco Herrera',
            department: 'Departamento de Administración de Programas Federales y Fideicomisos',
            phone: '449 910 5600 ext. 4218',
            updatedAt: '12/02/2024'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "ReportesSHCPView",
  components: {
    RIComponent
  }
};
</script>

<style></style>
