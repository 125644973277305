<template>
  <div class="position-relative">
    <div id="alert" class="position-fixed top-50 start-50 translate-middle" style="z-index: 1090;"></div>
  </div>
  
  <!-- Header -->
  <header id="hgea"></header>

  <main id="biea">
    <router-view />
  </main>

  <!-- Footer -->
  <footer id="fgea"></footer>
</template>

<script>
export default {
  name: "App"
}
</script>