import { createRouter, createWebHistory } from 'vue-router';

// Módulo (Module) del Sitio Web del Instituto de Educación de Aguascalientes...
import WwwModule from '../pages/www';

// Módulo (Module) de la página web de la "Expo Universidades"...
import ExpoUniversidadesModule from '../pages/expouniversidades';

// Módulo (Module) de la página web del evento "STEAM Hidrotalent"...
import STEAMHidrotalentModule from '../pages/STEAMHidrotalent';

// Módulo (Module) de la página web de "Educación Básica"...
import EducacionBasicaModule from '../pages/educacion-basica';

// Módulo (Module) de la página web de "Educación Media Superior"...
import EducacionMediaSuperiorModule from '../pages/educacion-media-superior';

// Módulo (Module) de la página web de "Educación Superior"...
import EducacionSuperiorModule from '../pages/educacion-superior';
import CongresoESView from '@/views/CongresoESView.vue';

// Módulo (Module) de la página web de "Servicios Digitales"...
import HerramientasDigitalesModule from '../pages/herramientas-digitales';

// Módulo (Module) de la página web de "Servicios Digitales"...
import ServiciosDigitalesModule from '../pages/servicios-digitales';

// Vista (View) de Noticia...
import NoticiaView from '../views/NoticiaView.vue';

// Vista (View) de Beneficiarios...
import BonosView from '../views/BonosView.vue';

import BSSPEView from '../views/BSSPEView.vue';
import BCMOVView from '../views/BCMOVView.vue';

const PasosGigantes = { template: "", created() { window.location.href = "https://aguascalientes.mdt.mx"; } }
const InternetEscolar2024 = { template: "", created() { window.location.href = "https://docs.google.com/spreadsheets/d/1pMqcdpKOEDo8z_bVIrd4OK4mpmZVm4cwp7qJX5QHDpA/edit#gid=516394291"; } }

// Vista (View) de página no encontrada (Error 404)...
const NotFound = { template: "", created() { window.location.href = "https://www.aguascalientes.gob.mx/404.html"; } }

const routes = [
  WwwModule.routes,
  { path: '/noticia/:id', component: NoticiaView, meta: { title: 'IEA | Noticias' }},
  { path: '/bonos', component: BonosView, meta: { title: 'IEA | Bonos' }},
  ExpoUniversidadesModule.routes,
  STEAMHidrotalentModule.routes,
  EducacionBasicaModule.routes,
  EducacionMediaSuperiorModule.routes,
  EducacionSuperiorModule.routes,
  HerramientasDigitalesModule.routes,
  ServiciosDigitalesModule.routes,
  { path: '/educacion-superior/congreso', component: CongresoESView, meta: { title: 'IEA | 2° Congreso de Educación Superior' } },
  { path: '/BSSPE', component: BSSPEView, meta: { title: 'IEA | BSSPE' }},
  { path: '/BCMOV', component: BCMOVView, meta: { title: 'IEA | BCMOV' }},
  { path: '/pasosgigantes', component: PasosGigantes, meta: { title: 'IEA | Pasos Gigantes'} },
  { path: '/internetescolar2024', component: InternetEscolar2024, meta: { title: 'IEA | Internet Escolar 2024'} },
  { path: '/:pathMatch(.*)*', component: NotFound }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    let mainPaths = routes.map((route) => {
      return route.path;
    });

    if (!savedPosition) {
      if (mainPaths.includes(to.path)) {
        return {
          top: 0
        }
      } else {
        // Scroll 250px above the element #main
        return {
          // could also be
          // el: document.getElementById('main'),
          el: '#main',
          top: 250
        }        
      }
    } else {
      return {
        top: 0
      }
    }
  }
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
