/**
 * Módulo de rutas del módulo EducacionSuperiorModule. Contiene las definiciones
 * de rutas de la Página de la Dirección de Educación Superior
 * (https://www.iea.gob.mx/educacion-superior).
 */

// Componente raiz del módulo...
import ModuleComponent from '../ModuleComponent.vue';

// Vista (View) "Educación Superior IEA"...
import PrincipalView from "../views/PrincipalView.vue";

// Vistas (Views) del menú "¿Quiénes somos?"...
import SomosView from "../views/somos/SomosView.vue";
import PlanEstrategicoView from "../views/somos/PlanEstrategicoView.vue";
import SEFEMSySView from '../views/somos/SEFEMSySView.vue';

// Vistas (Views) del menú "Oferta Educativa"...
import OfertaEducativaView from "../views/OfertaEducativaView.vue";

// Vistas (Views) del menú "Instituciones"...
import UniversidadesTecnologicasView from "../views/instituciones/UniversidadesTecnologicasView.vue";
import EscuelasNormalesView from "../views/instituciones/EscuelasNormalesView.vue";

// Vista (View) "Programas Transversales"...
import ProgramasTransversalesView from '../views/ProgramasTransversalesView.vue';

// Vistas (Views) del menú de "Servicios"...
import CedulasProfesionalesEstatalesView from "../views/servicios/CedulasProfesionalesEstatalesView.vue";
import ColegiosProfesionistasView from '../views/servicios/ColegiosProfesionistasView.vue';
import IncorporacionView from '../views/servicios/IncorporacionView.vue';

const routes = {
  path: '/educacion-superior',
  component: ModuleComponent,
  meta: { title: 'IEA | Educación Superior' },
  children: [
    { path: '', component: PrincipalView },

    { path: 'somos', component: SomosView },
    { path: 'ejes', component: PlanEstrategicoView },
    { path: 'SEFEMSyS', component: SEFEMSySView },

    { path: 'oferta-educativa', component: OfertaEducativaView },

    { path: 'universidades-tecnologicas', component: UniversidadesTecnologicasView },
    { path: 'escuelas-normales', component: EscuelasNormalesView },

    { path: 'cedulas', component: CedulasProfesionalesEstatalesView },
    { path: 'colegios', component: ColegiosProfesionistasView },
    { path: 'incorporacion', component: IncorporacionView },
    { path: 'programas-transversales', component: ProgramasTransversalesView }
  ]
}

export default routes;