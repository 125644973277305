<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="mini-1">
          <h1>Canva para la Educación</h1>
          <img src="https://www.iea.gob.mx/img/servicios-digitales/i_support.svg" />
          <h2>Estrategia de Formación de Capacidades</h2>
        </div>
        <p>
          Como parte del <b>Catálogo de Herramientas Digitales para la Educación</b>,
          Canva se ha consolidado como una plataforma clave para potenciar la
          creatividad y la innovación en el ámbito educativo. Gracias a su integración
          con las cuentas de correo institucional del Instituto de Educación de
          Aguascalientes, los usuarios de cuentas de correo institucional con
          dominios <b>iea.edu.mx</b> y <b>ags.nuevaescuela.mx</b> tienen
          <b>Acceso Gratuito a la versión Pro de Canva</b>. Esta herramienta ofrece
          un sinfín de recursos visuales y plantillas personalizables que facilitan
          tanto a estudiantes como a docentes la creación de presentaciones,
          infografías y otros materiales didácticos de forma profesional y sencilla,
          enriqueciendo así el proceso de enseñanza y aprendizaje.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_webv2.svg" alt="..." />
          <h3 class="titulo">Empieza a Usar a Canva</h3>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <p>
          El acceso a Canva es sumamente sencillo para los usuarios del Instituto de
          Educación de Aguascalientes. Basta con dirigirse al Waffle de Google, el
          menú de aplicaciones de Google ubicado en la esquina superior derecha de
          la pantalla, donde encontrarán el acceso directo a Canva.
          Alternativamente, pueden iniciar sesión directamente en la página de Canva
          utilizando su cuenta de correo institucional, ya sea de los dominios
          <b>iea.edu.mx</b> o <b>ags.nuevaescuela.mx</b>, y disfrutar inmediatamente
          de las ventajas de la versión Pro sin ningún costo adicional. Esta integración
          simplifica el uso de la herramienta, permitiendo un acceso rápido y eficiente.
        </p>
      </div>
      <div class="col-12 col-lg-6 text-center">
        <img src="https://www.iea.gob.mx/img/herramientas-digitales/img-acceso-canva.webp" alt="..." class="img-fluid rounded shadow" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_diseno.svg" alt="..." />
          <h3 class="titulo">Integración con Affinity</h3>
        </div>
        <p>
          Adicionalmente, el Instituto de Educación de Aguascalientes ahora cuenta
          con acceso gratuito a <b>Affinity</b> para todas las escuelas verificadas en
          Canva para la Educación. Esta herramienta complementa la oferta digital
          con potentes aplicaciones para la creación de contenido visual y
          publicaciones, permitiendo a estudiantes y docentes expandir su capacidad
          creativa. Entre los beneficios que ofrece Affinity se incluyen:
        </p>
        <ul>
          <li>
            Affinity Photo 2: Para edición y retoque de fotos, creación de
            composiciones fotográficas y arte digital mediante capas.
          </li>
          <li>
            Affinity Designer 2: Ideal para diseñar ilustraciones, logotipos,
            gráficos y otros trabajos de arte vectorial.
          </li>
          <li>
            Affinity Publisher 2: Perfecto para crear publicaciones digitales o
            impresas, combinando imágenes, gráficos y texto en un diseño
            profesional.
          </li>
        </ul>
        <p>
          Con esta integración, la comunidad educativa podrá aprovechar al máximo la
          suite de herramientas creativas de Affinity, complementando su experiencia
          con Canva Pro para la creación de recursos educativos impactantes.
        </p>
        <div class="alert alert-primary d-flex align-items-center" role="alert">
          <span class="iconify" data-icon="ep:info-filled" width="75" height="75"></span>
          <div>
            Affinity es una suite de software profesional que incluye herramientas
            para la edición de fotos, diseño gráfico y maquetación de publicaciones.
            Affinity está compuesta por tres aplicaciones principales:
            <b>Affinity Photo</b>, <b>Affinity Designer</b>, y <b>Affinity Publisher</b>.
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'José Roberto Pereda Delgado',
          department: 'Subdirección de Tecnologías de la Información',
          phone: '449 910 5600 ext. 4915',
          updatedAt: '20/09/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "CanvaView",
  components: {
    RIComponent
  }
}
</script>

<style lang="scss" scoped>

</style>