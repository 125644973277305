<template>
  <div class="container" style="margin-top: 120px;">
    <div class="row">
      <div class="col">
        <div class="mini-1 text-center">
          <h1>Bonos a Jubilados, Beneficiarios de Pensión Alimenticia y Homologados del IEA</h1>
          <h2>Consulta de Pago de Bono de Diciembre 2023</h2>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col col-md-6 text-center">
        <div class="mb-3">
          <select class="form-select" v-model="selected">
            <option disabled value="">Seleccione un filtro de búsqueda</option>
            <option v-for="option in options" :value="option.value">Buscar por {{ option.text }}</option>
          </select>
        </div>
        <div class="mb-3">
          <input placeholder="Buscar" v-model="textSearch" style="text-transform: uppercase;" />
          <div v-if="selected == 'nombre'" class="form-text">Para búsquedas por nombre, inicie por sus apellidos. Omita el uso de acentos.</div>
        </div>
        <div class="mb-3">
          <button class="btn btn-form" @click="search()">Buscar</button>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col col-md-6">
        <div v-if="jubilado.nombre != null" class="p-3 rounded border">
          <p class="c_base fw-bold text-center">Datos del {{ jubilado.estatus }}</p>
          <hr />
          <p><b>Nombre:</b> {{ jubilado.nombre }}</p>
          <p><b>CURP:</b> {{ jubilado.curp }}</p>
          <p><b>RFC:</b> {{ jubilado.rfc }}</p>
          <p><b>Tipo de pago:</b> {{ jubilado.pago }}</p>
          <div v-if="(jubilado.curp.length < 18 || jubilado.rfc.length < 13)">
            <hr />
            <p class="text-center fw-bold c_2">
              Estimado(a) {{ jubilado.estatus }}. Sus datos están incompletos, es necesario acudir a la
              Dirección de Finanzas y Administración a la Jefatura del Departamento de
              Servicios al personal del IEA para completar su información personal.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <p>
          Los jubilados que no se encuentren en la base de datos, podrán acudir a la
          Dirección de Finanzas y Administración a la Jefatura del Departamento de
          Servicios al personal del IEA, lo que podrá hacerse personalmente o a través
          de una persona autorizada con carta poder otorgada ante dos testigos
          únicamente los días 06 y 07 de diciembre de 2022, a efecto de registrarse
          como beneficiarios del Programa, para lo cual presentarán la siguiente
          documentación:
        </p>
        <p>
          Credencial de jubilado, hoja única de servicios, Identificación oficial con
          fotografía, comprobante de domicilio, proporcionar un teléfono celular del
          beneficiario, número de cuenta donde pretenda que se realice la
          transferencia del apoyo de la institución bancaria denominada BBVA Bancomer,
          si es que es cuentahabiente del banco en mención, o en su caso, manifestar
          su pretensión de que éste le sea entregado mediante referencia bancaria.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import jubilados from '@/assets/json/bonosData.json';

export default {
  data() {
    return {
      selected: 'nombre',
      options: [
        { text: 'nombre', value: 'nombre' },
        { text: 'CURP', value: 'curp' },
        { text: 'RFC', value: 'rfc' }
      ],
      jubilados: jubilados,
      jubilado: {}
    }
  },
  methods: {
    search() {
      this.jubilado = {};

      switch (this.selected) {
        case 'nombre':
          if (this.textSearch.toUpperCase() != '') {
            this.jubilados.forEach((jubilado) => {
              if (jubilado.nombre.includes(this.textSearch.toUpperCase())) {
                this.jubilado = jubilado;
              }
            });
          }
          return;
        case 'curp':
          if (this.textSearch.toUpperCase() != '') {
            this.jubilados.forEach((jubilado) => {
              if (jubilado.curp == this.textSearch.toUpperCase()) {
                this.jubilado = jubilado;
              }
            });
          }
          return;
        case 'rfc':
          if (this.textSearch.toUpperCase() != '') {
            this.jubilados.forEach((jubilado) => {
              if (jubilado.rfc == this.textSearch.toUpperCase()) {
                this.jubilado = jubilado;
              }
            });
          }
          return;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>