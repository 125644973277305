<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_validador.svg" alt="..." />
          <h1 class="titulo">
            IEA (DES) - Reconocimiento de Validez Oficial de Estudios (RVOE)
          </h1>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>
          El Reconocimiento de Validez Oficial de Estudios (RVOE) es el acto de
          la autoridad educativa, en virtud del cual se determina incorporar al
          Sistema Educativo Nacional los planes y programas de estudio que los
          particulares pretenden impartir.
        </p>
        <p>
          En Aguascalientes, como parte del proceso para obtener tal
          reconocimiento, los planes y programas de estudio propuestos por los
          particulares, son evaluados por la Comisión Estatal para la Planeación
          de la Educación Superior (CEPES). La Comisión cuenta con una Oficina
          Técnica que, entre otras funciones, puede asesorar a los particulares
          con respecto a los planes y programas de estudios que diseñan para
          solicitar el otorgamiento del RVOE.
        </p>
        <p class="fw-bold">
          Por lo anterior, la Oficina Técnica de la CEPES pone a su disposición
          el teléfono 449 91056 00 ext. 4380, para solicitar una cita; o bien,
          puede acudir a Heroico Colegio Militar #318 Col. Ferronales, de lunes
          a viernes en horario de 8:00 a 16:00 hrs.
        </p>
        <p>
          Para más información sobre la normatividad vigente sobre el
          Reconocimiento de Validez Oficial de Estudios (RVOE), puede consultar
          los siguientes documentos:
        </p>
        <ul>
          <!-- <li>
            <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10040.pdf#page=2" target="_blank">
              Convocatoria de Incorporación de Estudios para el Ciclo Escolar
              2024-2025.
            </a>
          </li> -->
          <li>
            <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-superior/Acuerdo-17-11-17.pdf" target="_blank">
              Acuerdo Secretarial 17/11/17 por el que se establecen los
              trámites y procedimientos relacionados con el reconocimiento de
              validez oficial de estudios de tipo superior.
            </a>
          </li>
          <li>
            <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-superior/Acuerdo-20-10-22.pdf" target="_blank">
              Acuerdo Secretarial 20/10/22 por el que se emiten los
              Lineamientos por los que se conceptualizan y definen las opciones
              educativas del tipo superior.
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :infoManager="{
            name: 'Alejandra Serrano Antúnez',
            department: 'Oficina Técnica de la CEPES',
            phone: '449 910 5600 ext. 4380',
            updatedAt: '05/04/2024',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "IncorporacionView",
  components: {
    RIComponent,
  },
};
</script>

<style lang="scss" scoped></style>
