/**
 * Módulo de rutas del módulo EducacionSuperiorModule. Contiene las definiciones
 * de rutas de la Página de la Dirección de Educación Superior
 * (https://www.iea.gob.mx/educacion-media-superior).
 */

// Componente raiz del módulo...
import ModuleComponent from '../ModuleComponent.vue';

// Vista (View) "Educación Superior IEA"...
import PrincipalView from "../views/PrincipalView.vue";

// Vistas (Views) del menú "¿Quiénes somos?"...
import SomosView from "../views/somos/SomosView.vue";

// Vistas (Views) del menú de "Oferta Educativa"...
import BachilleratosEstatalesView from '../views/oferta-educativa/BachilleratosEstatalesView.vue';
import BachilleratosIncorporadosView from '../views/oferta-educativa/BachilleratosIncorporadosView.vue';
import TelebachilleratosComunitariosView from '../views/oferta-educativa/TelebachilleratosComunitariosView.vue';
import FormacionParaElTrabajoView from '../views/oferta-educativa/FormacionParaElTrabajoView.vue';

// Vistas (Views) del menú de "Ingreso"...
import NuevoIngresoView from '../views/NuevoIngresoView.vue';

// Vistas (Views) del menú de "HUB"...
import HUBView from '../views/HUBView.vue';

// Vistas (Views) del menú de "HUB"...
import IndicadoresDeCoberturaYPermanenciaView from '../views/IndicadoresDeCoberturaYPermanenciaView.vue';

// Vistas (Views) del menú de "Enlaces de interés"...
import RevistaDEMSView from '../views/RevistaDEMSView.vue';

const routes = {
  path: '/educacion-media-superior',
  component: ModuleComponent,
  meta: { title: 'IEA | Educación Media Superior' },
  children: [
    { path: '', component: PrincipalView },

    { path: 'somos', component: SomosView },

    { path: 'bachilleratos-estatales', component: BachilleratosEstatalesView },
    { path: 'bachilleratos-incorporados', component: BachilleratosIncorporadosView },
    { path: 'telebachilleratos', component: TelebachilleratosComunitariosView },
    { path: 'certificacion-y-formacion-para-el-trabajo', component: FormacionParaElTrabajoView },

    { path: 'nuevo-ingreso', component: NuevoIngresoView },
    { path: 'hub', component: HUBView },
    { path: 'indicadores-de-cobertura-y-permanencia', component: IndicadoresDeCoberturaYPermanenciaView },
    { path: 'revista', component: RevistaDEMSView },
  ]
}

export default routes;