<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="mini-1">
          <h1>Servicios Digitales</h1>
          <img src="https://www.iea.gob.mx/img/servicios-digitales/i_support.svg" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>
          Es objetivo de la Subdirección de Tecnologías de la Información que el uso
          de las tecnologías se realice de acuerdo con las normas y calidad
          establecidas para cada sistema buscando eficientar labores administrativas
          y optimizar el uso del equipo de cómputo además de buscar fortalecer la
          seguridad de la infraestructura tecnológica del IEA. 
        </p>
        <p>
          En el marco de la Agenda Digital del Plan Estatal de Desarrollo del
          Gobierno del Estado de Aguascalientes ponemos a tu disposición el portal
          de integración de servicios digitales educativos orientados a la población
          estudiantil y docente de nuestro estado.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="titulo">Servicios Principales</h1>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-3 g-3">
      <div class="col">
        <a href="https://forms.gle/368FAJGMMC3bw5938" target="_blank" class="cartaBorde">
            <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_quejasysugerencias.svg">
            <p>Cuentas de Correo Institucionales</p>
        </a>
      </div>
      <div class="col">
        <router-link to="/servicios-digitales/cuentas-educativas" class="cartaBorde">
            <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_quejasysugerencias.svg">
            <p>Cuentas de Correo Educativas</p>
        </router-link>
      </div>
      <div class="col">
        <a href="https://miportal.iea.edu.mx" class="cartaBorde">
            <img src="http://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_web3.svg">
            <p>Mi Portal de Servicios IEA</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiciosDigitalesView"
};
</script>

<style>

</style>
