<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <router-link to="/servicios-digitales" class="btn btn-ln-bg1 d-flex align-items-center" style="width: min-content;">
          <span class="iconify me-3" data-icon="icon-park-outline:return" width="30" height="30"></span>
          Volver
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="titulo">Cuentas de Correo Educativas</h1>
      </div>
    </div>

    <div class="row">
      <div class="col col-md-8">
        <div class="banner-body-left bg_base">
          <img src="https://www.iea.gob.mx/img/servicios-digitales/banner-cuentas-educativas.png" alt="..." class="img-fluid"/>
          <div class="baninfo-wrapper">
            <h1>
              Guía de Cuentas de Usuario Educativas <br />
              <span class="fs-4">@ags.nuevaescuela.mx</span>
            </h1>
            <div class="d-flex flex-column align-items-end">
              <a href="https://www.iea.gob.mx/IEA/servicios-digitales/Guía-Cuentas-Educativas.pdf" target="_blank" class="btn btn-principal-light mb-3" style="position: relative; bottom: 0;">
                Más Información
              </a>
              <a href="https://www.iea.gob.mx/IEA/servicios-digitales/Guía-de-Uso-de-Cuentas-Educativas.pdf" target="_blank" class="btn btn-principal-light mb-3" style="position: relative; bottom: 0;">
                Ver Guía de Uso
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-md-4">
        <h2 class="subtitulo">Creación de Cuentas de Correo Educativas</h2>
        <a href="https://forms.gle/ZRp55YhtAYgJEErx5" target="_blank" class="pillLink">
          <div class="pill-card d-flex align-items-center">
            <img src="http://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_hombre.svg" alt="icono" class="img-fluid" />
            <div>Cuentas de Correo Educativas para Alumnos</div>
          </div>
        </a>
        <a href="https://forms.gle/HP9qLQV1NhZ9wPUs9" target="_blank" class="pillLink">
          <div class="pill-card d-flex align-items-center">
            <img src="http://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_personas.svg" alt="icono" class="img-fluid" />
            <div>Cuentas de Correo Educativas para Docentes</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CuentasCorreoEducativasView"
};
</script>

<style lang="scss" scoped>

</style>
