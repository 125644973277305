<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">
          IEA (DEB) - Programa Estratégico "Los Sueños se Construyen en el Aula"
        </h1>
        <p>
          Los sueños se construyen en el aula es un programa estratégico
          generado a iniciativa de nuestra gobernadora, la Dra. María Teresa
          Jiménez Esquivel, quien a través del Mtro. Luis Enrique Reynoso
          Gutiérrez, Director General del Instituto de Educación de
          Aguascalientes, se desarrolla en nuestro Estado.
        </p>
        <p>
          Esta estrategia es de gran trascendencia para Aguascalientes ya que no
          sólo se trata de una política educativa, sino de una convocatoria para
          todos los hidrocálidos que de manera altruista, comprometida y
          corresponsable quieran ayudar a las niñas, niños, adolescentes y
          jóvenes que, en el marco de la pandemia y por alguna razón, han dejado
          la educación preescolar, primaria o secundaria, o bien han bajado su
          rendimiento académico.
        </p>
        <div class="banner_sm bg_dg-7 mb-3">
          <div class="item1">
            <p class="titulo">Los Sueños se Construyen en el Aula</p>
          </div>
          <div class="item2">
            <p class="contenido">Presentación informativa</p>
          </div>
          <div class="item3">
            <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/PESCA-Presentación-Informativa.pdf" target="_blank" class="btn btn-principal-light">
              Ver presentación
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Objetivo General</h2>
        <p>
          Generar las condiciones necesarias para que las niñas, niños,
          adolescentes y jóvenes (NNAJ) que durante la pandemia abandonaron sus
          estudios o, que no lograron el óptimo desarrollo de sus competencias
          básicas, puedan consolidarlas, todo ello con la corresponsabilidad de
          la sociedad civil y de las instituciones públicas y privadas de
          educación superior.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Objetivos Específicos</h2>
        <ol>
          <li>
            Localizar y reincorporar al sistema educativo estatal a las NNAJ que
            abandonaron la escuela (Estudiantes Desafiliados) a fin de que
            continúen con sus estudios de educación básica.
          </li>
          <li>
            Fortalecer el desarrollo de las competencias básicas para el
            aprendizaje: lectura, escritura y pensamiento matemático de los
            estudiantes focalizados en rezago educativo profundo con el
            propósito de que cuenten con las herramientas necesarias para
            continuar con el desarrollo de su aprendizaje.
          </li>
          <li>
            Fortalecer los procesos de lectura y pensamiento matemático; así
            como aquellos contenidos curriculares necesarios a fin de mejorar el
            nivel educativo de todos los estudiantes.
          </li>
          <li>
            Desarrollar e implementar programas, estrategias y/o acciones que
            permitan generar una educación con equidad, inclusiva, segura,
            saludable, sustentable y que fortalezca el desarrollo socioemocional
            de los NNAJ que estudian la educación básica.
          </li>
        </ol>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Metas</h2>
        <ol>
          <li>
            DESAFILIACIÓN: Localizar a 19,003 estudiantes de educación básica
            que estuvieron inscritos en los ciclos escolares 2019-2020,
            2020-2021 y 2021-2022y que NO están inscritos en el ciclo escolar
            2022-2023.
          </li>
          <li>
            REZAGO EDUCATIVO PROFUNDO: Atender a 50,795 niños focalizados en
            rezago educativo profundo con el propósito de desarrollar sus
            competencias básicas para el aprendizaje.
          </li>
          <li>
            FORTALECIMIENTO ACADÉMICO: Ofrecer al 100% de los estudiantes de
            educación básica diversos programas educativos con la finalidad de
            fortalecer su desarrollo educativo.
          </li>
        </ol>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Plan de Trabajo</h2>
        <div class="banner_sm bg_dg-6 mb-3">
          <div class="item1">
            <p class="titulo">Los Sueños se Construyen en el Aula</p>
          </div>
          <div class="item2">
            <p class="contenido">Plan de trabajo</p>
          </div>
          <div class="item3">
            <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/PESCA-Plan-de-Trabajo.pdf" target="_blank" class="btn btn-principal-light">
              Ver plan de trabajo
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Programas Estratégicos, Ciclo Escolar 2023-2024</h2>
        <div class="banner_sm bg_dg-5 mb-3">
          <div class="item1">
            <p class="titulo">Los Sueños se Construyen en el Aula</p>
          </div>
          <div class="item2">
            <p class="contenido">Programas Estratégicos</p>
          </div>
          <div class="item3">
            <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/PESCA-Programas.pdf" target="_blank" class="btn btn-principal-light">
              Ver presentación
            </a>
          </div>
        </div>
        <h3 class="subtitulo">Programa de Lectura, Escritura y Matemáticas</h3>
        <p>
          El <b>Programa de Lectura, Escritura y Matemáticas</b> trabaja en forma
          colaborativa con los distintos colectivos: Titurales de los SisAAE,
          Supervisores, ATP´s, Directores y docentes, a fin de establecer acciones
          de mejora de las prácticas pedagógicas que repercuten en el desarrollo de
          las habilidades de lectura, escritura y pensamiento matemático en alumnos
          que cursan la educación básica.
        </p>
        <p class="c_base"><b>Objetivos y metas:</b></p>
        <p>
          El objetivo del programa es garantizar una educación inclusiva y
          equitativa de calidad y promover oportunidades de aprendizaje permanente
          para los NNAJ que cursan la educación básica a partir del desarrollo de
          sus habilidades de lectura, escritura y pensamiento matemático.
        </p>
        <ul>
          <li>
            <b>META 1.</b> Mejorar la calidad de la lectura como una herramienta
            eficaz para el aprendizaje continuo de de niñas, niños y jóvenes.
          </li>
          <li>
            <b>META 2.</b> Desarrollar el pensamiento matemático a través de
            situaciones diversas de conteo, comparación, medición y resolución
            de problemas que lleven a los estudiantes a reconocer y emplear las
            habilidades matemáticas como una herramienta de la vida cotidiana.
          </li>
        </ul>
        <p class="c_base"><b>Principales acciones:</b></p>
        <ul>
          <li>
            Implementación del taller sobre orientaciones didácticas para
            consolidar la lectura y escritura, para las fases 3, 4 y 5.
          </li>
          <li>
            Foros y mesas de intercambio académico entre Asesores Técnico
            Pedagógicos de todos los niveles de Educación Básica.
          </li>
          <li>
            Producción y/o difusión e implementación de insumos tales como:
            ficheros, trípticos, infografías, carteles, revistas, separadores,
            presentaciones, biblioteca digital para docentes, etc., para apoyar la
            asesoría y el acompañamiento que realizan supervisores y ATPs a
            directores y docentes del estado de Aguascalientes.
          </li>
          <li>
            Difusión de experiencias pedagógicas en torno al trabajo de formación de
            lectores críticos y productores de diversidad de textos, en
            correspondencia a las metodologías señaladas por la NEM y los ejes que
            marca el Programa Nacional de Lectura.
          </li>
          <li>
            Difusión, promoción y animación a la lectura de textos que forman parte
            de las bibliotecas escolares, de aula, personal, públicas y digitales
            (Tripulantes de la lectura, Pasos Gigantes, Libros de oro del Rincón,
            entre otros).
          </li>
        </ul>
        <p class="c_base"><b>Recursos:</b></p>
        <p>
          Se cuenta con un paquete de insumos que fueron elaborados por los
          grupos de ATP´s. en un trabajo colegiado de los diversos niveles de
          educación básica.
        </p>
        <div class="container-fluid">
          <div class="row justify-content-center g-3">
            <div class="col-12 col-lg-6">
              <a href="https://drive.google.com/drive/folders/1YAX2UB-nEGuEhTls-TPmAOvNxQhCbjbC" target="_blank" class="pillLink">
                <div class="pill-card d-inline-flex align-items-center">
                  <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_libros.svg" alt="..." class="img-fluid" />
                  <div>Recursos del Programa "Los Sueños se Construyen en el Aula"</div>
                </div>
              </a>
            </div>
          </div>
          <div class="row justify-content-center g-3">
            <div class="col-12 col-lg-6">
              <a href="https://drive.google.com/drive/folders/1uAZbbt58SqiFY3K5s8za_IlNbANCN_39" target="_blank" class="pillLink">
                <div class="pill-card d-inline-flex align-items-center">
                  <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_libros.svg" alt="..." class="img-fluid" />
                  <div>Recursos del Programa de Lectura y Escritura</div>
                </div>
              </a>
            </div>
            <div class="col-12 col-lg-6">
              <a href="https://drive.google.com/drive/folders/1_w4ID9KQrSa39yhoKU60G6mmGFzkBLdD?usp=drive_link" target="_blank" class="pillLink">
                <div class="pill-card d-inline-flex align-items-center">
                  <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_libros.svg" alt="..." class="img-fluid" />
                  <div>Recursos del Programa de Matemáticas</div>
                </div>
              </a>
            </div>
          </div>
          <div class="row justify-content-center g-3">
            <div class="col-12 col-lg-6">
              <a href="https://docs.google.com/presentation/d/1BIuQmbHJCK1aRJvGFStM-wh6SsJSDO0HTo9iRAUUSek/edit#slide=id.p" target="_blank" class="pillLink">
                <div class="pill-card d-inline-flex align-items-center">
                  <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_libro.svg" alt="..." class="img-fluid" />
                  <div>Biblioteca para ATP´s y Docentes</div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <p>
          Gracias al compromiso que asumen los supervisores y ATP´s en el
          acompañamiento a los colectivos docentes, se observan cambios
          favorables en las prácticas pedagógicas que favorecen el desarrollo de las
          habilidades de lectura-escritura y desarrollo del pensamiento matemático,
          bajo el enfoque de la NEM.
        </p>
        <div class="banner-body-left bg_3">
          <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-basica/banner-sueños-aula.webp" alt="..." class="img-fluid" />
          <div class="baninfo-wrapper">
            <h1>Los Sueños se Construyen en el Aula</h1>
            <p>Plataforma Web</p>
            <div class="d-flex align-items-end flex-column">
              <a href="https://suenos.iea.edu.mx" target="_blank" class="btn btn-principal-light float-end">
                Ir a la plataforma
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Jorge Abraham Mejía Reyna',
          department: 'Coordinación del Programa Estratégico “Los Sueños se Construyen en el Aula”',
          phone: '449 910 5600 ext. 4313',
          updatedAt: '06/11/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "PESCAView",
  components: {
    RIComponent,
  },
};
</script>

<style lang="scss" scoped>

</style>
