<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DEMS) - Ingreso a la Educación Media Superior 2024 - 2025</h1>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-lg-2 justify-content-center g-3">
      <div class="col">
        <a href="https://siems.iea.edu.mx" target="_blank" class="pillLink">
          <div class="pill-card d-inline-flex align-items-center">
            <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_registro.svg" alt="..." class="img-fluid" />
            <div>Ingresar al Sistema</div>
          </div>
        </a>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-lg-3 g-3">
      <div class="col">
        <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/nuevo-ingreso/CVT-Ingreso-a-la-EMS.pdf" target="_blank" class="pillLink">
          <div class="pill-card d-inline-flex align-items-center">
            <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_libro.svg" alt="..." class="img-fluid" />
            <div>Convocatoria de Ingreso</div>
          </div>
        </a>
      </div>
      <div class="col">
        <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/nuevo-ingreso/CVT-Ingreso-a-la-EMS-Oferta-Educativa.pdf" target="_blank" class="pillLink">
          <div class="pill-card d-inline-flex align-items-center">
            <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_lista.svg" alt="..." class="img-fluid" />
            <div>Oferta Educativa</div>
          </div>
        </a>
      </div>
      <div class="col">
        <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/nuevo-ingreso/CVT-Ingreso-a-la-EMS-Instructivo-de-Ingreso.pdf" target="_blank" class="pillLink">
          <div class="pill-card d-inline-flex align-items-center">
            <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_revista.svg" alt="..." class="img-fluid" />
            <div>Instructivo de Ingreso</div>
          </div>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Dirección de Educación Media Superior',
          department: 'Dirección de Educación Media Superior',
          phone: '449 149 2400 ext. 7800',
          updatedAt: '05/07/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "IngresoView",
  components: {
    RIComponent
  }
}
</script>

<style lang="scss" scoped></style>
