<template>
  <header>
    <HeaderComponent />
  </header>

  <main id="main">
      <div class="container mt-3">
        <div class="row">
          <div class="col">
            <router-view />
          </div>
        </div>
      </div>
  </main>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue';

export default {
  name: "ServiciosDigitalesModuleComponent",
  components: {
    HeaderComponent
  },
};
</script>

<style lang="scss" scoped></style>
