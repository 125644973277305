<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo">IEA (DEB) - Programas educativos</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <router-view></router-view>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Rosario Elizabeth Gutiérrez Carreón',
          department: 'Subdirección de Relación Interinstitucional y Social',
          phone: '449 910 5600 ext. 4074',
          updatedAt: '09/07/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "ProgramasEducativosView",
  components: {
    RIComponent,
  }
}
</script>

<style lang="scss" scoped>

</style>