<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DES) - Programas Transversales</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Índice</h2>
        <ol class="fw-bold c_base">
          <li>
            Programa para el Mejoramiento de la Calidad de la Educación Superior
            de Aguascalientes (PROMECA).
          </li>
          <li>
            Programa Estatal de Fomento a la Creatividad, Innovación y
            Emprendimiento (STEAM-IE).
          </li>
          <li>1er Congreso de Educación Superior.</li>
          <li>
            Programa de Actualización del Personal Docente de las Instituciones
            de Educación Superior.
          </li>
          <li>
            Programa Aguascalientes el Gigante Digital.
          </li>
          <ol>
            <li>Certificaciones.</li>
            <li>Hackaton.</li>
            <li>Aprendizaje Activo.</li>
          </ol>
          <li>
            Programa Estatal de Apoyo a la Movilidad Internacional.
          </li>
          <li>
            Programa Estatal para el Fortalecimiento de la Educación Superior.
          </li>
        </ol>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Contenido</h2>
        <div class="accordion" id="accordionProgramasTransversales">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed subtitulo-sm mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                1er Congreso de Educación Superior
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionProgramasTransversales">
              <div class="accordion-body">
                <div class="container-fluid">
                  <div class="row g-3">
                    <div class="col-12">
                      <h2 class="subtitulo">1er Congreso de Educación Superior</h2>
                      <p>
                        El Primer Congreso de Educación Superior se llevó a cabo los días 9,
                        10 y 11 de noviembre de 2023 en las instalaciones del Museo Descubre y
                        la Universidad Tecnológica de Aguascalientes (UTA). La temática
                        principal del Congreso fue construyendo comunidades académicas, a
                        través del análisis de las funciones sustantivas de las instituciones
                        de Educación Superior: Docencia, Investigación y Vinculación.
                      </p>
                      <p>
                        En la jornada inaugural se contó con la presencia de 40 Instituciones
                        de Educación Superior del Estado, las cuales junto con las autoridades
                        del Instituto de Educación de Aguascalientes firmaron un pacto estatal
                        entre Instituciones de Educación Superior, cuyos principales objetivos
                        son: Compromiso para trabajar de manera colaborativa, implementación
                        de buenas prácticas académicas y de investigación, compartir
                        infraestructura física educativa, y la creación de un ecosistema de
                        Educación Superior.
                      </p>
                    </div>
                    <div class="col-12 text-center">
                      <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-congreso-01.webp" alt="..." class="img-fluid rounded shadow" />
                    </div>
                    <div class="col-12">
                      <p>
                        Durante el segundo día se llevo a cabo un panel de cuerpos académicos
                        en donde los participantes de diferentes Instituciones de Educación
                        Superior intercambiaron experiencias exitosas de aprendizaje con el
                        público asistente, y se contó también con la impartición de 2
                        conferencias cuya temática fue Construyendo Comunidades Académicas por
                        parte de la Dra. Luz María Stella Moreno proveniente de la Universidad
                        Iberoamericana Ciudad de México y el Mtro. Joaquín Amaro Cisneros de
                        la empresa de tecnología SIEMENS.
                      </p>
                    </div>
                    <div class="col-12 col-lg-6 text-center">
                      <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-congreso-02.webp" alt="..." class="img-fluid rounded shadow" />
                    </div>
                    <div class="col-12 col-lg-6 text-center">
                      <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-congreso-03.webp" alt="..." class="img-fluid rounded shadow" />
                    </div>
                    <div class="col-12">
                      <p>
                        El tercer día del Congreso se realizaron 24 talleres con diversas
                        temáticas en la Universidad Tecnológica de Aguascalientes, en donde se
                        contó con la participación entusiasta de 400 Docentes de 40
                        Instituciones de Educación Superior de nuestro estado.
                      </p>
                    </div>
                    <div class="col-12 col-lg-6 text-center">
                      <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-congreso-04.webp" alt="..." class="img-fluid rounded shadow" />
                    </div>
                    <div class="col-12 col-lg-6 text-center">
                      <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-congreso-05.webp" alt="..." class="img-fluid rounded shadow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed subtitulo-sm mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Programa de Actualización del Personal Docente de las Instituciones de
                Educación Superior
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionProgramasTransversales">
              <div class="accordion-body">
                <div class="container-fluid">
                  <div class="row g-3">
                    <div class="col-12">
                      <h2 class="subtitulo">
                        Programa de Actualización del Personal Docente de las Instituciones de
                        Educación Superior
                      </h2>
                      <p>
                        Durante el mes de septiembre, la Universidad Politécnica de
                        Aguascalientes, se comunicó con la Subdirección de Fortalecimiento de
                        la Educación Superior y el departamento de Desarrollo Académico para
                        gestionar y diseñar un taller, donde se atiendan algunas necesidades
                        sociales y emocionales que se estaban presentando dentro de la
                        universidad (UPA) con los alumnos. En el mes de septiembre se
                        contactaron varios expertos con diferentes universidades del estado
                        para contactar a diferentes maestros. La Dra. Jessica Guadalupe Lozano
                        Montoya, docente de el Centro Regional de Educación Normal de
                        Aguascalientes (CRENA), para la elaboración del Taller “Educación
                        socioemocional desde el enfoque por competencias”, un taller impartido
                        en el mes de octubre, dentro de las instalaciones de la UPA, se
                        realizaron dos sesiones, cada sesión con una duración de dos horas
                        cada uno, en una modalidad presencial la participación fue de 22
                        docentes.
                      </p>
                      <p>
                        El objetivo del taller es que el docente reconozca la importancia,
                        conceptos y principios de las emociones, así como el desarrollo de
                        habilidades socioemocionales asociadas al modelo de 5 competencias
                        emocionales de Rafael Bisquerra.
                      </p>
                      <p>
                        La participación de los maestros fue muy proactiva, de conocimientos
                        nuevos y estrategias a identificar en los alumnos para canalizarlos a
                        diferentes Centros de Atención, dicho taller tiene continuidad con los
                        mismos maestros que se desarrollara en el primer semestre del año
                        2024.
                      </p>
                    </div>
                    <div class="col-12 col-lg-4 text-center">
                      <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-programa-docentes-01.webp" alt="..." class="img-fluid rounded shadow" />
                    </div>
                    <div class="col-12 col-lg-4 text-center">
                      <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-programa-docentes-02.webp" alt="..." class="img-fluid rounded shadow" />
                    </div>
                    <div class="col-12 col-lg-4 text-center">
                      <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-programa-docentes-03.webp" alt="..." class="img-fluid rounded shadow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed subtitulo-sm mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Programa Aguascalientes el Gigante Digital
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionProgramasTransversales">
              <div class="accordion-body">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col">
                      <h2 class="subtitulo">Programa Aguascalientes el Gigante Digital</h2>
                      <h2 class="subtitulo">Certificaciones</h2>
                    </div>
                  </div>
                  <div class="row row-cols-1 row-cols-lg-2 g-3">
                    <div class="col" v-for="slide of slides">
                      <img :src="slide.img" alt="..." class="img-fluid rounded shadow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row g-3">
      <div class="col-12">
        <h2 class="subtitulo">1er Congreso de Educación Superior</h2>
        <p>
          El Primer Congreso de Educación Superior se llevó a cabo los días 9,
          10 y 11 de noviembre de 2023 en las instalaciones del Museo Descubre y
          la Universidad Tecnológica de Aguascalientes (UTA). La temática
          principal del Congreso fue construyendo comunidades académicas, a
          través del análisis de las funciones sustantivas de las instituciones
          de Educación Superior: Docencia, Investigación y Vinculación.
        </p>
        <p>
          En la jornada inaugural se contó con la presencia de 40 Instituciones
          de Educación Superior del Estado, las cuales junto con las autoridades
          del Instituto de Educación de Aguascalientes firmaron un pacto estatal
          entre Instituciones de Educación Superior, cuyos principales objetivos
          son: Compromiso para trabajar de manera colaborativa, implementación
          de buenas prácticas académicas y de investigación, compartir
          infraestructura física educativa, y la creación de un ecosistema de
          Educación Superior.
        </p>
      </div>
      <div class="col-12 text-center">
        <img
          src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-congreso-01.webp"
          alt="..." class="img-fluid rounded shadow" />
      </div>
      <div class="col-12">
        <p>
          Durante el segundo día se llevo a cabo un panel de cuerpos académicos
          en donde los participantes de diferentes Instituciones de Educación
          Superior intercambiaron experiencias exitosas de aprendizaje con el
          público asistente, y se contó también con la impartición de 2
          conferencias cuya temática fue Construyendo Comunidades Académicas por
          parte de la Dra. Luz María Stella Moreno proveniente de la Universidad
          Iberoamericana Ciudad de México y el Mtro. Joaquín Amaro Cisneros de
          la empresa de tecnología SIEMENS.
        </p>
      </div>
      <div class="col-12 col-lg-6 text-center">
        <img
          src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-congreso-02.webp"
          alt="..." class="img-fluid rounded shadow" />
      </div>
      <div class="col-12 col-lg-6 text-center">
        <img
          src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-congreso-03.webp"
          alt="..." class="img-fluid rounded shadow" />
      </div>
      <div class="col-12">
        <p>
          El tercer día del Congreso se realizaron 24 talleres con diversas
          temáticas en la Universidad Tecnológica de Aguascalientes, en donde se
          contó con la participación entusiasta de 400 Docentes de 40
          Instituciones de Educación Superior de nuestro estado.
        </p>
      </div>
      <div class="col-12 col-lg-6 text-center">
        <img
          src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-congreso-04.webp"
          alt="..." class="img-fluid rounded shadow" />
      </div>
      <div class="col-12 col-lg-6 text-center">
        <img
          src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-congreso-05.webp"
          alt="..." class="img-fluid rounded shadow" />
      </div>
    </div> -->

    <!-- <div class="row g-3">
      <div class="col-12">
        <h2 class="subtitulo">
          Programa de Actualización del Personal Docente de las Instituciones de
          Educación Superior
        </h2>
        <p>
          Durante el mes de septiembre, la Universidad Politécnica de
          Aguascalientes, se comunicó con la Subdirección de Fortalecimiento de
          la Educación Superior y el departamento de Desarrollo Académico para
          gestionar y diseñar un taller, donde se atiendan algunas necesidades
          sociales y emocionales que se estaban presentando dentro de la
          universidad (UPA) con los alumnos. En el mes de septiembre se
          contactaron varios expertos con diferentes universidades del estado
          para contactar a diferentes maestros. La Dra. Jessica Guadalupe Lozano
          Montoya, docente de el Centro Regional de Educación Normal de
          Aguascalientes (CRENA), para la elaboración del Taller “Educación
          socioemocional desde el enfoque por competencias”, un taller impartido
          en el mes de octubre, dentro de las instalaciones de la UPA, se
          realizaron dos sesiones, cada sesión con una duración de dos horas
          cada uno, en una modalidad presencial la participación fue de 22
          docentes.
        </p>
        <p>
          El objetivo del taller es que el docente reconozca la importancia,
          conceptos y principios de las emociones, así como el desarrollo de
          habilidades socioemocionales asociadas al modelo de 5 competencias
          emocionales de Rafael Bisquerra.
        </p>
        <p>
          La participación de los maestros fue muy proactiva, de conocimientos
          nuevos y estrategias a identificar en los alumnos para canalizarlos a
          diferentes Centros de Atención, dicho taller tiene continuidad con los
          mismos maestros que se desarrollara en el primer semestre del año
          2024.
        </p>
      </div>
      <div class="col-12 col-lg-4 text-center">
        <img
          src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-programa-docentes-01.webp"
          alt="..." class="img-fluid rounded shadow" />
      </div>
      <div class="col-12 col-lg-4 text-center">
        <img
          src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-programa-docentes-02.webp"
          alt="..." class="img-fluid rounded shadow" />
      </div>
      <div class="col-12 col-lg-4 text-center">
        <img
          src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-programa-docentes-03.webp"
          alt="..." class="img-fluid rounded shadow" />
      </div>
    </div> -->

    <!-- <div class="row">
      <div class="col">
        <h2 class="subtitulo">Programa Aguascalientes el Gigante Digital</h2>
        <h2 class="subtitulo">Certificaciones</h2>
        <div class="sliderGea swiper-container rounded">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="slide of slides">
              <img :src="slide.img" alt="..." class="img-fluid" />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Stefany Karina Jiménez Colombo',
          department: 'Departamento de Divulgación y Educación Científica y Tecnológica',
          phone: '449 149 2400 ext. 7805',
          updatedAt: '10/04/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "ProgramasTransversalesView",
  components: {
    RIComponent,
  },
  data() {
    return {
      slides: [
        {
          img: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-certificacion-01.webp",
        },
        {
          img: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-certificacion-02.webp",
        },
        {
          img: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/programas-transversales/img-certificacion-04.webp",
        },
      ],
    };
  },
  created() {
    gea.iwin(() => {
      setTimeout(() => {
        const swiper = new Swiper(".swiper-container", {
          observer: true,
          loop: true,
          speed: 1000,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          // navigation: {
          //   nextEl: ".swiper-button-next",
          //   prevEl: ".swiper-button-prev"
          // },
          // scrollbar: {
          //   el: '.swiper-scrollbar'
          // }
        });
      }, 1000);
    });
  },
};
</script>

<style lang="scss" scoped></style>
