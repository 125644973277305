<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Incorporación</h1>
        <p>
          La incorporación de estudios al Sistema Educativo Nacional está
          contemplado en dos figuras importantes que son la Autorización y el
          Reconocimiento de Validez Oficial de Estudios.
        </p>
        <p>
          La <strong>Autorización</strong> es la resolución que emite la
          autoridad educativa respecto de Planes y Programas de Estudio de
          Educación Básica (Inicial, Preescolar, Primaria y Secundaria) y
          Formación de Docentes (Normal).
        </p>
        <p>
          El <strong>Reconocimiento de Validez Oficial de Estudios (RVOE)</strong>
          es la resolución respecto de todos aquellos Planes y Programas de
          Estudio que no se encuentran comprendidos en la Educación Básica
          (Inicial, Preescolar, Primaria, Secundaria) y Formación de Docentes
          (Normal), es decir, son los comprendidos por Formación para el
          Trabajo, la Educación Media Superior y la Superior.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="banner_sm bg_dg-6 mb-3">
          <div class="item1">
            <p class="titulo">Convocatoria de Incorporación de Estudios</p>
          </div>
          <div class="item2">
            <p class="contenido">Ciclo Escolar 2025-2026</p>
          </div>
          <div class="item3">
            <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/CVT-Incorporación-de-Estudios.pdf" target="_blank" class="btn btn-principal-light">
              Ver convocatoria
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Trámites de Incorporación</h2>
      </div>
      <div class="col-12">
        <p>
          Los trámites de incorporación para todos los tipos y niveles
          educativos: Inicial, Preescolar, Primaria, Secundaria, Educación Media
          Superior, Superior, Formación para el Trabajo y Profesional Técnico,
          están sujetos a la Convocatoria de Incorporación para el ciclo escolar
          2022-2023.
        </p>
      </div>
      <div class="col-12">
        <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/Procedimiento de Incorporación - Autorización o RVOE 2022-2023.pdf" target="_blank" class="pillLink">
          <div class="pill-card mb-3">
            <div class="d-inline-flex align-items-center">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_encuesta.svg" alt="..." class="img-fluid" />
              <div>
                Procedimiento de Incorporación. Autorización o Reconocimiento de
                Validez Oficial de Estudios (RVOE) 2022-2023
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Autorizaciones y RVOE's</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Autorización para impartir Educación Inicial 2022-2023</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/Autorización para impartir Educación Inicial 2022-2023.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Autorización para impartir Educación Preescolar 2022-2023</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/Autorización para impartir Educación Preescolar 2022-2023.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Autorización para impartir Educación Primaria 2022-2023</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/Autorización para impartir Educación Primaria 2022-2023.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Autorización para impartir Educación Secundaria 2022-2023</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/Autorización para impartir Educación Secundaria 2022-2023.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>Reconocimiento de Validez Oficial de Estudios (RVOE) Educación Media Superior 2022-2023</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/RVOE Educación Media Superior 2022-2023.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>Reconocimiento de Validez Oficial de Estudios (RVOE) Educación Superior 2022-2023</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/RVOE Educación Superior 2022-2023.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>Reconocimiento de Validez Oficial de Estudios (RVOE) Formación para el Trabajo 2022-2023</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/RVOE Formación para el Trabajo 2022-2023.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Acuerdos de Incorporación Vigentes</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Acuerdos de Incorporación Vigentes de Educación Inicial</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos-de-incorporacion/Educación Inicial.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Acuerdos de Incorporación Vigentes de Educación Preescolar</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos-de-incorporacion/Educación Preescolar.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Acuerdos de Incorporación Vigentes de Educación Primaria</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos-de-incorporacion/Educación Primaria.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Acuerdos de Incorporación Vigentes de Educación Secundaria</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos-de-incorporacion/Educación Secundaria.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>Acuerdos de Incorporación Vigentes de Educación Media Superior</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos-de-incorporacion/Educación Media Superior.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>Acuerdos de Incorporación Vigentes de Educación Superior</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos-de-incorporacion/Educación Superior.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Fortino Enrique Marín Contreras',
          department: 'Departamento de Incorporación y Revalidación',
          phone: '449 910 5600 ext. 4230',
          updatedAt: '04/11/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "IncorporacionView",
  components: {
    RIComponent
  }
}
</script>

<style lang="scss" scoped>

</style>