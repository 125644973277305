<template>
  <!-- Header de la página -->
  <header>
    <HeaderSecundarioComponent title="Dirección de Educación Superior" />
  </header>

  <!-- Navbar de la página -->
  <nav>
    <NavbarDESComponent />
  </nav>

  <!-- Contenido principal de la página -->
  <main id="main">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <!-- Contenido principal de la página -->
          <section>
            <router-view v-slot="{ Component }">
              <transition>
                <keep-alive>
                  <component :is="Component" />
                </keep-alive>
              </transition>
            </router-view>
          </section>
        </div>
        <div class="col-md-4">
          <!-- Sidebar de la página -->
          <aside>
            <SidebarDESComponent />
          </aside>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import HeaderSecundarioComponent from "@/components/HeaderSecundarioComponent.vue";
import NavbarDESComponent from "@/pages/educacion-superior/components/NavbarDESComponent.vue"
import SidebarDESComponent from "@/pages/educacion-superior/components/SidebarDESComponent.vue"

export default {
  name: "EducacionSuperiorModuleComponent",
  components: {
    HeaderSecundarioComponent,
    NavbarDESComponent,
    SidebarDESComponent,
  }
};
</script>

<style>

</style>
