<template>
  <div class="container">
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
      <div class="col">
        <router-link to="/educacion-basica/programas-educativos/interinstitucionales">
          <div class="card shadow" style="max-width: 18rem; min-height: 16rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/Interinstitucionales.webp" class="card-img-top p-3" alt="...">
            <p class="fw-bold c_base text-center">Programas Interinstitucionales</p>
          </div>
        </router-link>
      </div>
      <div class="col">
        <router-link to="/educacion-basica/programas-educativos/PROAGE">
          <div class="card shadow" style="max-width: 18rem; min-height: 16rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PROAGE.webp" class="card-img-top p-3" alt="...">
              <p class="fw-bold c_base text-center">Programa de Apoyo a la Gestión Escolar</p>
          </div>
        </router-link>
      </div>
      <div class="col">
        <router-link to="/educacion-basica/programas-educativos/PEEI">
          <div class="card shadow" style="max-width: 18rem; min-height: 16rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PEEI.webp" class="card-img-top p-3" alt="...">
              <p class="fw-bold c_base text-center">Programa Expansión de la Educación Inicial</p>
          </div>
        </router-link>
      </div>
      <div class="col">
        <router-link to="/educacion-basica/programas-educativos/soy-anfitrion-kids">
          <div class="card shadow" style="max-width: 18rem; min-height: 16rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/soy-anfitrion-kids.webp" class="card-img-top p-3" alt="...">
            <p class="fw-bold c_base text-center">Programa Soy Anfitrión Kids</p>
          </div>
        </router-link>
      </div>
      <div class="col">
        <router-link to="/educacion-basica/programas-educativos/PRONI/preescolar-y-primaria">
          <div class="card shadow" style="max-width: 18rem; min-height: 16rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI.webp" class="card-img-top p-3" alt="...">
            <p class="fw-bold c_base text-center">Programa Nacional de Inglés</p>
          </div>
        </router-link>
      </div>
      <div class="col">
        <router-link to="/educacion-basica/programas-educativos/PFSEE">
          <div class="card shadow" style="max-width: 18rem; min-height: 16rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PFSEE.webp" class="card-img-top p-3" alt="...">
            <p class="fw-bold c_base text-center">Programa de Fortalecimiento de los Servicios de Educación Especial</p>
          </div>
        </router-link>
      </div>
      <div class="col">
        <router-link to="/educacion-basica/programas-educativos/descubriendo-al-mundo-con-otros-ojos">
          <div class="card shadow" style="max-width: 18rem; min-height: 16rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/descubriendo-al-mundo-con-otros-ojos.webp" class="card-img-top p-3" alt="...">
            <p class="fw-bold c_base text-center">Programa Descubriendo al Mundo con Otros Ojos</p>
          </div>
        </router-link>
      </div>
      <div class="col">
        <router-link to="/educacion-basica/programas-educativos/ENTE">
          <div class="card shadow" style="max-width: 18rem; min-height: 16rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/ENTE.webp" class="card-img-top p-3" alt="...">
            <p class="fw-bold c_base text-center">Estrategia Nacional para Promover Trayectorias Educativas Continuas, Completas y de Excelencia</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgramasEducativosMenuView"
}
</script>

<style lang="scss" scoped>

</style>