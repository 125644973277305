<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DEMS) - Indicadores de Cobertura y Permanencia</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Daniel Ponce Maldonado',
          department: 'Dirección de Educación Media Superior',
          phone: '449 149 2400 ext. 7800',
          updatedAt: '07/11/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "IndicadoresDeCoberturaYPermanenciaView",
  components: {
    RIComponent
  }
}
</script>

<style lang="scss" scoped>

</style>
