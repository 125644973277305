<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="mini-1">
          <h1>Portal de Herramientas Digitales para la Educación</h1>
          <img src="https://www.iea.gob.mx/img/servicios-digitales/i_support.svg" />
        </div>
        <p>
          ¡Bienvenido al portal web de <b>Herramientas Digitales para la Educación</b>
          en Aguascalientes! Este espacio está dedicado a la innovación educativa,
          donde las últimas tecnologías se unen para transformar la forma en que
          enseñamos y aprendemos. En este portal, encontrarás un conjunto diverso de
          herramientas diseñadas para potenciar la experiencia educativa, desde la
          educación básica hasta la educación superior. Su objetivo es proporcionar
          a educadores y estudiantes acceso a recursos digitales de vanguardia que
          promuevan la creatividad, el pensamiento crítico y la colaboración.
          ¡Explora nuestro portal y descubre cómo la tecnología puede transformar la
          educación en Aguascalientes!
        </p>
        <p>
          El Instituto de Educación de Aguascalientes, en el marco de la Agenda
          Digital del Plan Estatal de Desarrollo del Gobierno del Estado de
          Aguascalientes 2022 - 2027, pone a tu disposición el portal de integración
          de <b>Herramientas Digitales para la Educación </b>, orientado a la
          población estudiantil y docente de nuestro Estado.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="titulo">Catálogo de Herramientas Digitales para la Educación</h1>
        <p>
          El Instituto de Educación de Aguascalientes ha puesto a disposición un
          completo <b>Catálogo de Herramientas Digitales para la Educación</b>,
          diseñado para apoyar tanto a estudiantes como a docentes en la
          transformación de los procesos de enseñanza y aprendizaje. Este catálogo
          ofrece una amplia gama de recursos digitales que facilitan la
          personalización del aprendizaje, permitiendo a los educadores incorporar
          tecnologías innovadoras en el aula. Gracias a esta iniciativa, la
          comunidad educativa está mejor equipada para enfrentar los retos de la
          educación moderna, promoviendo unaenseñanza más dinámica e interactiva.
        </p>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
      <div class="col">
        <router-link to="/herramientas-digitales/google-workspace" class="cartaBorde">
          <img src="http://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_disenov2.svg" />
          <p>Google Workspace para la Educación</p>
        </router-link>
      </div>
      <div class="col">
        <router-link to="/herramientas-digitales/canva" class="cartaBorde">
          <img src="http://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_diseno.svg" />
          <p>Canva para la Educación</p>
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="titulo">Estrategias Google para la Educación</h1>
        <p>
          El Instituto de Educación de Aguascalientes ha centrado sus esfuerzos en
          impulsar las <b>Estrategias Google para la Educación</b> en el estado de
          Aguascalientes. Gracias a estas estrategias, los estudiantes pueden explorar
          nuevos enfoques de aprendizaje, aprovechando recursos digitales que enriquecen
          su experiencia educativa. Los educadores, por su parte, están capacitándose
          para integrar estas herramientas de manera efectiva en el aula, fomentando
          un entorno de enseñanza innovador y adaptativo.
        </p>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
      <div class="col">
        <router-link to="/herramientas-digitales/chromebooks" class="cartaBorde">
          <img src="http://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_web3.svg" />
          <p>Estrategia Google Chromebooks</p>
        </router-link>
      </div>
      <div class="col">
        <router-link to="/herramientas-digitales/vive-seguro-en-internet" class="cartaBorde">
          <img src="http://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_proteccion.svg" />
          <p>Estrategia Vive Seguro en Internet</p>
        </router-link>
      </div>
      <div class="col">
        <a href="https://edu.google.com/intl/ALL_mx/for-educators/training-courses/?modal_active=none" target="_blank" rel="noopener noreferrer" class="cartaBorde">
          <img src="http://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_diseno.svg" />
          <p>Cursos de Capacitación para Educadores Google</p>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="titulo">Plataformas Digitales</h1>
        <p>
          El Instituto de Educación de Aguascalientes ha apostado por la
          implementación y promoción de diversas <b>Plataformas Digitales</b>, con
          el objetivo de optimizar la gestión y el acceso a recursos educativos en
          el estado. Estas plataformas permiten a los estudiantes y docentes
          conectarse con contenidos relevantes y herramientas colaborativas que
          enriquecen el proceso de enseñanza-aprendizaje. Con estas soluciones, se
          facilita la integración de las tecnologías en el ámbito escolar,
          promoviendo un entorno educativo más accesible, flexible e inclusivo para
          todos.
        </p>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
      <div class="col">
        <a href="https://pasosgigantes.mx" target="_blank" rel="noopener noreferrer" class="cartaBorde">
          <img src="http://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_webv2.svg" />
          <p>Portal de Pasos Gigantes</p>
        </a>
      </div>
      <div class="col">
        <a href="https://pruebat.org" target="_blank" rel="noopener noreferrer" class="cartaBorde">
          <img src="http://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_webv2.svg" />
          <p>Plataforma Pruéba-T</p>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'José Roberto Pereda Delgado',
          department: 'Subdirección de Tecnologías de la Información',
          phone: '449 910 5600 ext. 4915',
          updatedAt: '20/09/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "InicioView",
  components: {
    RIComponent
  }
};
</script>

<style lang="scss" scoped>

</style>
