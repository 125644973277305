/**
 * Módulo de rutas del módulo ServiciosDigitales. Contiene las definiciones
 * de rutas de la Página de la Servicios Digitales (https://www.iea.gob.mx/servicios-digitales).
 */

// Componente principal del módulo...
import ModuleComponent from "../ModuleComponent.vue";

import ServiciosDigitalesView from '../views/ServiciosDigitalesView.vue';
import InstitucionesEducativasView from '../views/InstitucionesEducativasView.vue';
import CuentasCorreoEducativasView from '../views/CuentasCorreoEducativasView.vue';

const moduleRoute = {
  path: '/servicios-digitales',
  component: ModuleComponent,
  meta: { title: 'IEA | Servicios Digitales' },
  children: [
    { path: '', component: ServiciosDigitalesView },
    { path: 'instituciones', component: InstitucionesEducativasView },
    { path: 'cuentas-educativas', component: CuentasCorreoEducativasView },
  ]
};

export default moduleRoute;