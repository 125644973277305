<template>
  <header class="w-100 bg_dg-3 bg-img" style="background-image: url('https://www.iea.gob.mx/img/expouniversidades/bg.png');  background-size: cover; background-repeat: no-repeat; position: relative;">
    <div class="container">
      <div class="row ">
        <div class="col-lg-12 align-middle headerContent" style="padding-bottom: 18%;">
          <p class="supLong mb-0" style="line-height: 1;">Expo Universidades</p>
          <div class="d-flex">
            <p class="inf">2023</p>
            <img src="https://www.iea.gob.mx/img/expouniversidades/i_support.svg" class="digitalImg d-none d-lg-block"/>
          </div>
          <p class="frase">22 y 23 de marzo de 2023</p>
        </div>
      </div>
    </div>

    <img class="divider" src="https://www.iea.gob.mx/img/expouniversidades/divider.svg" alt="">
  </header>
</template>

<script>
export default {
  name: "HeaderComponent"
}
</script>

<style>

</style>