<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DEMS) - Misión, Visión y Valores</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>
          La Educación Media Superior se ubica en el nivel intermedio del
          Sistema Educativo Nacional. Su esencia consiste en brindar a los
          estudiantes oportunidades de aprendizaje con calidad. Independiente
          del nivel económico, social o lugar de residencia.
        </p>
        <p>
          La Dirección de Educación Media Superior del Instituto de Educación de
          Aguascalientes en coordinación con la Secretaría de Educación Pública
          y la sociedad en general, son responsables de que este nivel educativo
          funcione bien, con calidad y cumpla un cometido propio.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Misión</h2>
        <p>
          Proporcionar servicios educativos con experiencia, equidad, inclusión
          y calidez para coadyuvar en la formación integral de las y los
          estudiantes, adquiriendo las competencias necesarias para lograr una
          vida plena que contribuya al desarrollo de una sociedad plural,
          incluyente y humanista.
        </p>
        <h2 class="subtitulo">Visión</h2>
        <p>
          Lograr hacer del Sistema Educativo líder y ejemplo para la
          construcción de una sociedad evolutiva, humanista, vinculante y pilar
          fundamental en el desarrollo de los individuos, a través de la
          equidad, inclusión y compromiso.
        </p>
        <h2 class="subtitulo">Valores</h2>
        <ul>
          <li>Respeto.</li>
          <li>Interés público.</li>
          <li>Integridad.</li>
          <li>Cooperación.</li>
          <li>Transparencia.</li>
          <li>Rendición de cuentas.</li>
          <li>Igualdad y no discriminación.</li>
          <li>Equidad de género.</li>
          <li>Entorno cultural y ecológico.</li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
            name: '-',
            department: 'Dirección de Educación Media Superior',
            phone: '449 149 2400 ext. 7800',
            updatedAt: '08/01/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "SomosView",
  components: {
    RIComponent,
  },
};
</script>

<style lang="scss" scoped></style>
