<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="mini-1">
          <h1>Estrategia Google Chromebooks</h1>
          <img src="https://www.iea.gob.mx/img/servicios-digitales/i_support.svg" />
          <h2>Estrategia de Equipamiento Tecnológico</h2>
        </div>
        <p>
          En el marco de la Agenda Digital del Plan Estatal de Desarrollo del
          Gobierno del Estado de Aguascalientes 2022 - 2027, en materia de
          Educación, la <b>Estrategia Google Chromebooks</b> representa uno de los
          proyectos centrales en este proceso de transformación educativa. Esta
          estrategia consiste en dotar de equipamiento tecnológico a las
          instituciones educativas, permitiendo, a educadores y estudiantes,
          aprovechar efectivamente el acceso a la información y contenidos
          educativos a través de internet.Estos dispositivos no solo brindan acceso
          a una amplia gama de recursos educativos en línea, sino que también
          fomentan la colaboración, la creatividad y el pensamiento crítico en el
          aula.
        </p>
      </div>
    </div>

    <div class="row g-3">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_video.svg" alt="..." />
          <h3 class="titulo">Videos Tutoriales Sobre el Uso de Chromebooks</h3>
        </div>
      </div>
      <div class="col-12">
        <p>
          Sabemos que los primeros pasos para utilizar un dispositivo nuevo
          siempre es complicado, para apoyarte en el uso de Chromebook ponemos a
          tu alcance esta serie de videos que te serán de ayuda para comenzar
          con el uso de tu equipo.
        </p>
      </div>
      <div class="col-12 col-md-4">
        <h2 class="subtitulo">
          Características de las Chromebooks para Educación
        </h2>
        <div class="ratio ratio-16x9">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/lNa9kpUX3rE?si=6zN1QFtK_CoL0N7f"
            class="rounded shadow" title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <h2 class="subtitulo">
          Dispositivos que se Adaptan a tu Forma de Enseñar
        </h2>
        <div class="ratio ratio-16x9">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/UJ9DUv8_uJ8?si=foCdWcG01m4jhVGX"
            class="rounded shadow" title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <h2 class="subtitulo">
          Conectar una Pantalla a la Chromebook
        </h2>
        <div class="ratio ratio-16x9">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/oP_NhdzrMLs?si=dAmThF-Ot6aShm7-"
            class="rounded shadow" title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>

    <div class="row g-3">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_webv2.svg" alt="..." />
          <h3 class="titulo">Curso Chromebooks para Educadores</h3>
        </div>
      </div>
      <div class="col-12">
        <p>
          ¿Te esta agradando trabajar con las Chomebooks? ¡Genial! ahora puedes
          pasar a un siguiente nivel de habilidad accediendo al curso gratuito
          de "Chromebooks para Educadores" de Google.
        </p>
      </div>
      <div class="col-12">
        <div class="banner-body-left bg_base">
          <img src="https://www.iea.gob.mx/img/servicios-digitales/img-banner.png" alt="..." class="img-fluid"/>
          <div class="baninfo-wrapper">
            <h1>Curso Chromebooks para Educadores</h1>
            <p>
              ¿Te esta agradando trabajar con las Chomebooks? ¡Genial! ahora puedes
              pasar a un siguiente nivel de habilidad accediendo al curso gratuito
              de "Chromebooks para Educadores" de Google.
            </p>
            <div class="d-flex align-items-end flex-column">
              <a href="https://www.google.com/url?q=https%3A%2F%2Fskillshop.exceedlms.com%2F%2Fstudent%2Fpath%2F567956-chromebooks-para-educadores&sa=D&sntz=1&usg=AOvVaw1mUwEaVK4v7CL0ITmudeWA" target="_blank" class="btn btn-principal-light float-end">
                Ir al curso
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-3">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_diseno.svg" alt="..." />
          <h3 class="titulo">Materiales de Apoyo</h3>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <a href="https://partnerdash.google.com/apps/simulator/chromebook#use-your-touchpad?l=en" target="_blank" class="cartaBorde">
            <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_web3.svg" alt="icono" class="img-fluid" />
            <p>Emulador Chromebook</p>
        </a>
      </div>
      <div class="col-12 col-md-4">
        <a href="https://support.google.com/chromebook/answer/177893?hl=es-419&ref_topic=9016892" target="_blank" class="cartaBorde">
            <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_familiaDos.svg" alt="icono" class="img-fluid" />
            <p>Accesibilidad</p>
        </a>
      </div>
      <div class="col-12 col-md-4">
        <a href="https://support.google.com/chromebook/answer/3265094?hl=es-419" target="_blank" class="cartaBorde">
            <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_preguntas.svg" alt="icono" class="img-fluid" />
            <p>Centro de Ayuda</p>
        </a>
      </div>
      <div class="col-12 col-md-6">
        <a href="https://youtube.com/playlist?list=PL5QPh7WaZCSesOqG_bQpJ4ZMfa2DaJJml" target="_blank" class="cartaBorde">
            <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_video.svg" alt="icono" class="img-fluid" />
            <p>Videos de Apoyo</p>
        </a>
      </div>
      <div class="col-12 col-md-6">
        <a href="https://sites.google.com/potencia.mx/herramientasdigitales/%C3%ADndice?authuser=0" target="_blank" class="cartaBorde">
            <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_puericultura.svg" alt="icono" class="img-fluid" />
            <p>Plataformas Educativas para Inspirar Ideas</p>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'José Roberto Pereda Delgado',
          department: 'Subdirección de Tecnologías de la Información',
          phone: '449 910 5600 ext. 4915',
          updatedAt: '12/04/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "EquipamientoTecnologicoView",
  components: {
    RIComponent
  }
};
</script>

<style lang="scss" scoped>

</style>
