<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div v-if="loading" class="loading" style="aspect-ratio: 5/4;"></div>
        
        <div v-else class="sliderGea swiper-container rounded">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="slide of slides">
              <a :href="slide.liga" target="_blank" v-if="externalLink(slide.liga)">
                <img :src="slide.archivo" alt="..." class="img-fluid" style="aspect-ratio: 5/4;" />
              </a>
              <router-link :to="slide.liga" v-else>
                <img :src="slide.archivo" alt="..." class="img-fluid" style="aspect-ratio: 5/4;" />
              </router-link>
            </div>
          </div>
          <div class="swiper-pagination"></div>
          
          <!-- <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div> -->

          <!-- <div class="swiper-scrollbar"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SliderPrincipalComponent",
  data() {
    return {
      slides: [],
      loading: true
    }
  },
  created() {
    gea.iwin(() => {
      // Petición al webservice de los sliders (id de IEA es PIEA)...
      axios.post('https://www.aguascalientes.gob.mx/geaws/api/gea/eve/ws/ac/PIEA')
        .then((res) => {
          this.loading = false;
          this.slides = res.data.reverse();

          gea.iwin(() => {
            const swiper = new Swiper(".swiper-container", {
              observer: true,
              loop: true,
              speed: 1000,
              autoplay: {
                delay: 5000,
                disableOnInteraction: false
              },
              pagination: {
                el: ".swiper-pagination",
                clickable: true
              },
              // navigation: {
              //   nextEl: ".swiper-button-next",
              //   prevEl: ".swiper-button-prev"
              // },
              // scrollbar: {
              //   el: '.swiper-scrollbar'
              // }
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },
  methods:{
    externalLink(link) {
      return link.includes('https://') || link.includes('http://');
    }
  }
}
</script>

<style lang="scss" scoped>

</style>