<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="mini-1">
          <h1>Google Workspace para la Educación</h1>
          <img src="https://www.iea.gob.mx/img/servicios-digitales/i_support.svg" />
          <h2>Estrategia de Formación de Capacidades</h2>
        </div>
        <p>
          Como parte del <b>Catálogo de Herramientas Digitales para la Educación</b>,
          Google Workspace for Education está completamente integrado con las cuentas
          de correo institucional del Instituto de Educación de Aguascalientes. Los
          usuarios de cuentas de correo institucional con dominios <b>iea.edu.mx</b>
          y <b>ags.nuevaescuela.mx</b> tienen <b>Acceso Gratuito a esta Suite de
          Herramientas</b>.
        </p>
        <p>
          Google Workspace incluye aplicaciones esenciales como
          <b>Google Docs</b> para la creación y edición de documentos,
          <b>Google Sheets</b> para hojas de cálculo, <b>Google Slides</b> para
          presentaciones, <b>Google Drive</b> para almacenamiento en la nube, y
          <b>Google Classroom</b>, que facilita la gestión de aulas virtuales. Estas
          herramientas proporcionan a docentes y estudiantes un entorno colaborativo
          y accesible para crear, compartir y organizar contenidos educativos de
          manera eficiente, contribuyendo a un aprendizaje más dinámico y conectado.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_webv2.svg" alt="..." />
          <h3 class="titulo">Empieza a Usar a Google Workspace</h3>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <p>
          El acceso a Google Workspace for Education es muy sencillo para los
          usuarios del Instituto de Educación de Aguascalientes. Solo deben
          dirigirse al Waffle de Google, el ícono de menú de aplicaciones ubicado en
          la esquina superior derecha de la pantalla, desde donde podrán acceder
          rápidamente a herramientas como Google Docs, Sheets, Slides, y más.
          También es posible iniciar sesión directamente en cualquier aplicación de
          Google utilizando su cuenta de correo institucional (dominios <b>iea.edu.mx</b> o
          <b>ags.nuevaescuela.mx</b>), lo que les otorga acceso inmediato y gratuito a
          todas las funcionalidades, facilitando el uso diario de estas herramientas
          en el entorno educativo.
        </p>
      </div>
      <div class="col-12 col-lg-6 text-center">
        <img src="https://www.iea.gob.mx/img/herramientas-digitales/img-acceso-google-workspace.webp" alt="..." class="img-fluid rounded shadow" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'José Roberto Pereda Delgado',
          department: 'Subdirección de Tecnologías de la Información',
          phone: '449 910 5600 ext. 4915',
          updatedAt: '20/09/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "GoogleWorkspaceView",
  components: {
    RIComponent
  }
}
</script>

<style lang="scss" scoped>

</style>