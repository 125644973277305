/**
 * Módulo de rutas del módulo EducacionSuperiorModule. Contiene las definiciones
 * de rutas de la Página de la Dirección de Educación Básica
 * (https://www.iea.gob.mx/educacion-basica).
 */

// Componente raiz del módulo...
import ModuleComponent from '../ModuleComponent.vue';

// Vista (View) "Educación Básica IEA"...
import PrincipalView from "../views/PrincipalView.vue";

// Vistas (Views) del menú "¿Quiénes somos?"...
import SomosView from "../views/somos/SomosView.vue";
import CifrasEducacionView from '../views/somos/CifrasEducacionView.vue';

// Vistas (Views) del menú "Niveles Educativos"...
import EducacionInicialView from "../views/niveles-educativos/EducacionInicialView.vue";
import EducacionEspecialView from "../views/niveles-educativos/EducacionEspecialView.vue";
import EducacionFisicaView from "../views/niveles-educativos/EducacionFisicaView.vue";

// Vista (View) "SisAAE"...
import SisAAEView from "../views/SisAAEView.vue";

// Vistas (Views) del menú "Relación Interinstitucional y Social"...
import ProgramasEducativosView from '../views/relacion-interinstitucional-y-social/ProgramasEducativosView.vue';
import ProgramasView from '../views/relacion-interinstitucional-y-social/programas-educativos/ProgramasView.vue';
import InterinstitucionalesView from '../views/relacion-interinstitucional-y-social/programas-educativos/InterinstitucionalesView.vue';
import PROAGEView from '../views/relacion-interinstitucional-y-social/programas-educativos/PROAGEView.vue';
import PEEIView from '../views/relacion-interinstitucional-y-social/programas-educativos/PEEIView.vue';
import SoyAnfitrionKidsView from "../views/relacion-interinstitucional-y-social/programas-educativos/SoyAnfitrionKidsView.vue";
import PRONIView from '../views/relacion-interinstitucional-y-social/programas-educativos/PRONIView.vue';
import PRONIPrimariaView from '../views/relacion-interinstitucional-y-social/programas-educativos/PRONIPrimariaView.vue';
import PRONISecundariaView from '../views/relacion-interinstitucional-y-social/programas-educativos/PRONISecundariaView.vue';
import PFSEEView from '../views/relacion-interinstitucional-y-social/programas-educativos/PFSEEView.vue';
import DescubriendoAlMundoConOtrosOjosView from '../views/relacion-interinstitucional-y-social/programas-educativos/DescubriendoAlMundoConOtrosOjosView.vue';
import ENTEView from '../views/relacion-interinstitucional-y-social/programas-educativos/ENTEView.vue';

import ConsejoTecnicoEscolarView from '../views/ConsejoTecnicoEscolarView.vue';

// Vistas (Views) del menú de "Enlaces de interés"...
import PESCAView from '../views/PESCAView.vue';

const routes = {
  path: '/educacion-basica',
  component: ModuleComponent,
  meta: { title: 'IEA | Educación Básica' },
  children: [
    { path: '', component: PrincipalView },

    { path: 'somos', component: SomosView },
    { path: 'cifras-de-la-educacion', component: CifrasEducacionView, meta: { title: 'IEA | Cifras de la Educación' } },

    { path: 'educacion-inicial', component: EducacionInicialView, meta: { title: 'IEA | Educación Inicial' } },
    { path: 'educacion-especial', component: EducacionEspecialView, meta: { title: 'IEA | Educación Especial' } },
    { path: 'educacion-fisica', component: EducacionFisicaView, meta: { title: 'IEA | Educación Física' } },

    { path: 'SisAAE', component: SisAAEView, meta: { title: 'IEA | SisAAE' } },

    { path: 'programas-educativos', component: ProgramasEducativosView, meta: { title: 'IEA | Programas Educativos' }, children: [
      { path: '', component: ProgramasView, meta: { title: 'IEA | Programas Educativos' } },
      { path: 'interinstitucionales', component: InterinstitucionalesView, meta: { title: 'IEA | Programas Interinstitucionales' } },
      { path: 'PROAGE', component: PROAGEView, meta: { title: 'IEA | PROAGE' } },
      { path: 'PEEI', component: PEEIView, meta: { title: 'IEA | PEEI' } },
      { path: 'soy-anfitrion-kids', component: SoyAnfitrionKidsView, meta: { title: 'IEA | Soy Anfitrión Kids' } },
      {
        path: 'PRONI', component: PRONIView, meta: { title: 'IEA | PRONI' }, children: [
          { path: 'preescolar-y-primaria', component: PRONIPrimariaView },
          { path: 'secundaria', component: PRONISecundariaView }
        ]
      },
      { path: 'PFSEE', component: PFSEEView, meta: { title: 'IEA | PFSEE' } },
      { path: 'descubriendo-al-mundo-con-otros-ojos', component: DescubriendoAlMundoConOtrosOjosView, meta: { title: 'IEA | Descubriendo al Mundo con Otros Ojos' } },
      { path: 'ENTE', component: ENTEView, meta: { title: 'IEA | ENTE' } },  
      ]
    },

    { path: 'consejo-tecnico-escolar', component: ConsejoTecnicoEscolarView, meta: { title: 'IEA | Consejo Técnico Escolar' } },
    
    { path: 'los-suenos-se-construyen-en-el-aula', component: PESCAView, meta: { title: 'IEA | Los Sueños se Contruyen en el Aula' } },
  ]
}

export default routes;