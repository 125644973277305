<template>
  <div class="container" id="gabinete-cards">
    <div class="row flex-height">
      <div class="col-md-12">
        <div class="card-funcionario">
          <div class="funcionario-info">
            <p class="funcionario-puesto text-end">Aguascalientes, Ags., {{ news.date }}</p>
            <p class="funcionario-puesto text-end">{{ news.type }} No. {{ news.number }}</p>
            <p class="funcionario-nombre text-center mt-3">{{ news.title }}</p>
          </div>
          <img :src="news.images[0].base64" alt="..." class="img-fluid rounded mx-auto d-block" style="width: 85% !important" v-if="news.images.length > 0" />
          <p class="funcionario-texto" v-for="paragraph of news.body.split('\n')">
            {{ paragraph }}
          </p>
          <div class="container">
            <div class="row g-3">
              <div class="col" v-for="image of news.images">
                <img :src="image.base64" alt="..." class="img-fluid rounded shadow" style="max-height: 150px;" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoticiaComponent',
  props: {
    news: Object
  }
}
</script>

<style>
 
</style>
