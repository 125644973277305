<template>
  <div class="container border rounded shadow p-3">
    <div class="row">
      <div class="col">
        <router-link to="/educacion-basica/programas-educativos" class="btn btn-ln-bg1">
          <span class="iconify" data-icon="ph:arrow-bend-down-left-bold"></span>
          Regresar
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="titulo">
          Estrategia Nacional Para Promover Trayectorias Educativas
          Continuas, Completas Y De Excelencia (ENTE)
        </h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>
          Es una iniciativa de la SEP para enfrentar el bajo logro educativo, la
          pérdida de aprendizaje, el abandono escolar y la afectación
          socioemocional, que obstaculizan las trayectorias escolares de los
          estudiantes, en especial de los más desfavorecidos.
        </p>
        <h3 class="subtitulo-sm fs-6">Objetivo general</h3>
        <p>
          Proporcionar a los docentes herramientas y recursos educativos para
          que atiendan en mejores condiciones la pérdida de aprendizajes, el
          rezago, el abandono en sus comunidades escolares y las afectaciones
          socioemocionales de sus estudiantes, con la finalidad de que puedan
          promover para y con ellos trayectorias educativas completas, continuas
          y de excelencia.
        </p>
      </div>
    </div>

    <div class="row row-cols-1">
      <div class="col">
        <h2 class="subtitulo">
          Componente 1. Evaluación diagnóstica y formativa
        </h2>
      </div>
      <div class="col">
        <div class="i-card p-3 mb-3">
          <div class="i-card-header">
            <div class="i-card-icon">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_encuesta.svg" alt="..." />
            </div>
            Evaluación diagnóstica y formativa
          </div>
          <div class="i-card-body">
            Impulsar la cultura y la práctica de la evaluación diagnóstica y
            formativa a partir de la cual escuelas y docentes diseñen
            estrategias de recuperación de aprendizajes y mejora del logro
            escolar.
          </div>
        </div>
      </div>
      <div class="col">
        <h3 class="subtitulo-sm fs-6">
          Evaluación Diagnóstica para Alumnas y Alumnos de Educación Básica
          <br />
          <em>Taller autogestivo</em>
        </h3>
        <p>
          Este taller plantea diversas actividades para que el personal docente
          y directivo se forme en la estrategia de Evaluación Diagnóstica para
          obtener un diagnóstico de los aprendizajes del alumnado al inicio del
          ciclo escolar, a través de revisar y analizar los documentos y
          herramientas que la conforman, así como reflexionar sobre el uso
          didáctico de los resultados.
        </p>
      </div>
      <div class="col">
        <div class="banner_sm bg_dg-7 mb-3">
          <div class="item1">
            <p class="titulo">Taller autogestivo</p>
          </div>
          <div class="item2">
            <p class="contenido">
              Evaluación Diagnóstica para Alumnas y Alumnos de Educación Básica
            </p>
          </div>
          <div class="item3">
            <a href="https://dgfcdd.sep.gob.mx/oferta_nacional/talleres/DGFCDD_DGADAE/uso_de_las_tics/TALLER%20DE%20EVALUACI%C3%93N%20DIAGN%C3%93STICA.pdf" target="_blank" class="btn btn-principal-light">
              Ver taller
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-cols-1">
      <div class="col">
        <h2 class="subtitulo">
          Componente 2. Habilidades para la vida y atención socioemocional
        </h2>
      </div>
      <div class="col">
        <div class="i-card p-3 mb-3">
          <div class="i-card-header">
            <div class="i-card-icon">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_autoestima.svg" alt="..." />
            </div>
            Habilidades para la vida y atención socioemocional
          </div>
          <div class="i-card-body">
            Fomentar en los docentes capacidades para que favorezcan en sus
            estudiantes el desarrollo de habilidades socioemocionales y para la
            vida, claves para la adquisición de los aprendizajes básicos.
          </div>
        </div>
      </div>
      <div class="col">
        <h3 class="subtitulo-sm fs-6">
          Educación y Alfabetización socioemocional
          <br />
          <em>Ciclo de entrevistas</em>
        </h3>
        <p>
          El ciclo de entrevistas comprende 9 sesiones, una por mes, durante el
          ciclo escolar 2022-2023.
        </p>
        <div class="banner_sm bg_dg-2 mb-3" v-if="proximaEntrevista">
          <div class="item1">
            <p class="titulo">
              Próxima sesión: {{ fechaFormateada(proximaEntrevista.fecha) }}
            </p>
          </div>
          <div class="item2">
            <p class="contenido">
              {{ proximaEntrevista.tema }}
            </p>
          </div>
          <div class="item3">
            <a href="https://www.facebook.com/SEPmx/" target="_blank" class="btn btn-principal-light">
              Ver en facebook
            </a>
          </div>
        </div>
        <div class="container mb-5">
          <div class="row row-cols-1 row-cols-md-2 g-3">
            <div class="col" v-for="entrevista of entrevistas">
              <div class="pill-card h-100" :class="entrevista == proximaEntrevista ? 'bg_dg-2' : ''">
                <div class="d-flex align-items-center">
                  <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_fecha.svg" alt="..." class="img-fluid" />
                  <div>
                    <em>{{ fechaFormateada(entrevista.fecha) }}</em> <br />
                    <br />
                    {{ entrevista.tema }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="fw-bold">Ciclo de entrevistas</p>
        <div class="container">
          <div class="row row-cols-1 row-cols-md-2 g-3">
            <div class="col" v-for="entrevista of entrevistas">
              <div class="ratio ratio-21x9" v-if="entrevista.enlace != ''">
                <iframe :src="entrevista.enlace" class="rounded shadow" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div v-else class="loading p-3" style="aspect-ratio: 21/9;">
                <p>
                  <b>Próximamente:</b> {{ entrevista.tema }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-cols-1">
      <div class="col">
        <h2 class="subtitulo">
          Componente 3. Metodologías innovadoras y recursos para recuperación de
          aprendizajes
        </h2>
      </div>
      <div class="col">
        <div class="i-card p-3 mb-3">
          <div class="i-card-header">
            <div class="i-card-icon">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_puericultura.svg" alt="..." />
            </div>
            Metodologías innovadoras y recursos para recuperación de
            aprendizajes
          </div>
          <div class="i-card-body">
            Promover alternativas metodológicas para la recuperación de
            aprendizajes y mejora del logro educativo, así como la socialización
            de recursos y experiencias susceptibles de ser integradas a las
            acciones de las escuelas y el aula.
          </div>
        </div>
      </div>
      <div class="col">
        <h3 class="subtitulo-sm fs-6">Metodologías</h3>
        <p>
          Enriquecer la práctica docente y mejorar el aprendizaje de los
          estudiantes con propuestas metodológicas que favorezcan planeaciones
          didácticas que prioricen la mejora de logro educativo y la
          recuperación de aprendizajes.
        </p>
        <ul>
          <li>
            <b>Diseño Universal de Aprendizaje (CURSO EN LÍNEA).</b> El Diseño
            Universal de Aprendizaje (DUA) proporciona un marco teórico-práctico
            -con bases científicas- para atender a la diversidad desde una
            perspectiva inclusiva. Propone, frente al currículo único y rígido,
            intervenciones flexibles, para responder a las diferentes
            necesidades, competencias e intereses de los estudiantes. El marco
            del DUA sugiere prácticas educativas con objetivos, métodos,
            evaluación, recursos flexibles con la finalidad de que todas y todos
            los estudiantes aprendan.
          </li>
          <li>
            <b>Enseñanza en el nivel apropiado / Campamentos de aprendizaje MIA
            (CURSOS).</b>
            Son jornadas educativas de corta duración, que se realizan en el
            espacio de las escuelas, se focalizan en aprendizajes básicos de
            lectura y matemáticas y contribuyen a la mejora sustantiva de los
            aprendizajes de niños, niñas y adolescentes (NNA).
            <a href="https://educacionbasica.sep.gob.mx/wp-content/uploads/2022/06/Nota-sobre-Metodologia-Campamentos-Aprendizaje-nueva-version.pdf" target="_blank">
              Ver lectura completa.
            </a>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col col-md-6">
                  <a href="https://educacionbasica.sep.gob.mx/wp-content/uploads/2022/06/Metodologia-ABP-y-ECG.pdf" target="_blank" class="pillLink">
                    <div class="pill-card">
                      <div class="d-inline-flex align-items-center">
                        <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_webv2.svg" alt="..." class="img-fluid" />
                        <div>Ver presentación</div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li>
            <b>Aprendizaje Basado en Proyectos: la metodología ideal para educar
            a los nuevos ciudadanos globales.</b>
            Esta metodología permite crear aprendizajes gracias a la realización
            de una producción concreta. A través de una serie de etapas, los
            alumnos colaboran, guiados por el o la docente, para responder a una
            problemática, resolver una situación o responder a una pregunta,
            apoyándose en un tema que suscita su interés. Es muy importante que
            los estudiantes se enfrenten a una problemática real que deberán
            resolver siguiendo un proceso de investigación-acción, movilizando
            conocimientos, habilidades y actitudes de una forma interdisciplinar
            y colaborativa.
            <a href="https://educacionbasica.sep.gob.mx/wp-content/uploads/2022/06/Metodologia-ABP-Final.pdf" target="_blank">
              Ver lectura completa.
            </a>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col col-md-6">
                  <a href="https://educacionbasica.sep.gob.mx/wp-content/uploads/2022/06/Metodologia-ABP-y-ECG.pdf" target="_blank" class="pillLink">
                    <div class="pill-card">
                      <div class="d-inline-flex align-items-center">
                        <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_revista.svg" alt="..." class="img-fluid" />
                        <div>Ver infografía</div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <h3 class="subtitulo-sm fs-6">Recursos</h3>
        <p>
          Poner a disposición de todos los docentes de educación básica del país
          los recursos didácticos -programas, materiales, acervos- que requieren
          para la mejora del logro educativo y la recuperación de aprendizajes.
        </p>
      </div>
    </div>

    <div class="row row-cols-1">
      <div class="col">
        <h2 class="subtitulo">
          Componente 4. Mecanismos para favorecer la permanencia y el egreso
          oportuno
        </h2>
      </div>
      <div class="col">
        <div class="i-card p-3 mb-3">
          <div class="i-card-header">
            <div class="i-card-icon">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_virrete.svg" alt="..." />
            </div>
            Mecanismos para favorecer la permanencia y el egreso oportuno
          </div>
          <div class="i-card-body">
            Fortalecer los mecanismos para prevenir y atender los casos de
            abandono escolar.
          </div>
        </div>
      </div>
      <div class="col">
        <h3 class="subtitulo-sm fs-6">Sistema de Alerta Temprana</h3>
        <p>
          El sistema consiste en realizar un análisis automatizado del histórico
          de la base de datos de los Sistemas de Información y Gestión Educativa
          (SIGED) estatales para responder a la pregunta sobre cuáles son las
          variables que se relacionan con el abandono escolar, es decir, qué han
          tenido en común los estudiantes que históricamente han abandonado.
        </p>
      </div>
    </div>

    <div class="row row-cols-1">
      <div class="col">
        <h2 class="subtitulo">
          Componente 5. Reforzamiento docente en telesecundarias
        </h2>
      </div>
      <div class="col">
        <div class="i-card p-3 mb-3">
          <div class="i-card-header">
            <div class="i-card-icon">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_registro.svg" alt="..." />
            </div>
            Reforzamiento docente en telesecundarias
          </div>
          <div class="i-card-body">
            Impulsar procesos de formación continua, intercambio de recursos y
            utilización de metodologías para la mejora del logro educativo en
            telesecundarias.
          </div>
        </div>
      </div>
      <div class="col">
        <h3 class="subtitulo-sm fs-6">
          Comunidades de Aprendizaje en Relación Tutora (CART)
        </h3>
        <p>
          La relación tutora como una propuesta pedagógica para el desarrollo
          del lenguaje y la comunicación y el pensamiento matemático.
        </p>
      </div>
      <div class="col">
        <div class="banner_sm bg_dg-1 mb-3">
          <div class="item1">
            <p class="titulo">Relación tutora</p>
          </div>
          <div class="item2">
            <p class="contenido">
              Una propuesta para el desarrollo de competencias que permiten el
              aprendizaje autónomo
            </p>
          </div>
          <div class="item3">
            <a href="https://educacionbasica.sep.gob.mx/wp-content/uploads/2022/06/relaciontutora.pdf" target="_blank" class="btn btn-principal-light">
              Ver infografía
            </a>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="ratio ratio-16x9 mb-3">
          <iframe src="https://www.youtube.com/embed/OLgUcS34_wY" class="rounded shadow" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>

    <div class="row row-cols-1 mt-5">
      <div class="col">
        <p class="fw-bold">
          Para mayor información sobre la Estrategia Nacional Para Promover
          Trayectorias Educativas Continuas, Completas Y De Excelencia (ENTE),
          visita el micrositio de la ENTE:
        </p>
        <div class="banner-body-left bg_dg-6">
          <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/banners/img-banner-ENTE.webp" alt="..." class="img-fluid" />
          <div class="baninfo-wrapper">
            <p>
              Estrategia Nacional Para Promover Trayectorias Educativas
              Continuas, Completas Y De Excelencia (ENTE)
            </p>
            <div class="d-flex align-items-end flex-column">
              <a href="https://educacionbasica.sep.gob.mx/ENTE" target="_blank" class="btn btn-principal-light float-end">
                Ir al sitio
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Claudia Carolina Sánchez Gutiérrez',
          department: 'Subdirección de Relación Interinstitucional y Social',
          phone: '449 910 5600 ext. 4074',
          updatedAt: '10/7/2023'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "ENTEView",
  data() {
    return {
      entrevistas: [
        {
          fecha: "26/09/2022",
          tema: "Principios y técnicas para el trabajo educativo de las emociones en preescolar con Emiliana Rodríguez Morales",
          enlace: "https://www.youtube.com/embed/Ns58V45-9Cs"
        },
        {
          fecha: "31/10/2022",
          tema: "Principios y técnicas para el trabajo educativo de las emociones en primaria con Benilde García Cabrero",
          enlace: ""
        },
        {
          fecha: "22/11/2022",
          tema: "Principios y técnicas para el trabajo educativo de las emociones en secundaria con Rocío Chirinos Montalbetti",
          enlace: "https://www.youtube.com/embed/Iw4_1-__bAU"
        },
        {
          fecha: "31/1/2023",
          tema: "Cómo crear un clima emocional favorable para el aprendizaje con Cimmena Chao Rebolledo",
          enlace: "https://www.youtube.com/embed/1plPE40aHu4"
        },
        {
          fecha: "28/2/2023",
          tema: "Familia-escuela: binomio inseparable de la educación socioemocional con Leticia Garcés Larrea",
          enlace: ""
        },
        {
          fecha: "4/4/2023",
          tema: "El bienestar emocional del docente en los distintos momentos de la vida del aula con Arnaldo Canales Benítez",
          enlace: ""
        },
        {
          fecha: "2/5/2023",
          tema: "Las emociones y la solución pacífica de conflictos: cómo trabajarlos en la escuela con Lucas J. J. Malaisi",
          enlace: ""
        },
        {
          fecha: "30/5/2023",
          tema: "Aprendiendo juntos, cooperando y con empatía con Yolanda Muñoz Martínez",
          enlace: ""
        },
        {
          fecha: "4/7/2023",
          tema: "Motivación para el logro y resiliencia con Anna Belykh",
          enlace: ""
        },
      ],
    };
  },
  components: {
    RIComponent,
  },
  computed: {
    proximaEntrevista() {
      let today = new Date();

      for (let i = 0; i < this.entrevistas.length; i++) {
        let date = this.entrevistas[i].fecha.split("/");
        date = new Date(date[2], date[1] - 1, date[0]);

        if (date > today) {
          return this.entrevistas[i];
        }
      }
    },
  },
  methods: {
    fechaFormateada: function (fecha) {
      let months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      let date = fecha.split("/");
      return `${date[0]} de ${months[date[1] - 1]} de ${date[2]}`;
    },
  },
};
</script>

<style>

</style>
