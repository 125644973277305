<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DEB) - Dirección de Educación Básica</h1>
      </div>
    </div>

    <div class="row">
      <div class="col mt-0" id="gabinete-cards">
        <div class="card-funcionario">
          <div class="d-flex align-items-center">
            <img src="https://www.iea.gob.mx/img/logo-ags.png" alt="funcionario" class="img-fluid mx-auto" style="width: 150px;" />
            <div class="funcionario-info">
              <p class="funcionario-puesto text-center">Directora de Educación Básica del IEA</p>
              <h1 class="funcionario-nombre text-center">Mtra. Ma. Dolores Romo Cuevas</h1>
              <p class="funcionario-contacto text-center">
                Av. Tecnológico No. 601, Fracc. Ojocaliente, C.P. 20196
                Aguascalientes, Ags.
              </p>
              <p class="funcionario-contacto text-center">
                (449) 910 5600 ext. 4065
              </p>
              <p class="funcionario-contacto text-center">
                dolores.romo@iea.edu.mx
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Blanca Annette Venegas Haro',
          department: 'Dirección de Educación Básica',
          phone: '449 910 5600 ext. 4196 / 4179',
          updatedAt: '25/06/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "PrincipalView",
  components: {
    RIComponent,
  },
}
</script>

<style lang="scss" scoped>

</style>
