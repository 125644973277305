<template>
  <div class="container border rounded shadow p-3">
    <div class="row">
      <div class="col">
        <router-link to="/educacion-basica/programas-educativos" class="btn btn-ln-bg1">
          <span class="iconify" data-icon="ph:arrow-bend-down-left-bold"></span>
          Regresar
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="titulo">Programa de Apoyo a la Gestión Escolar (PROAGE)</h1>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <h2 class="subtitulo">Formatos PROAGE</h2>
      </div>
      <div class="col-12">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item" v-for="(directory, index) of proage">
            <h2 class="accordion-header" :id="'heading' + index">
              <button class="accordion-button subtitulo-sm mb-0 collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#accordion' + index" aria-expanded="false" :aria-controls="'accordion' + index">
                PROAGE {{ directory.name }}
              </button>
            </h2>
            <div :id="'accordion' + index" class="accordion-collapse collapse" :aria-labelledby="'heading' + index" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Descripción</th>
                      <th class="text-end">Enlace</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(file, subindex) of directory.contents">
                      <td>{{ subindex + 1 }}</td>
                      <td>{{ file.name }}</td>
                      <td class="text-center" width="50">
                        <a :href="file.path" target="_blank">
                          <span v-if="file.path.includes('.pdf')" class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                          <span v-if="file.path.includes('.docx') || file.path.includes('.doc')" class="iconify" data-icon="mdi:microsoft-word" width="35" height="35" color="blue"></span>
                          <span v-if="file.path.includes('.xlsx') || file.path.includes('.xls')" class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Contraloría Social al Servicio del PROAGE</h2>
        <div class="banner_sm bg_dg-5 mb-3">
          <div class="item1">
            <p class="titulo">
              Contraloría Social al Servicio del PROAGE
            </p>
          </div>
          <div class="item2">
            <p class="contenido">Tríptico informativo</p>
          </div>
          <div class="item3">
            <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PROAGE/PROAGE-Tríptico de Contraloría Social.pdf"
              target="_blank" class="btn btn-principal-light">
              Ver Tríptico
            </a>
          </div>
        </div>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>PROAGE-Formato de Quejas y Denuncias.</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PROAGE/PROAGE-Formato de Quejas y Denuncias.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <h2 class="subtitulo">Capacitación PROAGE</h2>
      </div>
      <div class="col-12">
        <p class="fw-bold c_base">Capacitación Estatal a Instituciones Públicas de Educación Básica del PROAGE 2024-2025
        </p>
        <div class="ratio ratio-16x9">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/I1Z5M0THwbw?si=h1KGnw_3awAFwC8k" class="rounded shadow" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Reglas de Operación PROAGE</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(file, index) of rules">
              <td>{{ index + 1 }}</td>
              <td>{{ file.name }}</td>
              <td class="text-center" width="50">
                <a :href="file.path" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Beneficiarios PROAGE</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Padrón de beneficiarios</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(file, index) of beneficiaries">
              <td>{{ index + 1 }}</td>
              <td>{{ file.name }}</td>
              <td class="text-center" width="50">
                <a :href="file.path" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Departamento de Participación Social',
          department: 'Departamento de Participación Social',
          phone: '449 910 5600 ext. 4176 / 4282',
          updatedAt: '06/11/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import proageData from '@/assets/json/proageData.json';
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "PROAGEView",
  data() {
    return {
      proage: proageData.filter((directory) => /^\d{4}-\d{4}$/.test(directory.name)).reverse(),
      beneficiaries: proageData.find((directory) => directory.name === "beneficiarios").contents.reverse(),
      rules: [
        {
          name: "REFORMAS A LAS REGLAS DE OPERACIÓN PARA LA APLICACIÓN EN EL ESTADO DEL PROGRAMA DE APOYO A LA GESTIÓN ESCOLAR PARA EL CICLO ESCOLAR 2024-2025 (7/10/2024)",
          path: "https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10310.pdf#page=7"
        },
        {
          name: "REFORMAS A LAS REGLAS DE OPERACIÓN PARA LA APLICACIÓN EN EL ESTADO DEL PROGRAMA DE APOYO A LA GESTIÓN ESCOLAR PARA EL CICLO ESCOLAR 2023-2024 (11/09/2023)",
          path: "https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9946.pdf#page=2"
        },
        {
          name: "REGLAS DE OPERACIÓN PARA LA APLICACIÓN EN EL ESTADO DEL PROGRAMA DE APOYO A LA GESTIÓN ESCOLAR PARA CICLO ESCOLAR 2022-2023",
          path: "https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PROAGE/reglas-operacion/REGLAS DE OPERACIÓN PROAGE 2022-2023.pdf"
        },
        {
          name: "REFORMAS A LAS REGLAS DE OPERACIÓN PARA LA APLICACIÓN EN EL ESTADO DEL PROGRAMA DE APOYO A LA GESTIÓN ESCOLAR PARA CICLO ESCOLAR 2022 (7/11/2022)",
          path: "https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9512.pdf#page=2"
        },
        {
          name: "REFORMAS Y ADICIONES A LAS REGLAS DE OPERACIÓN PARA LA APLICACIÓN EN EL ESTADO DEL PROGRAMA DE APOYO A LA GESTIÓN ESCOLAR PARA EL CICLO ESCOLAR 2022-2023 (5/12/2022)",
          path: "https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9531.pdf#page=17"
        },
        {
          name: "REGLAS DE OPERACIÓN PARA LA APLICACIÓN EN EL ESTADO DEL PROGRAMA DE APOYO A LA GESTIÓN ESCOLAR PARA CICLO ESCOLAR 2021-2022",
          path: "https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PROAGE/reglas-operacion/REGLAS DE OPERACIÓN PROAGE 2021-2022.pdf"
        },
        {
          name: "REGLAS DE OPERACIÓN PARA LA APLICACIÓN EN EL ESTADO DEL PROGRAMA DE APOYO A LA GESTIÓN ESCOLAR PARA CICLO ESCOLAR 2020-2021",
          path: "https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PROAGE/reglas-operacion/REGLAS DE OPERACIÓN PROAGE 2020-2021.pdf"
        },
        {
          name: "REGLAS DE OPERACIÓN PARA LA APLICACIÓN EN EL ESTADO DEL PROGRAMA DE APOYO A LA GESTIÓN ESCOLAR PARA CICLO ESCOLAR 2019-2020",
          path: "https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PROAGE/reglas-operacion/REGLAS DE OPERACIÓN PROAGE 2019-2020.pdf"
        }
      ]
    }
  },
  components: {
    RIComponent
  }
}
</script>

<style lang="scss" scoped>

</style>