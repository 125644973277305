<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="cabecera-1" style=" background-image: linear-gradient(305.24deg, rgba(41, 60, 152, 0.5) 23.02%, rgba(41, 60, 152, 0.357) 80.31%), url('https://www.iea.gob.mx/img/bg.png');">
          <div class="info-contenedor-cb">
            <img src="https://www.aguascalientes.gob.mx/logodep/img-escudoiea.png" alt="Logo de dependencia" class="logo" />
            <img src="https://www.aguascalientes.gob.mx/logodep/img-escudoiea_g.png" alt="Logo de dependencia Completo" class="logoCompleto" />
            <div class="abr-dependencia">IEA</div>
            <div class="nombre-dependencia">Instituto de Educación de Aguascalientes</div>
          </div>

          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-sae">
              <li class="breadcrumb-item">
                <a href="https://www.aguascalientes.gob.mx">Inicio</a>
              </li>
              <li class="breadcrumb-item">
                <a href="https://www.aguascalientes.gob.mx/dependencias">Dependencias</a>
              </li>
              <li class="breadcrumb-item active" aria-current="Sitio Actual">IEA</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderIEAComponent"
}
</script>
