<template>
  <div class="position-relative" v-if="loading">
    <div class="position-fixed top-50 start-50 translate-middle" style="z-index: 1090;">
      <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <div class="container" style="margin-top: 120px;">
    <div class="row">
      <div class="col">
        <div class="mini-1 text-center">
          <h1>Becas Académicas a Concesionarios y Operadores del Transporte Público del Estado de Aguascalientes 2024</h1>
          <h2>Consulta de Resultado de Asignación Personalizado</h2>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col col-md-6 text-center">
        <div class="mb-3 text-center">
          Ingresa tu <b>CURP</b> y el <b>FOLIO DE REGISTRO</b> que recibiste vía
          correo electrónico al momento de realizar tu solicitud. Cualquier duda
          o comentario comunícate al Tel. 449 910 5600 ext. 4234.
        </div>
        <div class="mb-3">
          <input type="text" class="form-control" placeholder="Folio de Registro" style="text-transform: uppercase;" v-model="folio" />
        </div>
        <div class="mb-3">
          <input type="text" class="form-control" placeholder="CURP" style="text-transform: uppercase;" v-model="curp" />
        </div>
        <div class="mb-3">
          <button class="btn btn-principal" @click="search()">Buscar</button>
        </div>
      </div>
    </div>

    <div class="row" v-if="result">
      <div class="col">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Folio</th>
              <th>Nombre</th>
              <th>CURP</th>
              <th>Resultado</th>
              <th>Asignación</th>
              <th v-if="result.status === 'ACEPTADO'" class="text-center">Impresión de Reporte</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ result.folio }}</td>
              <td>{{ result.name }}</td>
              <td>{{ result.curp }}</td>
              <td>{{ result.status }}</td>
              <td>{{ result.assignment }}</td>
              <td v-if="result.status === 'ACEPTADO'" class="text-center">
                <button class="btn btn-ln-default" @click="generateResult()" :disabled="loading">
                  <span class="iconify" data-icon="ph:printer-fill" width="35" height="35"></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row" v-if="resultNotFoundMessage !== ''">
      <div class="col">
        <p class="text-center fw-bold">{{ resultNotFoundMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import results from '@/assets/json/BCMOVData.json';
import { alert } from '@/assets/js/functions';

export default {
  name: "BCMOVView",
  data() {
    return {
      results: results,
      folio: "",
      curp: "",
      result: null,
      resultNotFoundMessage: "",
      loading: false
    }
  },
  methods: {
    search() {
      this.result = null;
      this.resultNotFoundMessage = "No se encontró ningún resgistro para el FOLIO DE REGISTRO y la CURP proporcionados.";

      this.results.forEach((result) => {
        if (result.folio === this.folio.toUpperCase() && result.curp === this.curp.toUpperCase()) {
          this.result = result;
          this.resultNotFoundMessage = "";
        }
      });
    },
    generateResult() {
      this.loading = true;

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://servicios.iea.edu.mx/ieaws/ws-iea/reports/BCMOV',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: this.result
      };

      gea.iwin(() => {
        axios.request(config)
          .then((response) => {
            this.loading = false;

            // Decode Base64 string to binary data...
            const pdfData = atob(response.data);

            // Create a byte array from the binary data...
            const bytes = new Uint8Array(pdfData.length);
            for (let i = 0; i < pdfData.length; i++) {
              bytes[i] = pdfData.charCodeAt(i);
            }

            // Create a Blob from the bytes...
            const blob = new Blob([bytes], { type: "application/pdf" });

            // Create a URL from the Blob...
            const url = URL.createObjectURL(blob);

            // Create a link and start the download...
            const link = document.createElement("a");
            link.href = url;
            link.download = this.result.folio + ".pdf"; // File name to download...
            link.click();

            // Release Blob URL to free memory...
            URL.revokeObjectURL(url);
          })
          .catch((error) => {
            this.loading = false;
            alert("Ocurrió un error al imprimir tu comprobante, intenta de nuevo más tarde.", "danger");
          });
      });
    }
  }
}
</script>

<style lang="scss" scoped>

</style>