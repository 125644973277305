/**
 * Módulo de rutas del módulo STEAMHidrotalentModule. Contiene las definiciones
 * de rutas de la Página del evento STEAM Hidrotalent (https://www.iea.gob.mx/STEAMHidrotalent).
 */

// Componente principal del módulo...
import ModuleComponent from "../ModuleComponent.vue";

// import UniversidadesView from '../views/UniversidadesView.vue';

const moduleRoute = {
  path: '/STEAMHidrotalent',
  component: ModuleComponent,
  meta: { title: 'IEA | STEAM Hidrotalent' },
  // children: [
  //   { path: '', component: UniversidadesView }
  // ]
};

export default moduleRoute;