<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- Buscador de trámites -->
        <div id="tgea"></div>
      </div>
      <div class="col-12">
        <router-link to="/educacion-media-superior/revista" class="tarjeta bg_dg-5">
          <p class="inferior">Revista DEMS</p>
        </router-link>
      </div>
      <div class="col-12">
        <!-- Sección de enlaces relacionados -->
        <div class="enlacesDependencias">
          <h1 class="titulo">Enlaces Relacionados</h1>
          <ul class="enlaces-list">
            <li class="enlaces-item">
              <a href="http://www.prepaaguascalientes.sep.gob.mx/prepaabierta/index.php/preparatoria-abierta" target="_blank" rel="noopener noreferrer" class="enlaces-link">Preparatoria Abierta</a>
            </li>
            <li class="enlaces-item">
              <a href="https://prepaenlinea.sep.gob.mx" target="_blank" rel="noopener noreferrer" class="enlaces-link">Prepa en Línea</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12">
        <!-- Sección de enlaces de interés -->
        <div class="enlacesDependencias">
          <h1 class="titulo">Enlaces de Interés</h1>
          <ul class="enlaces-list">
            <li class="enlaces-item">
              <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/bachilleratos-estatales/Calendario-Escolar-2024-2025-BD.pdf" target="_blank" rel="noopener noreferrer" class="enlaces-link">Calendario Escolar 2024-2025 Bachillerato del Deporte</a>
            </li>
            <li class="enlaces-item">
              <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/bachilleratos-estatales/Calendario-Escolar-2024-2025-BAH.pdf" target="_blank" rel="noopener noreferrer" class="enlaces-link">Calendario Escolar 2024-2025 Bachillerato en Arte y Humanidades "José Guadalupe Posada"</a>
            </li>
            <li class="enlaces-item">
              <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/bachilleratos-estatales/Calendario-Escolar-2024-2025-BGMP.pdf" target="_blank" rel="noopener noreferrer" class="enlaces-link">Calendario Escolar 2024-2025 Bachillerato General Militarizado Poniente "1er Escuadrón de Lanceros de Aguascalientes"</a>
            </li>
            <li class="enlaces-item">
              <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/bachilleratos-estatales/Calendario-Escolar-2024-2025-BGMO.pdf" target="_blank" rel="noopener noreferrer" class="enlaces-link">Calendario Escolar 2024-2025 Bachillerato General Militarizado Oriente "José María Chávez"</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12">
        <!-- Sección de redes sociales -->
        <h1 class="titulo">Redes Sociales</h1>
        <div class="redes-button-wrapper">
          <a href="https://www.facebook.com/DEMS.AGS" target="_blank">
            <div class="fb-button">
              <div class="button-content">
                <span class="iconify" data-icon="uil:facebook-f" width="30" height="30" color="white"></span>
                <p class="red-button-title text-center">Facebook</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarDEMSComponent",
};
</script>

<style lang="scss" scoped>

</style>
