<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DEMS) - Certificación y Formación para el Trabajo</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>
          La formación para el trabajo, a la referida en el artículo 45 de la Ley
          General de Educación, que procurará la adquisición de conocimientos,
          habilidades o destrezas, que permitan a quien la recibe desarrollar una
          actividad productiva demandada en el mercado, mediante alguna ocupación o
          algún oficio calificado. (Acuerdo 03/01/18 por el que se establecen los
          trámites y procedimientos relacionados con el reconocimiento de validez
          oficial de estudios de formación para el trabajo).
        </p>
        <p>
          Los Centros de Formación para el Trabajo como instituciones educativas
          ofertan a toda persona de 15 años y más, que sepa leer y escribir, bajo
          los principios de equidad, justicia, transparencia, pertinencia y calidad,
          coadyuvando así al acceso al empleo, aldesarrollo económico, social y al
          mejoramiento de la calidad de vida de la población.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Centros de Formación para el Trabajo</h2>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
      <div class="col d-flex justify-content-center" v-for="centroDeFormacionParaElTrabajo of centrosDeFormacionParaElTrabajo">
        <button class="btn btn-principal-light p-0" @click="showModal(centroDeFormacionParaElTrabajo)">
          <div class="card" style="max-width: 18rem; min-height: 18rem;">
            <img :src="centroDeFormacionParaElTrabajo.logo" class="card-img-top p-3" alt="..." />
            <p class="fw-bold c_base text-center">{{ centroDeFormacionParaElTrabajo.nombre }}</p>
          </div>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Edith Mariana Ponce Rodríguez',
          department: 'Departamento de Certificación y Formación para el Trabajo',
          phone: '449 149 2400 ext. 7818',
          updatedAt: '16/04/2024',
        }" />
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="centroDeFormacionParaElTrabajoModal" tabindex="-1" aria-hidden="true" v-if="centroDeFormacionParaElTrabajoModal">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="subtitulo">{{ centroDeFormacionParaElTrabajoModal.nombre }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="text-align: justify;">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-lg-4">
                <img :src="centroDeFormacionParaElTrabajoModal.logo" class="card-img-top p-3" alt="..." />
                <p class="fw-bold c_base text-center">{{ centroDeFormacionParaElTrabajoModal.nombre }}</p>
              </div>
              <div class="col-12 col-lg-8">
                <p class="fw-bold c_base">
                  <span class="iconify" data-icon="akar-icons:location" width="25" height="25" data-inline="false"></span>
                  {{ centroDeFormacionParaElTrabajoModal.direccion }}
                </p>
                <p class="fw-bold c_base">
                  <span class="iconify" data-icon="akar-icons:phone" width="25" height="25" data-inline="false"></span>
                  {{ centroDeFormacionParaElTrabajoModal.telefono }}
                </p>
                <p class="fw-bold c_base">Carreras y No. de RVOE:</p>
                <ol>
                  <li v-for="carrera of centroDeFormacionParaElTrabajoModal.carreras" class="fw-bold c_base text-lowercase text-capitalize">{{ carrera }}</li>
                </ol>
                <div class="d-flex">
                  <p class="fw-bold c_base me-3">Modalidad: {{ centroDeFormacionParaElTrabajoModal.modalidad }}</p>
                  <p class="fw-bold c_base">Periodicidad: {{ centroDeFormacionParaElTrabajoModal.periodicidad }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-principal" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "FormacionParaElTrabajoView",
  components: {
    RIComponent,
  },
  data() {
    return {
      centrosDeFormacionParaElTrabajo: [
        {
          nombre: "ACADEMIA DE ESTETICISMO RAQUEL",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/academia-de-esteticismo-raquel.webp",
          direccion: "Prol. Libertad 240, Barrio de Guadalupe, C.P. 20000 Aguascalientes, Ags.",
          telefono: "449 915 8304",
          carreras: [
            "ESTETICISMO (2194)"
          ],
          modalidad: "ESCOLARIZADA",
          periodicidad: "SEMESTRAL"
        },
        {
          nombre: "CENTRO CULTURAL MÉXICO CONTEMPORÁNEO",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/centro-cultural-méxico-contemporáneo.webp",
          direccion: "Fuente de las Cibeles 202, Jardines de las Fuentes, C.P. 20270 Aguascalientes, Ags.",
          telefono: "449 978 8877",
          carreras: [
            "TÉCNICO EN MÚSICA CONTEMPORÁNEA POPULAR (2523)"
          ],
          modalidad: "ESCOLARIZADA",
          periodicidad: "SEMESTRAL"
        },
        {
          nombre: "CENTRO DE INVESTIGACIÓN Y DESARROLLO EN ELÉCTRONICA Y MECATRONICA",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/CIDEM.webp",
          direccion: "Quinta Avenida 312, Las Américas, C.P. 20230 Aguascalientes, Ags.",
          telefono: "449 293 8494 y 449 377 8888",
          carreras: [
            "TÉCNICO PROFESIONAL LÍDER EN MECATRÓNICA (2668)",
            "TÉCNICO RIKEN (2763)",
            "TÉCNICO EN MANTENIMIENTO (2815)",
            "TÉCNICO INDUSTRIAL GKN (2930)",
          ],
          modalidad: "MIXTA",
          periodicidad: "SEMESTRAL / CUATRIMESTRAL"
        },
        {
          nombre: "COLEGIO DEL ARTE Y LAS CIENCIAS",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/CAyC.webp",
          direccion: "C. Rivero y Gutiérrez 320, Zona Centro, C.P. 20000 Aguascalientes, Ags.",
          telefono: "449 235 5741",
          carreras: [
            "TÉCNICO EN ARTES VISUALES Y ESCÉNICAS (2635)"
          ],
          modalidad: "MIXTA",
          periodicidad: "SEMESTRAL"
        },
        {
          nombre: "ESCUELA DE MÚSICA, TEATRO Y DANZA FERNANDO WAGNER",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/escuela-de-música-teatro-y-danza-fernando-wagner.webp",
          direccion: "Av. Aguascalientes Sur 1137, Vista del Sol I, C.P. 20264 Aguascalientes, Ags.",
          telefono: "449 172 2994",
          carreras: [
            "TÉCNICO EJECUTANTE EN TEATRO MUSICAL Y ACTUACIÓN (2635)"
          ],
          modalidad: "MIXTA",
          periodicidad: "SEMESTRAL"
        },
        {
          nombre: "ESCUELA PROFESIONAL TÉCNICA AGUASCALIENTES",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/EPTA.webp",
          direccion: "Rafaela Suárez 220, Bulevares 1° Sección, C.P. 20288 Aguascalientes, Ags.",
          telefono: "449 913 0947",
          carreras: [
            "TÉCNICO PROFESIONAL EN ENFERMERÍA GENERAL (2496)"
          ],
          modalidad: "ESCOLARIZADA",
          periodicidad: "SEMESTRAL"
        },
        {
          nombre: "ESCUELA SOFÍA SUÁREZ DEL REAL, CONFECCIÓN Y DISEÑO DE MODAS",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/escuela-sofía-suárez-del-real.webp",
          direccion: "Gral. Francisco Villa 504, Gremial, C.P. 20030 Aguascalientes, Ags.",
          telefono: "449 582 7573",
          carreras: [
            "TÉCNICO PROFESIONAL EN CONFECCIÓN Y DISEÑO DE MODAS (2107)"
          ],
          modalidad: "MIXTA",
          periodicidad: "CUATRIMESTRAL"
        },
        {
          nombre: "EUROIDIOMAS",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/euroidiomas.webp",
          direccion: "Vázquez del Mercado 132, Zona Centro, C.P. 20000 Aguascalientes, Ags.",
          telefono: "449 555 3078",
          carreras: [
            "IDIOMA ALEMÁN (2661)",
            "IDIOMA INGLÉS (2662)",
            "IDIOMA FRANCÉS (2663)",
            "IDIOMA ITALIANO (2664)",
            "IDIOMA RUSO (2665)",
            "IDIOMA PORTUGUÉS (2775)",
            "IDIOMA COREANO (2776)",
            "IDIOMA JAPONÉS (2777)"
          ],
          modalidad: "MIXTA",
          periodicidad: "SEMESTRAL / CUATRIMESTRAL"
        },
        {
          nombre: "INSTITUTO DE BELLEZA E IMAGEN FRANCISCO GUTIÉRREZ",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/instituto-de-belleza-e-imagen-francisco-gutiérrez.webp",
          direccion: "General Guadalupe Victoria 702, Zona Centro, C.P. 20000 Aguascalientes, Ags.",
          telefono: "449 494 9930",
          carreras: [
            "ESTILISTA PROFESIONAL (2948)"
          ],
          modalidad: "MIXTA",
          periodicidad: "CUATRIMESTRAL"
        },
        {
          nombre: "INSTITUTO CULINARIO MÉXICO EUROPEO",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/ICUME.webp",
          direccion: "Prolongacion San Miguel 308, Gamez, C.P. 20240 Aguascalientes, Ags.",
          telefono: "449 709 0811",
          carreras: [
            "CHEF CARRERA TÉCNICA (2201)"
          ],
          modalidad: "ESCOLARIZADA",
          periodicidad: "CUATRIMESTRAL"
        },
        {
          nombre: "INSTITUTO DE ESPECIALIDADES ESTETOCOSMETICAS",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/IEECO.webp",
          direccion: "República de Ecuador 507, Las Américas, C.P. 20239 Aguascalientes, Ags.",
          telefono: "449 709 0811",
          carreras: [
            "ESTILISMO (2451)",
            "ESTETOCOSMETÓLOGIA (2452)"
          ],
          modalidad: "ESCOLARIZADA",
          periodicidad: "SEMESTRAL"
        },
        {
          nombre: "INSTITUTO DE GASTRONOMÍA ISIMA",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/ISIMA.webp",
          direccion: "Av. Adolfo López Mateos Ote. 327, Barrio del Encino, C.P. 20240 Aguascalientes, Ags.",
          telefono: "449 918 2845",
          carreras: [
            "TÉCNICO EN GASTRONOMÍA (2450)"
          ],
          modalidad: "ESCOLARIZADA",
          periodicidad: "SEMESTRAL"
        },
        {
          nombre: "INSTITUTO EMPRESARIAL CIEPAA",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/instituto-empresarial-CIEPAA.webp",
          direccion: "Nieto 231-A, Barrio de San Marcos, C.P. 20070 Aguascalientes, Ags.",
          telefono: "449 916 5550",
          carreras: [
            "TÉCNICO EN PUERICULTURA (2491)"
          ],
          modalidad: "MIXTA",
          periodicidad: "SEMESTRAL"
        },
        {
          nombre: "INSTITUTO GASTRONÓMICO DE AGUASCALIENTES",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/IGA.webp",
          direccion: "Av. Héroe de Nacozari 2428, Residencial del Parque, C.P. 20277 Aguascalientes, Ags.",
          telefono: "449 146 6898",
          carreras: [
            "CHEF PASTELERO (2529)",
            "TÉCNICO PARA LA FORMACIÓN DEL CHEF (2431)",
            "CHEF TÉCNICO PROFESIONAL (2687)",
          ],
          modalidad: "MIXTA / ESCOLARIZADA",
          periodicidad: "SEMESTRAL"
        },
        {
          nombre: "INSTITUTO GDL DE BELLEZA Y ESTILISMO",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/instituto-GDL-de-belleza-y-estilismo.webp",
          direccion: "Gral Álvaro Obregón 231, Zona Centro, C.P. 20000 Aguascalientes, Ags.",
          telefono: "449 206 9625",
          carreras: [
            "CONSULTORA DE BELLEZA Y ESTILISTA PROFESIONAL (2622)"
          ],
          modalidad: "ESCOLARIZADA",
          periodicidad: "CUATRIMESTRAL"
        },
        {
          nombre: "INSTITUTO DE IMAGEN PÚBLICA",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/IIP.webp",
          direccion: "Agustín Melgar 108, Héroes, C.P. 20190 Aguascalientes, Ags.",
          telefono: "449 343 3430",
          carreras: [
            "TÉCNICO EN DISEÑO DE IMAGEN FÍSICA (2830)",
            "TÉCNICO EN LOCUCIÓN Y EXPRESIÓN CORPORAL (2831)",
            "TÉCNICO EN DIRECCIÓN DE MODELOS Y PASARELA DE MODAS (2832)",
          ],
          modalidad: "MIXTA",
          periodicidad: "SEMESTRAL"
        },
        {
          nombre: "INSTITUTO PEDAGÓGICO DE CIENCIAS DE LA CONDUCTA",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/IPECC.webp",
          direccion: "Av. Viña Antigua 108, Fraccionamiento Viña Antigua, C.P. 20908 Jesús María, Ags.",
          telefono: "449 363 3337",
          carreras: [
            "ASISTENTE EN EDUCACIÓN INICIAL Y PREESCOLAR (2666)"
          ],
          modalidad: "MIXTA",
          periodicidad: "CUATRIMESTRAL"
        },
        {
          nombre: "INSTITUTO TÉCNICO DENTAL",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/ITD.webp",
          direccion: "Francisco G. Hornedo 105, Zona Centro, C.P. 20000 Aguascalientes, Ags.",
          telefono: "449 915 1216",
          carreras: [
            "TÉCNICO EN PRÓTESIS DENTAL (2521)",
            "TÉCNICO EN PRÓTESIS DENTAL (2522)"
          ],
          modalidad: "MIXTA / ESCOLARIZADA",
          periodicidad: "SEMESTRAL"
        },
        {
          nombre: "INSTITUTO TOLOSA",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/instituto-tolosa.webp",
          direccion: "Decreto 27 de Septiembre 105, Barrio de la Purísima, C.P. 20259 Aguascalientes, Ags.",
          telefono: "449 915 1216",
          carreras: [
            "TERAPIA DE LENGUAJE (2698)",
            "ESPECIALISTA EN UÑAS DE PIES Y MANOS (2698)",
            "VERSATILIDAD EN EL PEINADO (2696)",
            "MICROPIGMENTACIÓN FACIAL (2694)",
            "ESPECIALISTA EN COSMETOLOGÍA (2699)",
            "COSMETOLOGÍA CORPORAL (2701)",
            "INTERVENCIÓN INTEGRAL DEL INFANTE (2697)",
            "COSMETOLOGÍA FACIAL (2700)",
            "ESTRATEGIAS PSICOEDUCATIVAS EN SEXUALIDAD INFANTIL Y ADOLESCENTE (2695)"
          ],
          modalidad: "MIXTA",
          periodicidad: "SEMESTRAL"
        },
        {
          nombre: "ROLCAR INSTITUTO DE SUPERACION PROFESIONAL",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/ROLCAR-instituto-de-superacion-profesional.webp",
          direccion: "Av. Petróleos Mexicanos 602, Industrial, C.P. 20030 Aguascalientes, Ags.",
          telefono: "449 263 0844",
          carreras: [
            "VENDEDOR PROFESIONAL DE AUTOPARTES (2530)"
          ],
          modalidad: "ESCOLARIZADA",
          periodicidad: "CUATRIMESTRAL"
        },
        {
          nombre: "UNIVERSIDAD DE ESTUDIOS AVANZADOS",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/UNEA.webp",
          direccion: "Av. Héroe de Nacozari 402, Trojes del Sur, C.P. 20250 Aguascalientes, Ags.",
          telefono: "449 918 4088",
          carreras: [
            "TÉCNICO PROFESIONAL EN ENFERMERÍA GENERAL (2314)"
          ],
          modalidad: "ESCOLARIZADA",
          periodicidad: "CUATRIMESTRAL"
        },
        {
          nombre: "VOCABLO",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/VOCABLO.webp",
          direccion: "República Mexicana 304, Col. Jardines de Santa Elena, C.P. 20235 Aguascalientes, Ags.",
          telefono: "449 261 9320",
          carreras: [
            "IDIOMA INGLÉS (2872)"
          ],
          modalidad: "MIXTA",
          periodicidad: "SEMESTRAL"
        },
        {
          nombre: "VOCES ESCUELA DE LOCUCIÓN",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/certificacion-y-formacion-para-el-trabajo/VOCES.webp",
          direccion: "Gral. Vicente Guerrero Sur 103 Int. 102, Zona Centro, C.P. 20000 Aguascalientes, Ags.",
          telefono: "449 212 5772",
          carreras: [
            "TÉCNICO PROFESIONAL EN LOCUCIÓN (2446)"
          ],
          modalidad: "ESCOLARIZADA",
          periodicidad: "CUATRIMESTRAL"
        }
      ],
      centroDeFormacionParaElTrabajoModal: null
    }
  },
  methods: {
    showModal(bachilleratoIncorporado) {
      this.centroDeFormacionParaElTrabajoModal = bachilleratoIncorporado;

      gea.iwin(() => {
        const centroDeFormacionParaElTrabajoModal = bootstrap.Modal.getOrCreateInstance("#centroDeFormacionParaElTrabajoModal");
        centroDeFormacionParaElTrabajoModal.show();
      });
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
