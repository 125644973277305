<template>
  <div class="container border rounded shadow p-3">
    <div class="row g-5">
      <div class="col-12">
        <router-link to="/expouniversidades" class="btn btn-ln-bg1">
          <span class="iconify" data-icon="ph:arrow-bend-down-left-bold"></span>
          Regresar
        </router-link>
      </div>
      
      <div class="col-12 col-md-4 text-center">
        <img :src="university.logo" alt="..." class="img-fluid rounded shadow" width="400" height="400" style="max-height: 300px;" />

        <a :href="university.pagina_web" target="_blank" class="pillLink">
          <div class="pill-card">
            <div class="d-inline-flex align-items-center">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_web.svg" alt="..." class="img-fluid" />
              <div>
                Página web de la universidad
              </div>
            </div>
          </div>
        </a>
      </div>

      <div class="col-12 col-md-8">
        <div class="container">
          <div class="row justify-content-end">
            <div class="col-12">
              <h1 class="titulo">{{ university.nombre }} ({{ university.siglas }})</h1>
            </div>

            <div class="col-12">
              <div class="d-flex align-items-center">
                <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_virrete.svg" alt="..." />
                <h2 class="subtitulo">Oferta Educativa</h2>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <input placeholder="Encuentra tu carrera" class="me-3" style="text-transform: uppercase;" v-model="search" />
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-search c_base" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                </svg>
              </div>
            </div>

            <div class="col-12">
              <div class="container" v-if="search == ''">
                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                  <div class="col" v-for="career in university.carreras">
                    <div class="pillLink">
                      <div class="pill-card d-flex align-items-center" style="min-height: 6.5rem;">
                        {{ career }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container" v-if="search != ''">
                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                  <div class="col" v-for="career in careers">
                    <div class="pillLink">
                      <div class="pill-card d-flex align-items-center" style="min-height: 6.5rem;">
                        {{ career }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <a :href="university.oferta_educativa" target="_blank" class="pillLink">
                <div class="pill-card">
                  <div class="d-inline-flex align-items-center">
                    <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_revista.svg" alt="..." class="img-fluid" />
                    <div>
                      Oferta educativa
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-12">
              <div class="container rounded shadow p-3 mt-3">
                <div class="row row-cols-1 row-cols-lg-2 g-3">
                  <div class="col">
                    <div class="d-flex align-items-center">
                      <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_rutas.svg" alt="..." />
                      <h2 class="subtitulo">Ubicación</h2>
                    </div>
                    <p class="fw-bold c_base">{{ university.direccion }}</p>
                    <div class="d-flex align-items-center justify-content-center fw-bold c_base">
                      <span class="iconify" data-icon="akar-icons:phone" width="35" height="35"></span>
                      {{ university.telefono }}
                    </div>
                  </div>
                  <div class="col">
                    <iframe :src="university.ubicacion" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="rounded"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import universities from "@/assets/json/universidades.json"

export default {
  name: "UniversidadView",
  data: () => {
    return {
      universities: universities,
      search: ""
    }
  },
  computed: {
    university() {
      let university;

      this.universities.forEach((element) => {
        if (element.siglas == this.$route.params.siglas) {
          university = element;
        }
      });

      return university;
    },
    careers() {
      return this.university.carreras.filter((career) => {
        if (career.toUpperCase().includes(this.search.toUpperCase())) {
          return career;
        }
      });
    }
  }
}
</script>

<style>

</style>