<template>
  <header class="w-100 bg-img"
    style="background-image: url('img/STEAMHidrotalent/bg.webp');  background-size: cover; background-repeat: no-repeat; position: relative;">
    <div class="container">
      <div class="row ">
        <div class="col-lg-12 align-middle headerContent" style="padding-bottom: 18%;">
          <p class="supLong mb-0 c_3" style="line-height: 1;">STEAM-IE HIDROTALENT</p>
          <div class="d-flex">
            <p class="inf">2023</p>
            <img src="img/STEAMHidrotalent/i_support.svg" class="digitalImg d-none d-lg-block" />
          </div>
          <p class="frase">25 y 26 de octubre de 2023</p>
        </div>
      </div>
    </div>

    <img class="divider" src="img/STEAMHidrotalent/divider.svg" alt="">
  </header>
</template>

<script>
export default {
  name: "HeaderComponent"
}
</script>

<style lang="scss" scoped></style>