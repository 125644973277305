<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo">IEA - Lineamientos y políticas de escuelas</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>ACTA ENTEGA - RECEPCIÓN</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/lineamientos/ACTA E-R OIC.docx">
                  <span class="iconify" data-icon="mdi:microsoft-word" width="35" height="35" color="blue"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>FORMATO DE INGRESOS Y EGRESOS PARA ESCUELAS PRIMARIAS 2023-2024 (SEGUNDO PERIODO)</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/lineamientos/INGRESOS_EGRESOS_PRIMARIAS 2023_2024 SEGUNDO PERIODO.xlsx">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>FORMATO DE INGRESOS Y EGRESOS PARA ESCUELAS SECUNDARIAS 2023-2024 (SEGUNDO PERIODO)</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/lineamientos/INGRESOS_EGRESOS_SECUNDARIAS 2023_2024 SEGUNDO PERIODO.xlsx">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>MAPEO DE ANEXOS (INSTRUCCIONES) 2018</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/lineamientos/MAPEO DE ANEXOS (INSTRUCCIONES) OIC 2018.xlsx">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>PORTADA PARA ENTREGA - RECEPCIÓN OIC 2018</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/lineamientos/PORTADA PARA ENTREGA-RECEPCION OIC 2018.xls">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>LEY DE RESPONSABILIDADES ADMINISTRATIVAS</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/lineamientos/LEY DE RESPONSABILIDADADES ADMINISTRATIVAS DEL ESTADO DE AGUASCALIENTES.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>NORMAS DE BIENES DEL IEA</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/lineamientos/NORMAS BIENES IEA 12-11-18.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>LINEAMIENTOS GENERALES DE LAS NORMAS DICTAMINADORAS DE DEPURACIÓN Y ELIMINACIÓN DE DOCUMENTOS</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/lineamientos/Lineamientos generales de la comisión dictaminadora de depuración y eliminación de documentos.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>CIRCULAR 002, EMITIDA EL 16 DE MARZO DEL 2018, QUE HACE REFERENCIA A LOS CRITERIOS QUE DEBERÁN APLICARSE EN MATERIA DE ADMINISTRACIÓN DE PERSONAL PARA LA DOCUMENTACIÓN Y REGISTRO DE ASISTENCIA</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/lineamientos/CIRCULAR 002 16-03-18.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Alejandra Ortiz Serna',
          department: 'Departamento de Desarrollo Institucional',
          phone: '449 910 5600 ext. 4185',
          updatedAt: '02/07/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "LineamientosYPoliticasDeEscuelasView",
  components: {
    RIComponent
  }
}
</script>

<style lang="scss" scoped>

</style>
