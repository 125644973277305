<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="mini-1">
          <h1>Instituciones Educativas</h1>
          <img src="https://www.iea.gob.mx/img/servicios-digitales/i_support.svg" />
        </div>
      </div>
    </div>

    <div class="row justify-content-end g-3 mb-5">
      <div class="col-12 col-md-6 order-md-last">
        <div class="d-flex align-items-center">
          <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_urbano.svg" alt="..." />
          <div class="w-100">
            <input placeholder="Escriba el nombre de la institución" style="text-transform: uppercase;" v-model="searchByName" @input="searchByLevel = ''; searchByMaintenance = ''; updateMap();" />
            <select class="form-select" v-model="searchByLevel" @change="searchByName = ''; updateMap();">
              <option disabled value="">SELECCIONE UN NIVEL EDUCATIVO</option>
              <option value="Inicial">INICIAL</option>
              <option value="Preescolar">PREESCOLAR</option>
              <option value="Primaria">PRIMARIA</option>
              <option value="Secundaria">SECUNDARIA</option>
              <option value="Media Superior">MEDIA SUPERIOR</option>
              <option value="Superior">SUPERIOR</option>
              <option value="Capacitacion Para El Trabajo">CAPACITACIÓN PARA EL TRABAJO</option>
            </select>
            <select class="form-select" v-model="searchByMaintenance" @change="searchByName = ''; updateMap();">
              <option disabled value="">SELECCIONE UN TIPO DE SOSTENIMIENTO ECONÓMICO</option>
              <option value="Público">PÚBLICO</option>
              <option value="Privado">PRIVADO</option>
            </select>
            <button class="btn btn bg-2 float-end" @click="clearFilters(); updateMap();">
              <span class="iconify" data-icon="ant-design:clear-outlined"></span>
              Limpiar
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 order-md-first" v-if="institucionEducativa">
        <div class="card">
          <div class="card-body">
            <h1 class="card-title text-start mb-3">{{ institucionEducativa.nombre }}</h1>
            <h6 class="card-subtitle fw-bold c_1 mb-3">
              Clave de CCT: {{ institucionEducativa.claveCCT }}
            </h6>
            <div class="card-text text-end">
              <span class="pill-text bg_cyan c_1 fw-bold">{{ institucionEducativa.nivel }}</span>
              <span class="pill-text bg_cyan c_1 fw-bold">{{ institucionEducativa.sostenimiento }}</span>
              <p class="fw-bold mt-3">
                {{ institucionEducativa.calle + " No. " + institucionEducativa.numero + ", " + institucionEducativa.colonia + ", " + institucionEducativa.localidad + ", " + institucionEducativa.municipio }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Mostrando {{ institucionesEducativasFilter.length }} resultados</h2>
        <div id="map" class="rounded shadow" style="height: 480px;"></div>
        <div style="display: none;">
          <!-- Popup -->
          <div id="popup"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'ol/ol.css';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import { fromLonLat, toLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Overlay from 'ol/Overlay';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import institucionesEducativas from "@/assets/json/institucionesEducativas.json";

export default {
  name: 'InstitucionesEducativasView',
  /**
   * Sets all data to be used by the component.
   */
  data() {
    return {
      institucionesEducativas: institucionesEducativas,
      institucionEducativa: null,
      map: null,
      view: null,
      vectorLayer: null,
      popover: null,
      searchByName: "",
      searchByLevel: "",
      searchByMaintenance: ""
    }
  },
  /**
   * Mounted lifecycle hook. This lifecycle hook configures the inicial map
   * rendering.
   */
  mounted() {
    // Creates a new Map object with default configuration...
    this.map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat([-102.28259, 21.88234]),
        zoom: 14,
      }),
    });

    // Configures new Overlay object for ths bootstrap´s popover component...
    const popup = new Overlay({
      element: document.getElementById('popup'),
    });
    this.map.addOverlay(popup);

    // Sets the 'pointermove' event to the map object...
    this.map.on('pointermove', (evt) => {
      // Returns the hovered feature (marker) on the map...
      let feature = this.map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        return feature;
      });

      if (feature) {
        this.map.getTargetElement().style.cursor = 'pointer';
      } else {
        this.map.getTargetElement().style.cursor = '';
      }
    });

    // Sets the 'click' event to the map object...
    this.map.on('click', (evt) => {
      // Returns the clicked feature (marker) on the map...
      let feature = this.map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        return feature;
      });

      if (feature) {
        this.institucionEducativa = this.institucionesEducativasFilter.find((institucionEducativa) =>
          institucionEducativa.ubicacion.longitud.toFixed(6) == toLonLat(feature.getGeometry().getCoordinates())[0].toFixed(6) &&
          institucionEducativa.ubicacion.latitud.toFixed(6) == toLonLat(feature.getGeometry().getCoordinates())[1].toFixed(6)
        );

        this.showPopover(feature, popup);
      } else {
        this.institucionEducativa = null;
        
        this.hidePopover();
      }
    });

    // Sets all the features (markers) on the map and push them on array...
    let features = [];

    this.institucionesEducativasFilter.forEach((institucionEducativa) => {
      const feature = new Feature({
        geometry: new Point(fromLonLat([institucionEducativa.ubicacion.longitud, institucionEducativa.ubicacion.latitud]))
      });

      feature.setStyle(new Style({
        image: new Icon({
          src: "https://www.iea.gob.mx/img/servicios-digitales/marker.svg"
        })
      }));

      features.push(feature);
    });

    // Adds all the features to a new VectorLayer object. Finally add the layer to the map...
    this.vectorLayer = new VectorLayer({
      source: new VectorSource({
        features: features,
      }),
    });

    this.map.addLayer(this.vectorLayer);
  },
  methods: {
    /**
     * This method updates the map rendering when a filter is applied.
     */
    updateMap() {
      // Clear the current layer...
      this.vectorLayer.getSource().clear();

      // Sets all the features (markers) on the map...
      let features = [];

      this.institucionesEducativasFilter.forEach((institucionEducativa) => {
        const feature = new Feature({
          geometry: new Point(fromLonLat([institucionEducativa.ubicacion.longitud, institucionEducativa.ubicacion.latitud]))
        });

        feature.setStyle(new Style({
          image: new Icon({
            src: "https://www.iea.gob.mx/img/servicios-digitales/marker.svg"
          })
        }));

        features.push(feature);
      });

      // Adds all the features to a new VectorLayer object. Finally add the layer to the map...
      this.vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: features,
        }),
      });

      this.map.addLayer(this.vectorLayer);
    },
    /**
     * This method displays a boostrap's popover with information about the given
     * feature of the OpenLayers map.
     * @param {*} feature 
     * @param {*} popup 
     */
    showPopover(feature, popup) {
      gea.iwin(() => {
        const element = popup.getElement();
        popup.setPosition(feature.getGeometry().getCoordinates());
        let popover = bootstrap.Popover.getInstance(element);

        if (popover) {
          popover.dispose();
        }

        popover = new bootstrap.Popover(element, {
          animation: false,
          container: element,
          title: `
            <div class="d-flex align-items-center">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_urbano.svg" alt="..." width="75" height="75" />
              <h2 class="subtitulo">${ this.institucionEducativa.nombre }</h2>
            </div>`,
          content: `
            <div>
              <div class="d-flex align-items-center">
                <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_rutas.svg" alt="..." width="50" height="50" />
                <small class="fw-bold c_base">
                  ${ this.institucionEducativa.calle + " No. " + this.institucionEducativa.numero + ", " + this.institucionEducativa.colonia + ", " + this.institucionEducativa.localidad + ", " + this.institucionEducativa.municipio }
                </small>
              </div>
              <hr />
              <ul>
                <li class="fw-bold c_base">Clave de CCT: ${ this.institucionEducativa.claveCCT }</li>
                <li class="fw-bold c_base">Turno: ${ this.institucionEducativa.turno }</li>
                <li class="fw-bold c_base">Nivel educativo: ${ this.institucionEducativa.nivel }</li>
                <li class="fw-bold c_base">Sostenimiento: ${ this.institucionEducativa.sostenimiento }</li>
              </ul>
            </div>`,
          html: true,
          placement: 'top'
        });

        popover.show();
      });
    },
    /**
     * This method hides the bootstrap's popover of a current feature of the
     * OpenLayers map.
     */
    hidePopover() {
      gea.iwin(() => {
        let popover = bootstrap.Popover.getInstance("#popup");

        if (popover) {
          popover.dispose();
        }
      });
    },
    /**
     * This method sets all the filters to the default values.
     */
    clearFilters() {
      this.searchByName = "";
      this.searchByLevel = "";
      this.searchByMaintenance = "";
    }
  },
  computed: {
    /**
     * This computed property returns an array of objects
     */
    institucionesEducativasFilter() {
      let institucionesEducativasFilter = [];

      this.hidePopover();

      if (this.searchByName != "") {
        institucionesEducativasFilter = this.institucionesEducativas.filter((institucionEducativa) => institucionEducativa.nombre.toUpperCase().includes(this.searchByName.toUpperCase()));
      } else if (this.searchByLevel != "") {
        institucionesEducativasFilter = this.institucionesEducativas.filter((institucionEducativa) => institucionEducativa.nivel === this.searchByLevel);
        
        if (this.searchByMaintenance != "") {
          institucionesEducativasFilter = institucionesEducativasFilter.filter((institucionEducativa) => institucionEducativa.sostenimiento === this.searchByMaintenance);
        }
      } else if (this.searchByMaintenance != "") {
        institucionesEducativasFilter = this.institucionesEducativas.filter((institucionEducativa) => institucionEducativa.sostenimiento === this.searchByMaintenance);
      } else {
        institucionesEducativasFilter = this.institucionesEducativas;
      }

      this.institucionEducativa = institucionesEducativasFilter[0];

      return institucionesEducativasFilter;
    }
  }
}
</script>

<style>

</style>
