<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DES) - Dirección de Educación Superior</h1>
      </div>
    </div>

    <div class="row">
      <div class="col mt-0" id="gabinete-cards">
        <div class="card-funcionario">
          <div class="d-flex align-items-center">
            <img src="https://www.iea.gob.mx/img/logo-ags.png" alt="funcionario" class="img-fluid mx-auto" style="width: 150px;" />
            <div class="funcionario-info">
              <p class="funcionario-puesto text-center">Director de Educación Superior del IEA</p>
              <h1 class="funcionario-nombre text-center">Dr. Francisco Rangel Cáceres</h1>
              <p class="funcionario-contacto text-center">
                Av. Tecnológico No. 601, Fracc. Ojocaliente, C.P. 20196
                Aguascalientes, Ags.
              </p>
              <p class="funcionario-contacto text-center">
                (449) 910 5600 ext.4137
              </p>
              <p class="funcionario-contacto text-center">
                francisco.rangel@iea.edu.mx
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrincipalView"
}
</script>

<style>

</style>
