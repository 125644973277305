/**
 * Módulo ExpoUniversidadesModule. Contiene las definiciones de Componentes
 * (Components), Vistas (Views) y Rutas (Routes) de la Página de la Expo Universidades
 * (https://www.iea.gob.mx/expouniversidades).
 * 
 * * Componente principal: ModuleComponent.vue
 * * Módulo de rutas: /routes/index.js
 * * Componentes: /components
 * * Vistas: /views
 * 
 * * Página Web: https://www.iea.gob.mx/expouniversidades
 */

import routes from "./routes";

export default {
  routes
};