<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="cabecera-2 bg_dg-3">
          <div class="info-contenedor-cb">
            <div class="abr-dependencia">
              {{ title }}
            </div>
            <ol class="breadcrumb breadcrumb-sae c_blanco">
              <li class="breadcrumb-item">
                <a href="https://www.aguascalientes.gob.mx">Inicio</a>
              </li>
              <li class="breadcrumb-item">
                <a href="https://www.aguascalientes.gob.mx/dependencias">Dependencias</a>
              </li>
              <li class="breadcrumb-item">
                <a href="https://www.iea.gob.mx">IEA</a>
              </li>
              <li class="breadcrumb-item active" aria-current="Sitio Actual">
                {{ title }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>

</style>