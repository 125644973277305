<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Coordinación de Becas y Financiamiento Educativo</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Publicaciones Historias de Convocatorias y Resultados de Becas</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Periodo</th>
              <th class="text-center">Convocatorias</th>
              <th class="text-center">Resultados</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>2024</td>
              <td class="text-center" width="150">
                <a href="https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/historico/CVT-2024.pdf" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
              <td class="text-center" width="150">
                <a href="https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/historico/RES-2024.pdf" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="banner_sm mt-5" style="background-image: linear-gradient(0deg, rgba(41, 60, 152, 0.5) 23.02%, rgba(41, 60, 152, 0.357) 80.31%), url('https://www.iea.gob.mx/img/bg-banners.png');">
          <div class="item1">
            <p class="titulo">Coordinación de Becas y Financiamiento Educativo</p>
          </div>
          <div class="item2">
            <p class="contenido">Av. Manuel Gomez Morin S/N, Ferronales, 20180 <br> Tel. 449 910 5694</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Alberto Robles Núñez',
          department: 'Coordinación de Becas y Financiamiento Educativo',
          phone: '449 910 5694',
          updatedAt: '07/11/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "HistoricoBecasView",
  components: {
    RIComponent
  }
}
</script>

<style lang="scss" scoped>

</style>
