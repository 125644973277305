<template>
  <header>
    <HeaderComponent />
  </header>

  <main id="main">
    <section>
      <div class="container" style="margin-top: 100px; margin-bottom: 120px;">
        <div class="row">
          <div class="col">
            <router-view />
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row">
          <div class="col">
            <h1 class="titulo">Realiza tu Test Vocacional</h1>
            <div class="banner_sm bg_dg-3 mb-3">
              <div class="item1">
                <p class="titulo">Realiza tu Test Vocacional</p>
              </div>
              <div class="item2">
                <p class="contenido">Expo Universidades 2023</p>
              </div>
              <div class="item3">
                <a href="https://intereses-vocacionales.fs.utags.edu.mx/registro/" target="_blank" class="btn btn-principal-light"  >
                  Ir al test
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row row-cols-1 row-cols-md-2">
          <div class="col">
            <h1 class="titulo">Programa de evento</h1>
            <div class="swiper-container rounded w-100">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="container p-3">
                    <div class="row g-3">
                      <div class="col-12">
                        <h2 class="subtitulo text-center">22 de Marzo de 2023</h2>
                      </div>
                      <div class="col-12" v-for="evento of programa22">
                        <div class="pill-card">
                          <div class="d-flex align-items-center">
                            <img v-if="evento.evento == 'Receso'" src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_comida.svg" alt="..." class="img-fluid" />
                            <img v-else src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_fecha.svg" alt="..." class="img-fluid" />
                            <div class="w-100">
                              <em>{{ evento.horario }}</em>
                              <br />
                              <br />
                              {{ evento.evento }}
                              <br />
                              <br />
                              <em class="float-end">{{ evento.lugar }}</em>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="container p-3">
                    <div class="row g-3">
                      <div class="col-12">
                        <h2 class="subtitulo text-center">23 de Marzo de 2023</h2>
                      </div>
                      <div class="col-12" v-for="evento of programa23">
                        <div class="pill-card">
                          <div class="d-flex align-items-center">
                            <img v-if="evento.evento == 'Receso'" src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_comida.svg" alt="..." class="img-fluid" />
                            <img v-else src="https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_fecha.svg" alt="..." class="img-fluid" />
                            <div class="w-100">
                              <em>{{ evento.horario }}</em>
                              <br />
                              <br />
                              {{ evento.evento }}
                              <br />
                              <br />
                              <em class="float-end">{{ evento.lugar }}</em>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
              
              <!-- <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div> -->

              <!-- <div class="swiper-scrollbar"></div> -->
            </div>
          </div>
          <div class="col">
            <h1 class="titulo">Mapa</h1>
            <img src="https://www.iea.gob.mx/img/expouniversidades/layout.png" alt="..." class="img-fluid">
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// @ is an alias to /src
import HeaderComponent from '@/pages/expouniversidades/components/HeaderComponent.vue';

export default {
  name: 'ExpoUniversidadesModuleComponent',
  components: {
    HeaderComponent
  },
  data: () => {
    return {
      programa22: [
        {
          evento: "Juegos y Dinámicas",
          horario: "8:40 – 9:10 y 9:40 - 9:50",
          lugar: "Templete Mayor"
        },
        {
          evento: "Inaguración",
          horario: "10:00 – 11:10",
          lugar: "Templete Mayor"
        },
        {
          evento: "Conferencia Magistral “Tú Puedes Ser el Gigante que Quieras Ser” Ex Alumno de la UPA Ing. Mecatrónico Ernesto Eduardo Macías Gutiérrez",
          horario: "11:30 – 12:10",
          lugar: "Templete Mayor"
        },
        {
          evento: "Panel “Tendencias Tecnológicas y Carreras del Futuro” Diferentes Docentes de las UTyP",
          horario: "12:30- 1:30",
          lugar: "Templete Mayor"
        },
        {
          evento: "Presentación Cultural “Danza Árabe” - ITA",
          horario: "1:40 – 2:10",
          lugar: "Templete Mayor"
        },
        {
          evento: "Receso",
          horario: "2:10 - 3:20",
          lugar: ""
        },
        {
          evento: "Panel “Acoso Cibernético” CEPSOVIDE",
          horario: "3:30 – 4:420",
          lugar: "Salón de Taller"
        },
        {
          evento: "Presentación Cultural “Andanza Universitaria” -  UTA",
          horario: "4:00 – 4:40",
          lugar: "Templete Mayor"
        },
        {
          evento: "Carrera de Botargas",
          horario: "5:00",
          lugar: "Espacio de Expo Universidades"
        }
      ],
      programa23: [
        {
          evento: "Presentación Cultural “Música y Canto”",
          horario: "8:30 – 9:00",
          lugar: "Templete Mayor"
        },
        {
          evento: "Taller: “Guiando la Construcción de Proyectos de Vida Consentida”",
          horario: "9:00 – 10:30",
          lugar: "Salón de Taller"
        },
        {
          evento: "Presentación Cultural Baile Moderno - TUA",
          horario: "10:15 – 10:40",
          lugar: "Templete Mayor"
        },
        {
          evento: "Presentación Cultural - Hip Hop UTMAT",
          horario: "11:00 – 11:30",
          lugar: "Templete Mayor"
        },
        {
          evento: "Taller “Capacitándome Para Mi Futuro” - ICTEA",
          horario: "11:00 – 11:40",
          lugar: "Salón de Taller"
        },
        {
          evento: "Presentación Cultural “Miniaturas Coreográficas” - Universidad de las Artes",
          horario: "12:00 – 13:00",
          lugar: "Templete Mayor"
        },
        {
          evento: "Taller: Reanimación Cardiopulmonar Básica y Avanzada en Adultos y Edad Pediátrica",
          horario: "12:00- 12:30",
          lugar: "Salón de Taller"
        },
        {
          evento: "Panel “Tendencias Tecnológicas Y Carreras Del Futuro” Diferentes Docentes de las UTyP",
          horario: "12:30 – 1:30",
          lugar: "Templete Mayor"
        },
        {
          evento: "Receso",
          horario: "1:30 - 3:15",
          lugar: ""
        },
        {
          evento: "Presentación Cultural “Rondalla” - UTA",
          horario: "3:15 – 3:40",
          lugar: "Templete Mayor"
        },
        {
          evento: "Taller “Prevención De Adicciones” - CEPSOVIDE",
          horario: "4:00 – 5:00",
          lugar: "Salón de Taller"
        },
        {
          evento: "Presentación Cultural “GRUPO DE ROCK los Tiliches” UPA",
          horario: "5:00 – 5:40",
          lugar: "Templete Mayor"
        }
      ]
    }
  },
  created() {
    gea.iwin(function () {
      new Swiper(".swiper-container", {
        observer: true,
        speed: 1000,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        slidesPerView: 1
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev"
        // },
        // scrollbar: {
        //   el: '.swiper-scrollbar'
        // }
      });
    });
  }
}
</script>

<style>

</style>