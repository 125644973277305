/**
 * Módulo de rutas del módulo ServiciosDigitales. Contiene las definiciones
 * de rutas de la Página de la Servicios Digitales (https://www.iea.gob.mx/servicios-digitales).
 */

// Componente principal del módulo...
import ModuleComponent from "../ModuleComponent.vue";

import InicioView from '../views/InicioView.vue';
import GoogleWorkspaceView from '../views/GoogleWorkspaceView.vue';
import CanvaView from '../views/CanvaView.vue';
import GoogleChromebooksView from '../views/GoogleChromebooksView.vue';
import ViveSeguroEnInternetView from '../views/ViveSeguroEnInternetView.vue';

const routes = {
  path: '/herramientas-digitales',
  component: ModuleComponent,
  meta: { title: 'IEA | Herramientas Digitales para la Educación' },
  children: [
    { path: '', component: InicioView },
    { path: 'google-workspace', component: GoogleWorkspaceView },
    { path: 'canva', component: CanvaView },
    { path: 'chromebooks', component: GoogleChromebooksView },
    { path: 'vive-seguro-en-internet', component: ViveSeguroEnInternetView },
  ]
};

export default routes;