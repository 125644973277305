<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DEB) - Misión, Visión y Valores</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Misión</h2>
        <p>
          Proporcionar servicios educativos con experiencia, equidad, inclusión
          y calidez para coadyuvar en la formación integral de las y los
          estudiantes, adquiriendo las competencias necesarias para lograr una
          vida plena que contribuya al desarrollo de una sociedad plural,
          incluyente y humanista.
        </p>
        <h2 class="subtitulo">Visión</h2>
        <p>
          Lograr hacer del Sistema Educativo líder y ejemplo para la
          construcción de una sociedad evolutiva, humanista, vinculante y pilar
          fundamental en el desarrollo de los individuos, a través de la
          equidad, inclusión y compromiso.
        </p>
        <h2 class="subtitulo">Valores</h2>
        <ul>
          <li>Respeto.</li>
          <li>Interés público.</li>
          <li>Integridad.</li>
          <li>Cooperación.</li>
          <li>Transparencia.</li>
          <li>Rendición de cuentas.</li>
          <li>Igualdad y no discriminación.</li>
          <li>Equidad de género.</li>
          <li>Entorno cultural y ecológico.</li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
            name: '-',
            department: 'Dirección de Educación Básica',
            phone: '449 910 5600 ext. 4196 / 4179',
            updatedAt: '25/06/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "SomosView",
  components: {
    RIComponent,
  },
};
</script>

<style lang="scss" scoped>

</style>
