<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DEMS) - Bachilleratos Estatales</h1>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-lg-2 g-3">
      <div class="col">
        <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-estatales/BD.webp" alt="..." class="img-fluid rounded shadow" />
      </div>
      <div class="col">
        <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-estatales/BAH.webp" alt="..." class="img-fluid rounded shadow" />
      </div>
      <div class="col">
        <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-estatales/BGMO.webp" alt="..." class="img-fluid rounded shadow" />
      </div>
      <div class="col">
        <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-estatales/BGMP.webp" alt="..." class="img-fluid rounded shadow" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
            name: '-',
            department: 'Dirección de Educación Media Superior',
            phone: '449 149 2400 ext. 7800',
            updatedAt: '25/03/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "BachilleratosEstatalesView",
  components: {
    RIComponent
  }
}
</script>

<style lang="scss" scoped></style>
