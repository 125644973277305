<template>
  <div class="navDependencia">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="routerWrapper">
            
            <router-link to="/educacion-media-superior">Principal</router-link>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                ¿Quiénes somos?
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <router-link to="/educacion-media-superior/somos">Misión, Visión y Valores</router-link>
                </li>
              </ul>
            </div>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                Oferta Educativa
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <router-link to="/educacion-media-superior/bachilleratos-estatales">Bachilleratos Estatales</router-link>
                </li>
                <li>
                  <router-link to="/educacion-media-superior/bachilleratos-incorporados">Bachilleratos Incorporados</router-link>
                </li>
                <li>
                  <router-link to="/educacion-media-superior/telebachilleratos">Telebachilleratos Comunitarios</router-link>
                </li>
                <li>
                  <router-link to="/educacion-media-superior/certificacion-y-formacion-para-el-trabajo">Certificación y Formación para el Trabajo</router-link>
                </li>
              </ul>
            </div>

            <router-link to="/educacion-media-superior/nuevo-ingreso">Nuevo Ingreso</router-link>

            <router-link to="/educacion-media-superior/hub">HUB</router-link>

            <router-link to="/educacion-media-superior/indicadores-de-cobertura-y-permanencia">Indicadores de Cobertura y Permanencia</router-link>

          </div>
          <hr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarESComponent"
}
</script>