<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DES) - Plan Estratégico del Sistema de Educación Superior 2022-2027</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>EJE</th>
                <th>ESTRATEGIA</th>
                <th>ACCIÓN</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="7">1. Mejorar la gobernanza del sistema de Educación Superior del Estado.</td>
                <td rowspan="5">1.1. Propiciar la sinergia y complementariedad de las capacidades de las IES.</td>
                <td>1.1.1. Fortalecer el marco normativo y estructura organizacional de las IES.</td>
              </tr>
              <tr>
                <td>1.1.2. Gestionar recursos financieros para facilitar el crecimiento y consolidación de las IES..</td>
              </tr>
              <tr>
                <td>1.1.3. Crear una red de comités de mejora interinstitucional para el fortalecimiento del sistema educativo estatal.</td>
              </tr>
              <tr>
                <td>1.1.4. Generar comunidades académicas de aprendizaje colaborativo (mejores prácticas).</td>
              </tr>
              <tr>
                <td>1.1.5. Desarrollar un portal único, de fácil uso y acceso, que provea información relevante sobre la educación superior y el mercado laboral a todos los grupos de interés.</td>
              </tr>
              <tr>
                <td rowspan="2">1.2. Fortalecer las cualificaciones del Capital Humano de las IES.</td>
                <td>1.2.1. Establecer un sistema de actualización y profesionalización docente orientado al Marco Nacional de Cualificaciones.</td>
              </tr>
              <tr>
                <td>1.2.2. Fortalecer las cualificaciones y liderazgo del personal directivo y de apoyo.</td>
              </tr>
              <tr>
                <td rowspan="10">2. Fortalecer la calidad de la Educación Superior, pública y privada.</td>
                <td rowspan="3">2.1. Implementar un modelo de calidad orientado a resultados del proceso de aprendizaje de los estudiantes y la relevancia para el mercado laboral.</td>
                <td>2.1.1. Establecer criterios de calidad de los servicios de apoyo y programas educativos que faciliten identificar en qué grado los estudiantes adquirieron los conocimientos y competencias relevantes para el mercado laboral.</td>
              </tr>
              <tr>
                <td>2.1.2. Monitorear y evaluar periódica y sistemáticamente la eficacia de los criterios de calidad de los servicios de apoyo y programas educativos establecidos.</td>
              </tr>
              <tr>
                <td>2.1.3. Certificar los procesos y acreditar los programas educativos por organismos reconocidos por el COPAES y por la EMA, a fin de mejorar la calidad de la educación superior.</td>
              </tr>
              <tr>
                <td rowspan="5">2.2. Fortalecer el modelo educativo centrado en el aprendizaje y el estudiante.</td>
                <td>2.2.1. Fortalecer la oferta del modelo DUAL en el mercado laboral.</td>
              </tr>
              <tr>
                <td>2.2.2. Aplicar modelos educativos pertinentes y actuales.</td>
              </tr>
              <tr>
                <td>2.2.3. Fomentar la cultura STEAM y el emprendimiento en la comunidad estudiantil.</td>
              </tr>
              <tr>
                <td>2.2.4. Desarrollar programas de atención a estudiantes en riesgo de deserción a partir del ingreso.</td>
              </tr>
              <tr>
                <td>2.2.5. Desarrollar las habilidades clave para el desarrollo profesional (soft & hard skills,IE).</td>
              </tr>
              <tr>
                <td rowspan="2">2.3. Promover la investigación, transferencia de tecnología y patentes en las IES.</td>
                <td>2.3.1. Fortalecer los cuerpos académicos en las IES.</td>
              </tr>
              <tr>
                <td>2.3.2. Fomentar la creatividad, innovación e investigación aplicada interinstitucional.</td>
              </tr>
              <tr>
                <td rowspan="6">3. Posicionar nacional e internacionalmente la Educación Superior del Estado.</td>
                <td rowspan="4">3.1. Asegurar la pertinencia de la oferta educativa de las IES con las necesidades del entorno y las tendencias tecnológicas.</td>
                <td>3.1.1. Identificar necesidades de capital humano de los sectores público, privado y social.</td>
              </tr>
              <tr>
                <td>3.1.2. Ofrecer programas educativos pertinentes a las necesidades del entorno.</td>
              </tr>
              <tr>
                <td>3.1.3. Alinear expectativas de los estudiantiles a las necesidades de capital humano del entorno.</td>
              </tr>
              <tr>
                <td>3.1.4. Vincular a las IES con el sector educativo, público, privado y social.</td>
              </tr>
              <tr>
                <td rowspan="2">3.2. Atender las necesidades del entorno local, regional y global.</td>
                <td>3.2.1. Garantizar el aprendizaje del idioma inglés en la comunidad estudiantil.</td>
              </tr>
              <tr>
                <td>3.2.2. Impulsar la internacionalización de los programas educativos y la movilidad académica nacional e internacional.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Stefany Karina Jiménez Colombo',
          department: 'Departamento de Divulgación y Educación Científica y Tecnológica',
          phone: '449 149 2400 ext. 7805',
          updatedAt: '05/04/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "PlanEstrategicoView",
  components: {
    RIComponent,
  },
};
</script>

<style></style>
