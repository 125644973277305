<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Comisión de Seguridad e Higiene</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Acta Constitutiva de la Comisión de Seguridad e Higiene del IEA</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/comision-de-seguridad-e-higiene/Acta-Constitutiva-de-la-Comisión-de-Seguridad-e-Higiene-del-IEA.pdf" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Comisión de Seguridad e Higiene del IEA</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/comision-de-seguridad-e-higiene/Comisión-de-Seguridad-e-Higiene-del-IEA.pdf" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Acta Constitutiva de las Brigadas de Emergencia del IEA</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/comision-de-seguridad-e-higiene/Acta-Constitutiva-de-las-Brigadas-de-Emergencia-del-IEA.pdf" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Brigada de Búsqueda y Rescate del IEA</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/comision-de-seguridad-e-higiene/Brigada-de-Búsqueda-y-Rescate-del-IEA.pdf" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>Brigada de Evacuación del IEA</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/comision-de-seguridad-e-higiene/Brigada-de-Evacuación-del-IEA.pdf" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>Brigada de Prevención y Combate de Incendios del IEA</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/comision-de-seguridad-e-higiene/Brigada-de-Prevención-y-Combate-de-Incendios-del-IEA.pdf" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>Brigada de Primeros Auxilios del IEA</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/comision-de-seguridad-e-higiene/Brigada-de-Primeros-Auxilios-del-IEA.pdf" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>Manual de Lineamientos de las Actividades de las Comisiones de Seguridad e Higiene</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/comision-de-seguridad-e-higiene/Política-de-Seguridad-e-Higiene-de-la-Administración-Pública-del-Estado.pdf" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>Plan Anual de Actividades de las Comisiones de Seguridad e Higiene</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/comision-de-seguridad-e-higiene/Plan-Anual-de-Actividades-de-las-Comisiones-de-Seguridad-e-Higiene.pdf" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>10</td>
              <td>Política de Seguridad e Higiene de la Administración Pública del Estado</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/scii/comision-de-seguridad-e-higiene/Política-de-Seguridad-e-Higiene-de-la-Administración-Pública-del-Estado.pdf" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Alejandra Ortiz Serna',
          department: 'Departamento de Desarrollo Institucional',
          phone: '449 910 5600 ext. 4185',
          updatedAt: '14/05/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "ComisionDeSeguridadEHigieneView",
  components: {
    RIComponent,
  }
}
</script>

<style lang="scss" scoped>

</style>