/**
 * Módulo EducacionSuperiorModule. Contiene las definiciones de Componentes
 * (Components), Vistas (Views) y Rutas (Routes) de la Página de la Dirección de
 * Educación Superior (https://www.iea.gob.mx/educacion-media-superior).
 * 
 * * Componente principal: ModuleComponent.vue
 * * Módulo de rutas: /routes/index.js
 * * Componentes: /components
 * * Vistas: /views
 * 
 * * Página Web: https://www.iea.gob.mx/educacion-media-superior
 */

import routes from "./routes";

export default {
  routes
};